function Table({ children, className }) {
  return (
    <div className={`w-full ${className}`}>
      {children}
    </div>
  )
}

function TableHead({ children, className }) {
  return (
    <div className={`text-md flex justify-between items-center font-medium ${className}`}>
      {children}
    </div>
  )
}

function TableBody({ children, className }) {
  return (
    <div className={`text-sm flex flex-col items-center ${className}`}>
      {children}
    </div>
  )
}

function TableRow({ children, className, onClick = () => false }) {
  return (
    <div onClick={onClick} className={`flex w-full justify-between py-4 border-b border-current ${className}`}>
      {children}
    </div>
  )
}

function TableData({ children, className }) {
  return (
    <div className={`w-32 ${className}`}>
      {children}
    </div>
  )
}

export {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableData
}