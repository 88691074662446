/* eslint-disable no-unused-vars */
import { Redirect, Switch } from "react-router-dom"
import { SidebarTeacher } from "../../components/Sidebar"
import { useCookies } from "../../hooks/useCookies"
import ContainerDashboard from "../../layouts/Dashboard"
import { RouteWithSubRoute } from "../../routes"
import { checkCurrentRole } from "../../utils/common/checkCurrentRole"
import { checkRole } from "../../utils/common/checkRole"
import { ContentDashboardNotFound } from "../errors/404"
import Login from './Login'
import { getDetailTeacher } from '../../service/teacher/teacherService'
import { useEffect, useState } from "react"

function Dashboard({ routes }) {

  const { getCookie } = useCookies()
  const token = getCookie('token')
  const [profile, setProfile] = useState([])

  useEffect(() => {
    const fetchProfile = async () => {
      const result = await getDetailTeacher(token)
      setProfile(result.data)
    }
    fetchProfile()
  }, [token])

  if (!token) {
    return <Login />
  }

  if (!checkRole("teacher", token)) {
    const currentRole = checkCurrentRole(token)
    if (currentRole === "superadmin") {
      return <Redirect to={`/admin/payment`} />
    } else if (currentRole === "student") {
      return <Redirect to={`/dashboard`} />
    }
    return <Redirect to={`/${currentRole}`} />
  }

  return (
    <ContainerDashboard>
      <SidebarTeacher {...profile}/>
      <Redirect to="/teacher/modul" />
      <Switch>
        <RouteWithSubRoute routes={routes} />
        <ContentDashboardNotFound />
      </Switch>
    </ContainerDashboard>
  )
}

export default Dashboard