import Container from "../../../components/ContainerLogin"
import { FormLogin } from "../../Login"

function LoginAdmin() {
  return (
    <Container admin title="Halo, Selamat Datang">
      <FormLogin admin/>
    </Container>
  )
}

export default LoginAdmin