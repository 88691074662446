import { Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { NavDashboard } from "../../components/Navbar"
import { ContentDashboard, MainDashboard } from '../../layouts/Dashboard'
import { useQuery } from '../../hooks/useQuery'
import Modul from "../users/Modul"
import { useCookies } from '../../hooks/useCookies'
import { getAllChapter, getAllLesson, getAllGrade } from '../../service/teacher/teacherService'

function ClassAndModul() {
  const history = useHistory()
  const idGrade = useQuery('class')

  const [lesson, setLesson] = useState([])
  const [grades, setGrades] = useState([])
  const [resultSearch, setResultSearch] = useState([])

  const { getCookie } = useCookies()
  const token = getCookie("token")

  useEffect(() => {
    const fetchLesson = async () => {
      const lesson = await getAllLesson(token, idGrade)
      const grades = await getAllGrade(token)
      setLesson(lesson.data)
      setGrades(grades.data)
    }

    fetchLesson()
  }, [idGrade, token])

  const handleClickClass = (idGrade) => {
    history.push(`/teacher/modul?class=${idGrade}`)
  }

  if (idGrade) { // jika menekan tombol class
    return <Modul teacherLessonData={lesson} teacher grade={idGrade} getAllChapterByTeacher={getAllChapter} />
  }

  const handleSearchClass = (event) => {
    const value = parseInt(event.target.value)
    setResultSearch(grades.filter(grade => grade.grade === value))
  }

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Modul dan Kelas" onSearch={handleSearchClass} />
        <ContentDashboard>
          <h1 className='text-xl font-medium'>Kelas</h1>
          <div className='flex flex-col mb-6'>
            {resultSearch && (
              <div className='grid md:grid-cols-3 grid-cols-2 sm:gap-6 gap-3 mt-3'>
                {resultSearch.filter(grade => grade.grade <= 6).map(data => (
                  <div key={data.grade} onClick={() => handleClickClass(data.grade)}>
                    <Card grades={data} />
                  </div>
                ))}
              </div>
            )}

            {resultSearch?.length === 0 && (
              <>
                <div>
                  <span className='text-sm txt-gray'>Sekolah Dasar (SD)</span>
                  <div className='grid md:grid-cols-3 grid-cols-2 sm:gap-6 gap-3 mt-3'>
                    {grades.filter(grade => grade.grade <= 6).map(data => (
                      <div key={data.grade} onClick={() => handleClickClass(data.grade)}>
                        <Card grades={data} />
                      </div>
                    ))}
                  </div>
                </div>

                <div className='mt-8'>
                  <span className='text-sm txt-gray'>Sekolah Menengah Pertama (SMP)</span>
                  <div className='grid md:grid-cols-3 grid-cols-2 sm:gap-6 gap-3 mt-3'>
                    {grades.filter(grade => grade.grade >= 7 && grade.grade <= 9).map(data => (
                      <div key={data.grade} onClick={() => handleClickClass(data.grade)}>
                        <Card grades={data} />
                      </div>
                    ))}
                  </div>
                </div>

                <div className='mt-8'>
                  <span className='text-sm txt-gray'>Sekolah Menengah Atas (SMA)</span>
                  <div className='grid md:grid-cols-3 grid-cols-2 sm:gap-6 gap-3 mt-3'>
                    {grades.filter(grade => grade.grade >= 10 && grade.grade <= 12).map(data => (
                      <div key={data.grade} onClick={() => handleClickClass(data.grade)}>
                        <Card grades={data} />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  )
}

function Card({ grades }) {
  return (
    <div className='bg-blue p-6 rounded-xl flex flex-col text-white cursor-pointer relative overflow-hidden'>
      <span className='sm:text-base text-sm'>Kelas</span>
      <h3 className='sm:text-3xl text-2xl font-bold'>{grades?.grade}</h3>
      <div className='w-16 h-16 rounded-full border border-white absolute -top-8 -left-8'></div>
      <div className='w-20 h-20 rounded-full border border-white absolute -bottom-6 -right-6'></div>
      <div className='w-16 h-16 rounded-full bg-white opacity-40  absolute -bottom-6 -right-6'></div>
    </div>
  )
}

export default ClassAndModul