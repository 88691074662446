import { BASE_URL } from "../../constant";

// company profile promo
export async function getCompanyProfile() {
  return await (await fetch(`${BASE_URL}/v1/promo/getcompanyprofile`)).json();
}

export const download = (path, filename) => {
  const anchor = document.createElement("a");
  anchor.href = path;
  anchor.download = filename;

  document.body.appendChild(anchor);

  anchor.click();
  document.body.removeChild(anchor);
};

export async function forgotPassword(email) {
  return await (
    await fetch(`${BASE_URL}/v1/auth/forgot-password`, {
      method: "POST",
      body: new URLSearchParams({
        email,
      }),
    })
  ).json();
}

export async function resetPassword(email, token) {
  return await (
    await fetch(`${BASE_URL}/v1/auth/reset-password`, {
      method: "POST",
      body: new URLSearchParams({
        email,
        token,
      }),
    })
  ).json();
}
