/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { NavDashboard } from "../../components/Navbar"
import { useQuery } from '../../hooks/useQuery'
import { useToggle } from '../../hooks/useToggle'
import { PopupExam } from "../../components/Popup"
import { ContentDashboard, MainDashboard } from "../../layouts/Dashboard"
import { Table, TableHead, TableBody, TableRow, TableData } from '../../components/Table'
import { getAllClass, getAllExam, createClassExam, getAllScoringExam, restartExam } from '../../service/teacher/teacherService'
import { useCookies } from '../../hooks/useCookies'

function ExamTeacher() {
  const [activeTab, setActiveTab] = useState("exam")
  const history = useHistory()
  const { minimized } = useToggle()
  const [showPopup, setShowPopup] = useState(false)
  const [hasCreatedExam, setHasCreatedExam] = useState(false)
  const [hasReseted, setHasReseted] = useState(false)

  const [searchExam, setSearchExam] = useState([])

  const id = useQuery('id')

  const { getCookie } = useCookies()
  const token = getCookie("token")

  const [allClass, setAllClass] = useState([])
  const [allExam, setAllExam] = useState([])
  const [activeExamId, setActiveExamId] = useState()
  const [activeGrade, setActiveGrade] = useState()

  // eslint-disable-next-line 
  const [studentScore, setStudentScore] = useState([])

  useEffect(() => {
    const getClass = async () => {
      const result = await getAllClass(token)

      if(activeGrade && id) {
        const allExam = await getAllExam(activeGrade, id, token)
        const allScoringExam = await getAllScoringExam(id, token)
        
        setAllExam(allExam.data)
        setStudentScore(allScoringExam.data)
      }

      setAllClass(result.data)
      setHasCreatedExam(false)
    }
    
    setHasReseted(false)
    getClass()
  }, [token, activeGrade, hasCreatedExam, id, hasReseted])

  const handleClickClass = (data) => {
    history.push(`/teacher/exam?id=${data.id}`)
    setActiveGrade(data.classGrade)
    // setActiveClassQuiz(data.idClassQuiz)
  }

  const handleClickCardExam = (idExam) => {
    setActiveExamId(idExam)
    setShowPopup(true)
  }

  if (activeTab === 'exam' && id) {
    return (
      <Fragment>
        <MainDashboard>
          <NavDashboard title="Ujian Online" />
          <PopupExam setState={() => setHasCreatedExam(true)} token={token} action={createClassExam} idExam={activeExamId} idClass={parseInt(id)} open={showPopup} setOpen={setShowPopup} />
          <div className='mt-6 mb-6'>
            <div className={`mt-6 grid ${minimized ? 'md:grid-cols-3 grid-cols-1' : 'md:grid-cols-1 grid-cols-2 one-column'} gap-6`}>
              {allExam.map((exam, id) => {
                return <CardRetangle key={id} data={exam} onClick={() => handleClickCardExam(exam.id)} />
              })}
            </div>
          </div>
        </MainDashboard>
      </Fragment>)
  } else if (activeTab === 'skor' && id) {
    
    const handleSearch = (input) => {
      const value = input.target.value
      const doubleSearch = value.split("-").map(val => val.trim())
      
      if(value.includes("-")) {
        return setSearchExam(studentScore.filter(student => student.fullName.toLowerCase().includes(doubleSearch[0]) && student.title.toLowerCase().includes(doubleSearch[1])))
      }

      setSearchExam(studentScore.filter(student => student.fullName.toLowerCase().includes(value.toLowerCase()) || student.title.toLowerCase().includes(value.toLowerCase())))
    }

    return (
      <Fragment>
        <MainDashboard>
          <NavDashboard title="Ujian Online" onSearch={handleSearch}/>
          <ContentDashboard>
            <h1 className='text-xl font-medium mb-3'>Lihat Skor Siswa</h1>
            <ListStudent filtered={searchExam} data={studentScore} token={token} setHasReseted={setHasReseted}/>
          </ContentDashboard>
        </MainDashboard>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Ujian Online" />
        <div className='py-6 flex'>
          <button onClick={() => setActiveTab('exam')} className={`${activeTab === 'exam' ? 'bg-blue text-white' : 'bg-white'} mr-3 p-3 rounded-md md:text-md text-sm`}>Kelola Soal Ujian</button>
          <button onClick={() => setActiveTab('skor')} className={`${activeTab === 'skor' ? 'bg-blue text-white' : 'bg-white'} mr-3 p-3 rounded-md md:text-md text-sm`}>Skor Siswa</button>
        </div>
        <div className='md:mt-2 rounded-xl bg-white w-full p-6'>
          <h1 className='text-xl font-medium mb-3'>Kelas</h1>
          <div className='flex flex-col mb-6'>
            <div>
              <span className='text-sm txt-gray'>Sekolah Dasar (SD)</span>
              <div className='grid md:grid-cols-3 grid-cols-2 sm:gap-6 gap-3 mt-3'>
                {allClass.filter(data => data.classGrade <= 6).map(data => {
                  return (
                    <div key={data.id} onClick={() => handleClickClass(data)}>
                      <Card data={data} key={data.id}/>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className='mt-6'>
              <span className='text-sm txt-gray'>Sekolah Menengah Pertama (SMP)</span>
              <div className='grid md:grid-cols-3 grid-cols-2 sm:gap-6 gap-3 mt-3'>
                {allClass.filter(data => data.classGrade >= 7 && data.classGrade <= 9).map(data => {
                  return (
                    <div key={data.id} onClick={() => handleClickClass(data)}>
                      <Card data={data} />
                    </div>
                  )
                })}
              </div>
            </div>

            <div className='mt-6'>
              <span className='text-sm txt-gray'>Sekolah Menengah Atas (SMA)</span>
              <div className='grid md:grid-cols-3 grid-cols-2 sm:gap-6 gap-3 mt-3'>
                {allClass.filter(grade => grade.classGrade >= 10 && grade.classGrade <= 12).map(data => {
                  return (
                    <div key={data.id} onClick={() => handleClickClass(data)}>
                      <Card data={data} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </MainDashboard>
    </Fragment>
  )
}

function ListStudent({data, token, setHasReseted, filtered}) {

  const handleClickReset = async (id) => {
    const result = await restartExam(id, token)
    if(result.status === "Success") {
      const student = data.filter(student => student.id === id);
      setHasReseted(true)
      return alert(`berhasil reset score ${student[0]?.fullName}`)
    }
  }

  return (
    <Table>
      <TableHead className='md:block hidden'>
        <TableRow>
          <TableData className='w-full'>Nama Murid</TableData>
          <TableData className='text-center w-2/3'>Title</TableData>
          <TableData className='text-center w-72'>Status</TableData>
          <TableData className='text-center w-44'>Skor</TableData>
          <TableData className='text-center'>Action</TableData>
        </TableRow>
      </TableHead>
      <TableBody>
        {filtered.length >= 1 ? filtered.map(student => {
          return (
            <TableRow key={student.id} className='md:items-center relative md:flex-row flex-col'>
            <TableData className='w-full'>
              <div className='flex items-center'>
                {/* <div className='mr-3 md:block hidden'>
                  <img src="/assets/avatar/default.png" alt="prof" />
                </div> */}
                <div className='flex flex-col md:mb-0 mb-3'>
                  <span className='font-medium'>{student.fullName}</span>
                  {/* <span className='text-xs'>Kelas 1SD</span> */}
                </div>
              </div>
            </TableData>
            <TableData className='md:text-center md:w-2/3'>
              <span className='md:font-medium'>{student.title}</span>
            </TableData>
            <TableData className='md:text-center md:w-72 w-full my-3 md:my-0 md:mb-0 mb-5'>
              <span className='md:font-medium bg-blue text-white rounded-sm p-1 text-xs md:text-base'>{student.status}</span>
            </TableData>
            <TableData className='flex md:block text-center text-base md:text-xl font-medium md:static absolute left-0 bottom-0 w-max mt-3 md:w-44 md:mt-0'>
              <span className='md:hidden block font-medium'>Skor :</span>
              <span className='block ml-1 md:ml-0'> {student.score}</span>
            </TableData>
            <TableData className='text-center'>
              <button className='md:p-3 p-1 rounded-md bg-orange text-white md:block absolute top-3 right-3' onClick={() => handleClickReset(student.id)}>Reset</button>
            </TableData>
          </TableRow>
          )
        }) : data?.map(student => {
          return (
            <TableRow key={student.id} className='md:items-center relative md:flex-row flex-col'>
            <TableData className='w-full'>
              <div className='flex items-center'>
                {/* <div className='mr-3 md:block hidden'>
                  <img src="/assets/avatar/default.png" alt="prof" />
                </div> */}
                <div className='flex flex-col md:mb-0 mb-3'>
                  <span className='font-medium'>{student.fullName}</span>
                  {/* <span className='text-xs'>Kelas 1SD</span> */}
                </div>
              </div>
            </TableData>
            <TableData className='md:text-center md:w-2/3'>
              <span className='md:font-medium'>{student.title}</span>
            </TableData>
            <TableData className='md:text-center md:w-72 w-full my-3 md:my-0 md:mb-0 mb-5'>
              <span className='md:font-medium bg-blue text-white rounded-sm p-1 text-xs md:text-base'>{student.status}</span>
            </TableData>
            <TableData className='flex md:block text-center text-base md:text-xl font-medium md:static absolute left-0 bottom-0 w-max mt-3 md:w-44 md:mt-0'>
              <span className='md:hidden block font-medium'>Skor :</span>
              <span className='block ml-1 md:ml-0'> {student.score}</span>
            </TableData>
            <TableData className='text-center'>
              <button className='md:p-3 p-1 rounded-md bg-orange text-white md:block absolute top-3 right-3' onClick={() => handleClickReset(student.id)}>Reset</button>
            </TableData>
          </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

function Card({ data }) {
  return (
    <div className='bg-blue p-6 rounded-xl flex flex-col text-white cursor-pointer relative overflow-hidden'>
      <span className='sm:text-base text-sm'>{data.name}</span>
      <h3 className='sm:text-3xl text-2xl font-bold'>{data?.classGrade}</h3>
      <div className='w-16 h-16 rounded-full border border-white absolute -top-8 -left-8'></div>
      <div className='w-20 h-20 rounded-full border border-white absolute -bottom-6 -right-6'></div>
      <div className='w-16 h-16 rounded-full bg-white opacity-40  absolute -bottom-6 -right-6'></div>
    </div>
  )
}

function CardRetangle({ onClick = () => { }, data }) {
  const { minimized } = useToggle();

  const startDate = {
    hours:
      String(new Date(data.startDate).getHours()).length === 1
        ? String(new Date(data.startDate).getHours()).padStart(2, 0)
        : String(new Date(data.startDate).getHours()),
    minutes:
      String(new Date(data.startDate).getMinutes()).length === 1
        ? String(new Date(data.startDate).getMinutes()).padStart(2, 0)
        : String(new Date(data.startDate).getMinutes()),
  };

  const endDate = {
    hours:
      String(new Date(data.endDate).getHours()).length === 1
        ? String(new Date(data.endDate).getHours()).padStart(2, 0)
        : String(new Date(data.endDate).getHours()),
    minutes:
      String(new Date(data.endDate).getMinutes()).length === 1
        ? String(new Date(data.endDate).getMinutes()).padStart(2, 0)
        : String(new Date(data.endDate).getMinutes()),
  };

  return (
    <div
      onClick={onClick}
      className={`flex hover:border-blue-200 border-2 ${minimized
        ? "flex-col justify-center"
        : "md:flex-row flex-col justify-center"
        } bg-white rounded-xl md:p-8 p-4`}
    >
      <div className={`md:w-44 w-32 ${minimized ? "m-auto" : "m-auto mb-6"}`}>
        <img
          className="w-full"
          src={`/assets/images/quiz_img.png`}
          alt="illutration"
        />
      </div>
      <div
        className={`flex flex-col justify-between ${minimized ? "mt-12 text-center" : "w-4/5 md:pl-12"
          }`}
      >
        <div className="flex flex-col relative">
          <span className="txt-gray md:text-sm text-xs">{data.lesson} - {data.modul}</span>
          <h1 className="font-semibold md:text-xl text-sm my-2">
            {data.title}
          </h1>
          <p className="md:text-sm text-xs md:mb-0 mb-3">
            {data.description}
          </p>
          <p className={`${minimized ? 'relative' : 'md:right-0 md:top-0 md:absolute'} text-xs`}>
            {(data.startDate && data.endDate) && (`${data?.startDate?.split("T")[0]} s/d ${data?.endDate?.split("T")[0]}`)}
          </p>
        </div>

        <div
          className={`flex ${minimized
            ? "flex-col justify-center mt-6 items-center"
            : "md:flex-row flex-col items-start justify-between"
            } `}
        >
          <div className="flex md:flex-col text-sm">
                <span
                  className={`txt-gray md:text-sm text-xs flex ${
                    minimized && "justify-center"
                  }`}
                >
                  Guru <span className="md:hidden block">:</span>{" "}
                </span>
                <span
                  className={`${
                    minimized ? "text-lg" : "md:text-sm text-xs md:ml-0 ml-2"
                  } font-medium`}
                >
                  {data.teacher ?? "-"}
                </span>
              </div>

          <div
            className={`${minimized ? "flex-col" : "flex-row"
              } flex md:w-1/2 w-full md:mt-0 mt-3 justify-between`}
          >
            <div className={`flex flex-col text-sm ${minimized && "mt-3"}`}>
              <span className="txt-gray md:block hidden">Waktu Mulai</span>
              <span className="txt-blue">{startDate.hours}:{startDate.minutes} - {endDate.hours}:{endDate.minutes}</span>
            </div>
            <div className={`flex flex-col text-sm ${minimized && "mt-3"}`}>
              <span className="txt-gray md:block hidden">Jumlah Soal</span>
              <span className="font-medium">{data.totalQuestion} Soal</span>
            </div>
          </div>

          <div
            onClick={onClick}
            className={`p-2 rounded-md bg-blue cursor-pointer md:block hidden ${minimized && "mt-6"
              }`}
          >
            <img src="/assets/icons/arrow-right.png" alt="next" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamTeacher
