import { Fragment, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AlertTimeOut } from "../../components/Alert";
import { PopupConfirmation } from "../../components/Popup";
import { useQuery } from "../../hooks/useQuery";
import { doneExam } from "../../service/students/studentService";

function StartExam({data, popup, token, idScoring, countDownRef}) {
  const id = useQuery("id")

  const [state, setState] = useState(localStorage.getItem(`examAnswer-${id}`) ? JSON.parse(localStorage.getItem(`examAnswer-${id}`)).examAnswer : {})
  const [showPopup, setShowPopup] = useState(false)
  const [showAnswer, setShowAnswer] = useState(false)
  const match = useRouteMatch()
  const history = useHistory()

  useEffect(() => {
    popup(false)
  }, [popup])

  useEffect(() => {
    if(state) {
      localStorage.setItem(`examAnswer-${id}`, JSON.stringify({idExam: id, examAnswer: state}))
    }
  }, [id, state])

  if(data.length === 0) {
    return <div>Loading...</div>
  }

  const handleChangeAnswer = (input) => {
    const value = input.target.value
    setState(prev => ({
      ...prev,
      [input.target.name]: value
    }))
  }

  const splitedLink = data.linkPdf?.split("/")
  splitedLink[splitedLink.length - 1] = "preview"
  const link = splitedLink.join('/')

  const handleComplete = () => {
    const answers = Object.entries(state).map(data => ({
      number: parseInt(data[0]),
      answer: data[1],
    }))

    return answers
  }

  const handleClickDone = async () => {
    const answer = handleComplete()
    console.log(answer)
    const result = await doneExam(answer, idScoring, token)
    console.log(result)
    if(result.status === "Success") {
      return history.push(`${match.path}?type=done`)
    }
  }

  const answers = []

  for(let i = 1; i<=data.totalQuestion; i++) {
    answers.push(<Answer hasAnswered={state} key={i} i={i} handleChangeAnswer={handleChangeAnswer}/>)
  }

  return (
    <Fragment>
      <PopupConfirmation onClickOK={handleClickDone} open={showPopup} setOpen={setShowPopup} title="Anda Yakin Ingin Selesai Kuis Sekarang?" bgButtonOK="bg-red-500" textCancel="Kembali" textOK="Selesai" />
      <div className='fixed w-full inset-0 h-screen flex flex-col bg-gray md:p-7 p-4'>
        <h1 className='font-medium text-xl mb-6 lg:block hidden'>Ujian Online</h1>
        <div className='flex justify-between items-center'>
          <div className='lg:flex flex-col hidden'>
            {/* <span className='txt-gray text-sm'>Matematika</span>
            <span className='text-xl font-medium'>Belajar Matematika</span> */}
          </div>
          <div className='flex lg:w-max w-full justify-end'>
            <div className='cursor-default p-3 mr-3 px-6 rounded-2xl bg-white text-lg font-medium'>
              <Countdown ref={countDownRef} history={history} onComplete={handleComplete} date={new Date(data.startTime).getTime() + data.duration} renderer={TimeOut} />
            </div>
            <button className='p-3 px-8 rounded-xl bg-red-500 text-white md:text-md text-xs' onClick={() => setShowPopup(true)}>Selesai</button>
          </div>
        </div>

        <div className='flex lg:flex-row flex-col h-full overflow-hidden mt-6'>
          <div className='lg:w-5/6 bg-white h-full rounded-xl overflow-y-auto lg:mr-6'>
            <iframe className='w-full h-full' src={`${link}`} title="quiz"/>
          </div>
          <div className={`bg-white ${showAnswer ? 'h-44 transition-all' : `${document.body.clientWidth >= 968 ? 'h-full' : 'h-20'} transition-all `} lg:w-2/6 text-center md:rounded-2xl rounded-tl-2xl rounded-tr-2xl shadow-xl lg:shadow-none z-10`}>
            <div className='m-auto h-1 w-full h-7 lg:hidden block cursor-pointer p-3' onClick={() => setShowAnswer(!showAnswer)}>
              <span className='w-6 h-1 bg-black rounded-md block m-auto'></span>
            </div>
            <div className='overflow-y-auto md:mt-6 px-6 pb-6 h-full flex flex-col'>
              {answers}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

function Answer({handleChangeAnswer, i, hasAnswered}) {

  return (
    <div className='flex lg:flex-col mb-4 items-center lg:items-start '>
      <span className='font-medium md:mr-6 mr-3 md:text-base text-sm w-10'>No. {i}</span>
      <div className='grid grid-cols-5 gap-2 mt-3 pb-5 border-b-2 w-full md:h-20 h-16'>
        <label htmlFor={`${i}A`} className={`bg-gray text-center  rounded-xl cursor-pointer relative`}>
          <input defaultChecked={hasAnswered[i] === "A"} onChange={handleChangeAnswer} type="radio" id={`${i}A`} name={`${i}`} value="A" className='hidden' />
          <span className='checkmark absolute inset-0 flex justify-center items-center rounded-md md:text-base text-sm'>A</span>
        </label>
        <label htmlFor={`${i}B`} className={`bg-gray text-center  rounded-xl cursor-pointer relative`}>
          <input defaultChecked={hasAnswered[i] === "B"} onChange={handleChangeAnswer} type="radio" id={`${i}B`} name={`${i}`} value="B" className='hidden' />
          <span className='checkmark absolute inset-0 flex justify-center items-center rounded-md md:text-base text-sm'>B</span>
        </label>
        <label htmlFor={`${i}C`} className={`bg-gray text-center  rounded-xl cursor-pointer relative`}>
          <input defaultChecked={hasAnswered[i] === "C"} onChange={handleChangeAnswer} type="radio" id={`${i}C`} name={`${i}`} value="C" className='hidden' />
          <span className='checkmark absolute inset-0 flex justify-center items-center rounded-md md:text-base text-sm'>C</span>
        </label>
        <label htmlFor={`${i}D`} className={`bg-gray text-center  rounded-xl cursor-pointer relative`}>
          <input defaultChecked={hasAnswered[i] === "D"} onChange={handleChangeAnswer} type="radio" id={`${i}D`} name={`${i}`} value="D" className='hidden' />
          <span className='checkmark absolute inset-0 flex justify-center items-center rounded-md md:text-base text-sm'>D</span>
        </label>
        <label htmlFor={`${i}E`} className={`bg-gray text-center  rounded-xl cursor-pointer relative`}>
          <input defaultChecked={hasAnswered[i] === "E"} onChange={handleChangeAnswer} type="radio" id={`${i}E`} name={`${i}`} value="E" className='hidden' />
          <span className='checkmark absolute inset-0 flex justify-center items-center rounded-md md:text-base text-sm'>E</span>
        </label>
      </div>
    </div>
  )  
}

function TimeOut({ completed, props: { onComplete, history }, formatted: { hours, minutes, seconds } }) {

  const handleClickDone = () => {
    const answer = onComplete()
    history.push("/dashboard/exam?type=done")

    console.log(answer)
  }

  if (completed) {
    return (
      <Fragment>
        <AlertTimeOut showAlert text="Waktu Telah Habis!" onClickOK={handleClickDone} />
        <span>{hours}:{minutes}:{seconds}</span>
      </Fragment>
    )
  } else {
    return <span>{hours}:{minutes}:{seconds}</span>;
  }
}

export default StartExam