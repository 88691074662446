import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Section, Title, SubTitle } from '../Section';
import { CardGuru } from '../Cards';
import { responsiveMultiCarouselGuru } from '../../utils/carousel/responsiveCarousel'

function Teacher() {
    return (
        <Section className='flex-col' id='guru'>
            <div className='flex flex-col items-center text-center'>
                <SubTitle><span className='txt-black md:text-base text-md'>Guru Kami</span></SubTitle>
                <Title width='w-1/2'>Belajar Sambil <span className='txt-blue'>Bahagia </span>dengan Guru Terbaik Kami</Title>
            </div>
            <MultiCarousel draggable={false} removeArrowOnDeviceType={["tablet", "mobile"]} responsive={responsiveMultiCarouselGuru} showDots containerClass='p-6 h-max pb-16'>
                <CardGuru
                    name="Bunda Retno"
                    tutor="Tutor Kimia"
                    nameImage="retno"
                    type="jpeg"
                />
                <CardGuru 
                    name="Kak Farah"
                    tutor="Farahdillah Nursyifa S.Pd"
                    nameImage="farahdillah"
                />
                <CardGuru 
                    name="Kak Aini"
                    tutor="Tutor IPS, PPKn dan Bahasa Arab"
                    nameImage="nur_ainiyah"
                />
                <CardGuru 
                    name="Kak Diaz"
                    tutor="Tutor Matematika"
                    nameImage="diaz-elprana"
                />
                <CardGuru 
                    name="Kak Fuzi"
                    tutor="Tutor Bahasa Inggris"
                    nameImage="fuzi-inti"
                />
                <CardGuru 
                    name="Kak Iyat"
                    tutor="Tutor Matematika"
                    nameImage="sriyati"
                />
                <CardGuru 
                    name="Kak Nurul"
                    tutor="Tutor Biologi"
                    nameImage="nurul-aulia"
                />
                <CardGuru 
                    name="Kak Heni"
                    tutor="Tutor Bahasa Indonesia"
                    nameImage="heni"
                    type="jpeg"
                />
                <CardGuru 
                    name="Kak Ichsan"
                    tutor="Tutor Biologi"
                    nameImage="ichan"
                    type='jpeg'
                />
                <CardGuru 
                    name="Kak Rizka"
                    tutor="Tutor Bahasa Inggris"
                    nameImage="rizka"
                    type='jpeg'
                />
            </MultiCarousel>
        </Section>
    )
}

export default Teacher