import { Fragment, useState, useEffect } from "react"
import { NavDashboard } from '../../../components/Navbar'
import { PopupPaymentUpload } from '../../../components/Popup'
import { useForm } from "react-hook-form"
import { Input } from '../../../components/Input'
import { CardPaymentPackage, CardPaymentMethod } from '../../../components/Cards'
import { useParams } from 'react-router-dom'
import { usePaymentData } from '../../../hooks/usePaymentData'
import Close from "../../../components/Close"
import { Table, TableBody, TableData, TableHead, TableRow } from '../../../components/Table'
import { ContentDashboard } from "../../../layouts/Dashboard"
import { getPackagePayment, postPaymentData, checkPromo } from '../../../service/parent/parentService'
import { useCookies } from '../../../hooks/useCookies'
import { getAllPaymentData } from '../../../service/parent/parentService'
import { AlertSuccess } from "../../../components/Alert"

function PaymentManage() {
  const { stepPayment } = usePaymentData()

  return (
    <Fragment>
      <NavDashboard title="Pembayaran" />
      {stepPayment === 1 && <DataPayment />}
      {stepPayment === 2 && <PaymentPackage />}
    </Fragment>
  )
}

function DataPayment() {
  const [dataPayment, setDataPayment] = useState([])
  const { getCookie } = useCookies()
  const token = getCookie('token')

  useEffect(() => {
    const getAllPayment = async () => {
      const allPayment = await getAllPaymentData(token)
      if (allPayment.status === "success") {
        return setDataPayment(allPayment.data)
      }
    }

    getAllPayment()
  }, [token])

  return (
    <ContentDashboard>
      <h1 className='font-medium text-xl'>Data Pembayaran</h1>
      <Table>

        <TableHead className='md:block hidden'>
          <TableRow className='text-sm'>
            <TableData>ID Pembayaran</TableData>
            <TableData >Nama Murid</TableData>
            <TableData>Tanggal Bayar</TableData>
            <TableData className='text-center'>Tanggal Habis</TableData>
            <TableData className='text-center'>Harga</TableData>
            <TableData className='text-center'>Status Bayar</TableData>
          </TableRow>
        </TableHead>

        <TableBody className="border-t border-current md:border-t-0 ">
          {
            dataPayment.map((student) => {
              console.log(student)
              return (
                <TableRow key={student.id} className='md:flex-row flex-col relative md:items-center items-start'>
                  <TableData className='md:font-normal font-semibold md:text-sm text-md'>{student.id}</TableData>
                  <TableData className='md:text-black txt-gray md:mb-0 mb-2'>{student.studentname.name}</TableData>
                  <TableData className='md:text-sm text-xs md:mb-0 mb-1 flex md:w-32 w-full'>
                    <span>{student.transactionDate.split("T")[0]}</span>
                    <span className='md:hidden ml-1'> - {student.dpackages[0]?.transactionPackage?.endDate.split("T")[0]}</span>
                  </TableData>
                  <TableData className='md:text-sm text-xs md:block hidden'>{student.dpackages[0]?.transactionPackage?.endDate.split("T")[0]}</TableData>
                  <TableData className='md:w-auto w-full md:border-0 border md:p-0 p-2 md:mt-0 mt-2 md:rounded-none rounded-xl md:font-normal font-medium flex justify-between items-center md:justify-start'>
                    <span className='md:hidden md:transform md:scale-0 txt-gray text-xs'>Harga</span>
                    Rp{student.total.toLocaleString()}</TableData>
                  <TableData className='md:static absolute right-0'>
                    <span className={`${student.status === "proses" ? "bg-yellow-400 text-white" : student.status === "diterima" ? "border-green-200 text-green-200 border-2" : "border-red-400 text-red-400 border-2"} p-2 m-auto block text-center md:w-max rounded-xl md:text-sm text-xs`}>{student.status}</span>
                  </TableData>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </ContentDashboard>
  )
}

function PaymentPackage() {
  const [popupPaymentUpload, setPopupPaymentUpload] = useState(false)
  const [valueBankType, setValueBankType] = useState("")
  const [hasSubmit, setHasSubmit] = useState(false)
  const { state, eventListener: { onChangeDataPayment, resetDataPayment, setStepPayment } } = usePaymentData()
  const { id } = useParams()
  const { register, handleSubmit } = useForm()
  const { getCookie } = useCookies()
  const [payment, setPayment] = useState([])
  const [totalPayment, setTotalPayment] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [cutOff, setCutOff] = useState(0)

  const token = getCookie('token')

  useEffect(() => {
    const { idPackage } = state

    const fetchPayment = async () => {
      const payment = await getPackagePayment(token, { id: idPackage })
      if (payment.status === "success") {
        const getTotalPrice = payment.data.map(data => data.price).reduce((acc, curr) => acc + curr)
        setTotalPayment(cutOff ? getTotalPrice - cutOff : getTotalPrice)
        return setPayment(payment.data)
      }

      console.log(payment) // check error
    }
    fetchPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, cutOff])

  useEffect(() => {
    if (payment.length >= 0) {
      const getTotalPrice = payment.map(data => data.price).reduce((acc, curr) => acc + curr, 0)
      setTotalPayment(cutOff ? getTotalPrice - cutOff : getTotalPrice)
    }
  }, [payment, cutOff])

  const onSubmit = async () => {
    state.bank = valueBankType
    state.total = totalPayment

    console.log(state)

    if (!state.bank) {
      return alert('Harap pilih metode pembayaran yang anda gunakan')
    }

    setPopupPaymentUpload(false)
    setHasSubmit(true)

    const paymentRequest = await postPaymentData(state, token)
    console.log(paymentRequest)
    if (paymentRequest.status === "success") {
      return setShowAlert(true)
    }
  }

  const handleClikInputPromo = async (button) => {
    const { idPackage } = state
    const promo = { promoName: button.target.previousElementSibling.value, idPackage }
    const result = await checkPromo(promo, token)
    if (result.status === "Error") {
      return alert(result.message)
    }

    console.log(result)

    setCutOff(parseFloat(result.data.cutoff))
  }

  const handleClickPaymentMethod = (bankType) => {
    setValueBankType(bankType)
  }

  const handleClickClose = () => {
    resetDataPayment()
  }

  return (
    <Fragment>
      <AlertSuccess showAlert={showAlert} onClickOK={setStepPayment} text="Transaksi Berhasil" />
      <div className='w-full bg-white rounded-xl p-6 mt-6'>
        <div className='flex justify-between relative items-center'>
          <h1 className='text-xl font-semibold'>Pembayaran Paket</h1>
          <div onClick={handleClickClose}>
            <Close to="/parent/payment" />
          </div>
        </div>

        <form className='mt-6' onChange={onChangeDataPayment} onSubmit={handleSubmit(onSubmit)}>
          <span className='md:text-base text-sm'>Paket Dipilih</span>
          <div className='grid md:grid-cols-3 gap-6 mt-6'>
            {payment.map(data => {
              return <CardPaymentPackage key={data.id} {...data} setPayment={setPayment} payment={payment} />
            })}
          </div>

          <div className='mt-6 flex relative'>
            <Input register={register('name')} className='w-full uppercase' />
            <button type="button" disabled={hasSubmit} onClick={handleClikInputPromo} className={`${hasSubmit && 'cursor-not-allowed'} md:w-52 sm:w-42 w-32 h-full right-0 md:static absolute bg-blue md:py-4 px-6 text-white sm:text-sm text-xs rounded-xl transform md:-translate-x-10`}>Masukan Promo</button>
            {cutOff ? <span className='absolute bottom-0 left-0 text-xs text-green-600 -top-4'>Berhasil menambahkan Promo, anda hemat Rp{cutOff.toLocaleString()}</span> : ""}
          </div>

          <div className='mt-6'>
            <span>Metode Pembayaran</span>
            <Input register={register('bank')} defaultValue={valueBankType} className='hidden' />
            <div className='flex mt-4 w-full overflow-auto'>
              <div onClick={() => handleClickPaymentMethod("bca")} className='p-2'>
                <CardPaymentMethod number="6960129605" type="bca" active={valueBankType} />
              </div>
              <div onClick={() => handleClickPaymentMethod("dana-mandiri")} className='p-2'>
                <CardPaymentMethod number="89508081517863898" type="dana-mandiri" active={valueBankType} />
              </div>
              <div onClick={() => handleClickPaymentMethod("dana-bri")} className='p-2'>
                <CardPaymentMethod number="88810081517863898" type="dana-bri" active={valueBankType} />
              </div>
              <div onClick={() => handleClickPaymentMethod("dana-bni")} className='p-2'>
                <CardPaymentMethod number="8810081517863898" type="dana-bni" active={valueBankType} />
              </div>
            </div>
          </div>

          <div className='bg-gray3 w-full p-6 mt-6 rounded-xl'>
            <h3 className='md:text-lg text-md font-bold'>Detail Harga</h3>
            <div className='flex flex-col text-sm mt-6'>
              {payment.map(data => {
                const filterGrades = data.grades.map(grade => grade.grade).sort((a, b) => a - b)

                return (
                  <div key={data.id} className='w-full flex justify-between mb-2'>
                    <span className='md:text-base text-xs block'>{data.type.type} Kelas {filterGrades.join('/')} {data.classtype} - {data.price.toLocaleString()}/{data.per}</span>
                    <span className='md:text-base text-xs block'>Rp{data.price.toLocaleString()}</span>
                  </div>
                )
              })}
              {cutOff ? (
                <div className='w-full flex justify-between mb-2'>
                  <span className='md:text-base text-xs block'>Potongan</span>
                  <span className='md:text-base text-xs block'>Rp{cutOff.toLocaleString()}</span>
                </div>
              ) : ""}
            </div>
            <span className='block w-full border border-black mt-6'></span>
            <div className='w-full flex justify-between mt-6'>
              <span className='block md:text-xl text-lg font-bold'>Total</span>
              <span className='md:text-2xl text-xl font-semibold'>Rp{totalPayment.toLocaleString()}</span>
            </div>
          </div>
          <div className='w-full flex justify-end'>
            <button type="button" onClick={() => setPopupPaymentUpload(true)} disabled={hasSubmit || totalPayment === 0 ? true : false} className={`md:text-sm text-xs bg-blue py-3 px-6 text-white rounded-xl mt-8 ${(hasSubmit || totalPayment === 0 ? true : false) && 'cursor-not-allowed'} `}>Upload Bukti Pembayaran</button>
            <PopupPaymentUpload open={popupPaymentUpload} setOpen={setPopupPaymentUpload} />
          </div>
        </form>
      </div>
    </Fragment>
  )
}

export default PaymentManage
