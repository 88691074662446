import { Fragment, useEffect, useState } from "react"
import { NavDashboard } from '../../components/Navbar'
import { ContentDashboard, MainDashboard } from '../../layouts/Dashboard'
import { Table, TableBody, TableHead, TableRow, TableData } from '../../components/Table'
import { Link } from "react-router-dom"
import { PopupDeleteConfirmation, PopupSettingRekening } from '../../components/Popup'
import { useCookies } from "../../hooks/useCookies"
import { getAllAdmin, deleteAdmin } from '../../service/admin/adminService'

function AllAdmin() {
  const [open, setOpen] = useState(false)
  const [showRekening, setShowRekening] = useState(false)
  const [admin, setAdmin] = useState([])
  const [currentId, setCurrentId] = useState(null)
  const [hasDeletedAdmin, setHasDeletedAdmin] = useState(false)

  const { getCookie } = useCookies()
  const token = getCookie("token")

  useEffect(() => {
    const fetchAllAdmin = async () => {
      const result = await getAllAdmin(token)
      setAdmin(result.data)
      setHasDeletedAdmin(false)
    }

    fetchAllAdmin()
  }, [token, hasDeletedAdmin])

  const handleClickSetting = () => {
    setShowRekening(true)
  }

  const handleClickTrash = (event, adminId) => {
    event.stopPropagation()
    setCurrentId(adminId)
    setOpen(true)
  }

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Admin" />
        <PopupDeleteConfirmation id={currentId} setState={() => setHasDeletedAdmin(true)} token={token} action={deleteAdmin} open={open} setOpen={setOpen} text="Yakin ingin menghapus admin" />
        <PopupSettingRekening open={showRekening} setOpen={setShowRekening} />
        <ContentDashboard>
          <div className='flex justify-between items-center'>
            <div className='flex flex-col'>
              <h1 className='text-xl font-medium'>Data Admin</h1>
              <span className='text-sm'>{admin.length} Admin</span>
            </div>

            <div className='flex'>
              <div className='bg-blue rounded-md p-2 cursor-pointer mr-4 text-white' onClick={handleClickSetting}>
                Pengaturan Rekening
              </div>

              <Link to='/admin/all/add'>
                <div className='bg-blue rounded-md p-2 cursor-pointer'>
                  <img src="/assets/icons/plus.png" alt="add" />
                </div>
              </Link>
            </div>
          </div>

          <Table className='mt-4'>
            <TableHead className='md:flex hidden'>
              <TableRow className='text-sm'>
                <TableData className=''>Nama Guru</TableData>
                <TableData className='text-center'>Nomor Hp</TableData>
                <TableData className='text-center'>Email</TableData>
                <TableData className='text-center'>Status</TableData>
                <TableData className='text-center'>Action</TableData>
              </TableRow>
            </TableHead>
            <TableBody>
              {admin.map((data) => {
                return (
                  <TableRow key={data.id} className='items-center hover:bg-blue-50 relative'>
                    <TableData className='md:w-40 w-max md:h-auto h-20 justify-center items-center flex md:block'>
                      <div className='flex items-center h-full w-full'>
                        <div className='w-14 h-14 flex justify-center items-center'>
                          <img className='w-full' src="/assets/avatar/default.png" alt="profile" />
                        </div>
                        <span className='md:ml-4 ml-2 text-xs w-32 block font-medium'>{data.name}</span>
                      </div>
                    </TableData>
                    <TableData className='md:block hidden'>{data.phone}</TableData>
                    <TableData className='md:static md:break-words absolute w-max md:w-32 left-16 text-xs md:text-sm bottom-8'>{data.email}</TableData>
                    <TableData className='text-center'>
                      <span className={`${data.status === "active" ? 'border-green-200 text-green-200' : 'border-red-500 text-red-500'} md:static absolute top-6 left-10 block rounded-xl m-auto w-max py-2 px-6 md:border-2 md:text-base text-xs`}>{data.status}</span>
                    </TableData>
                    <TableData className='text-center'>
                      <i onClick={(event) => handleClickTrash(event, data.id)} className='block md:w-6 md:h-6 w-4 h-4 bg-cover  m-auto cursor-pointer bg-no-repeat' style={{ backgroundImage: "url('/assets/icons/trash-red.png')" }} />
                    </TableData>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  )
}

export default AllAdmin