import { Section, Title, SubTitle } from '../Section';
import { CardTestiMoni } from '../Cards';
import { responsiveMultiCarouselTestimoni } from '../../utils/carousel/responsiveCarousel'
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Testimoni() {
    return (
        <Section className='flex-col' marginTop='mt-0' id="testimoni">
            <div className='flex flex-col items-center text-center'>
                <SubTitle><span className='txt-dark md:text-base text-md'>Testimoni</span></SubTitle>
                <Title width='w-1/2'>Apa Kata <span className='txt-blue'>Mereka</span></Title>
            </div>

            <MultiCarousel draggable={false} showDots={true} removeArrowOnDeviceType={["tablet", "mobile"]} responsive={responsiveMultiCarouselTestimoni} containerClass='p-6 h-max pb-16 lg:pb-32'>
                <CardTestiMoni
                    name="Muhammad zaki akbar"
                    school="SMP Negeri 3 Tangsel"
                    text="Alhamdulillah berkat bimbel di Star Bimbel, saya bisa menguasai matematika dengan mudah" 
                    imgName="haykal"/>
                <CardTestiMoni
                    name="Rizky Harumdhany Mamonto"
                    school="SMAN 8 tangsel"
                    text="tutornya seru-seru, jadi belajarnya juga jadi seru. dan materi-materi yg di sediakan dari bimbel sangat lengkap"
                    imgName="rizky-harumdany"
                    typeExt="png"/>
                <CardTestiMoni
                    name="M Adzka Adzqiya"
                    school="SMPN 85"
                    text="yang ngajar seru-seru jadi ga bosen" 
                    imgName="muhammad-azka"/>
                <CardTestiMoni
                    name="Yasmin arafah hakim"
                    school="Sman 87 jakarta"
                    text="Gurunya baik, bisa diajak cerita. materi yang di terangkan juga mudah di pahami"
                    imgName="yasmin-arafah" />
                <CardTestiMoni
                    name="Putri zakia andini"
                    school="SMPN 3 Tangerang Selatan"
                    text="Menurut saya selama saya mengikuti pembelajaran di star bimbel, saya bisa jadi lebih mengerti dan mengikuti mapel yang diajarkan di sekolah. Cara pembahasan pada materi yg disampaikan juga cukup mudah untuk dipahami dan dipelajari." 
                    imgName="putri-zakia"/>
                <CardTestiMoni
                    name="Muhamad ramdani wakang"
                    school="Smpn226 jakarta"
                    text="Nilai menjadi lebih tinggi" 
                    imgName="ibu-dani"/>
                <CardTestiMoni
                    name="Neva Salsabila"
                    school="Smp Insan Teladan"
                    text="Selama belajar bimbel pengetahuan saya meningkat. Banyak diajarkan tips mudah dalam menyelesaikan soal di setiap pertemuan belajar. Disini guru yang mengajar ramah, membuat suasana belajar gak terlalu kaku dan jika ingin bertanya gak canggung. Materi yang diajarkan mengiringi materi sekolah, jadi ketika belajar disekolah materi sudah dimengerti. Walaupun bimbel online, cara penyampaian materinya mudah dipahami. Banyak latihan soal juga yang bisa dijadikan bahan pemantapan paham materi. " 
                    imgName="neva-salsabila"/>
            </MultiCarousel>

        </Section>
    )
}

export default Testimoni