import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { CONSTANT } from '../../../constant';
import TextRed from '../../ErrorField';
import { Input, InputGroup } from '../../Input'
import { REGEX } from '../../../utils/regex'
import { loadImage } from '../../../utils/common/loadImage';
import { addAdmin } from '../../../service/admin/adminService'

export function AddAdminForm({ token }) {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [imageKTP, setImageKTP] = useState('')
  const [image, setImage] = useState('')
  const [messageBackend, setMessageBackend] = useState({})
  const history = useHistory()

  const handleChangeImage = (image) => {
    loadImage(image, file => {
      setImage(file.name)
    })
  }

  const handleChangeImageKTP = (image) => {
    loadImage(image, file => {
      setImageKTP(file.name)
    })
  }

  const onSubmit = async (data) => {
    data.image = image
    data.idCard = imageKTP
    
    const result = await addAdmin(data, token)
    console.log(result)
    if (result?.errors) {
      return setMessageBackend(result.errors)
    }

    alert("Tambah admin berhasil")
    return history.push('/admin/all')
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex justify-between items-center'>
          <h1 className='font-medium text-xl'>Tambah Admin</h1>
          <Link to='/admin/all'>
            <div className='p-3 bg-gray rounded-md'>
              <img src="/assets/icons/exit.png" alt="add" />
            </div>
          </Link>
        </div>
        <div className='mt-6 w-full md:flex justify-between'>
          <InputGroup className='md:w-1/2 md:mr-4'>
            <label className='mb-2 text-sm'>Nama Lengkap</label>
            <Input register={register('name', { required: true })} />
            {(errors.name && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.name && <TextRed>{messageBackend.name.message}</TextRed>)}
          </InputGroup>
          <InputGroup className='md:w-1/2'>
            <label className='mb-2 text-sm'>Email</label>
            <Input register={register('email', { required: true, pattern: REGEX.email })} />
            {(errors.email && <TextRed>{CONSTANT.INVALID_EMAIL}</TextRed>) || (messageBackend.email && <TextRed>{messageBackend.email.message}</TextRed>)}
          </InputGroup>
        </div>
        <InputGroup>
          <label className='mb-2 text-sm'>Password</label>
          <Input type="password" register={register('password', { required: true, pattern: REGEX.password })} />
          {(errors.password && <TextRed>{CONSTANT.INVALID_PASSWORD}</TextRed>) || (messageBackend.password && <TextRed>{messageBackend.password.message}</TextRed>)}
        </InputGroup>
        <InputGroup className=''>
          <label className='mb-2 text-sm'>Tempat Lahir</label>
          <Input type="birthplace" register={register('birthplace', { required: true })} />
          {(errors.birthplace && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.birthplace && <TextRed>{messageBackend.birthplace.message}</TextRed>)}
        </InputGroup>
        <div className='w-full md:flex justify-between'>
          <InputGroup className='md:w-1/2 md:mr-4'>
            <label className='mb-2 text-sm'>Tanggal Lahir</label>
            <Input type="date" register={register('birthdate', { required: true })} />
            {(errors.birthdate && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.birthdate && <TextRed>{messageBackend.birthdate.message}</TextRed>)}
          </InputGroup>
          <InputGroup className='md:w-1/2'>
            <label className='mb-2 text-sm'>Nomor Hp</label>
            <Input register={register('phone', { required: true })} />
            {(errors.phone && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.phone && <TextRed>{messageBackend.phone.message}</TextRed>)}
          </InputGroup>
        </div>
        <InputGroup className='w-full md:flex md:flex-row justify-between items-center'>
          <div className='md:w-1/2 w-full flex flex-col md:mr-4'>
            <label className='mb-2 text-sm'>Status</label>
            <select className='border-gray rounded-xl p-3 outline-none w-full' {...register('status', { required: true })}>
              <option value="inactive">Inactive</option>
              <option value="active">Active</option>
            </select>
            {(errors.status && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.status && <TextRed>{messageBackend.status.message}</TextRed>)}
          </div>
          <div className='md:w-1/2 w-full md:mt-0 mt-3 flex flex-col'>
            <label className='mb-2 text-sm'>Role</label>
            <select className='border-gray rounded-xl p-3 outline-none' {...register('role', { required: true })}>
              <option value="admin">Admin</option>
              <option value="superadmin">Super Admin</option>
            </select>
            {(errors.role && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.role && <TextRed>{messageBackend.role.message}</TextRed>)}
          </div>
        </InputGroup>
        <InputGroup>
          <label className='mb-2 text-sm'>Address</label>
          <textarea className='rounded-xl border-gray p-3 outline-none' {...register('address', { required: true })} />
          {(errors.address && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (messageBackend.address && <TextRed>{messageBackend.address.message}</TextRed>)}
        </InputGroup>

        <div className='md:flex justify-between'>
          <div className='mt-6'>
            <span className='text-sm font-semibold'>Upload Foto</span>
            <div className='flex flex-col'>
              <div className='w-full h-52 rounded-xl border-4 p-4 border-dashed mt-4 flex flex-col items-center bg-lightblue-4 p-4'>
                <div className='mt-6'>
                  <img src="/assets/icons/i-upload.png" alt="upload" />
                </div>
                <span className='text-sm mt-3 text-center'>{' '}
                  <label className='txt-blue cursor-pointer' htmlFor="file">Browse</label>
                  <input {...register('image')} onChange={handleChangeImage} required className='w-full m-auto' type='file' id='file' />
                </span>
                <span className='text-sm txt-gray mt-2'>Supports File JPG - Max. File Size 5 MB </span>
              </div>
            </div>
          </div>
          <div className='mt-6'>
            <span className='text-sm font-semibold'>Upload KTP</span>
            <div className='flex flex-col'>
              <div className='w-full h-52 rounded-xl border-4 p-4 border-dashed mt-4 flex flex-col items-center bg-lightblue-4 p-4'>
                <div className='mt-6'>
                  <img src="/assets/icons/i-upload.png" alt="upload" />
                </div>
                <span className='text-sm mt-3 text-center'>{' '}
                  <label className='txt-blue cursor-pointer' htmlFor="file2">Browse</label>
                  <input {...register('idCard')} onChange={handleChangeImageKTP} required className='w-full m-auto' type='file' id='file2' />
                </span>
                <span className='text-sm txt-gray mt-2'>Supports File JPG - Max. File Size 5 MB </span>
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-end mt-8'>
          <button className='p-4 bg-blue text-white rounded-md'>Tambah</button>
        </div>
      </form>
    </Fragment>
  )
}