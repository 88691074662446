import { BASE_URL } from "../../constant";

const headersWithBearer = (token) => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
});

// Package
export async function createPackage(data, token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/package/createbyadmin`, {
      method: "POST",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
  return result;
}

export async function deletePackage(id, token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/package/delete/${id}`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ idPackage: id }),
    })
  ).json();
  return result;
}

export async function getAllPackage(token) {
  return await (
    await fetch(`${BASE_URL}/v1/package/getallpackage`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

// Admin
export async function addAdmin(data, token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/admin/postadmin`, {
      method: "POST",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
  return result;
}

export async function deleteAdmin(data, token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/admin/delete`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ id: data }),
    })
  ).json();
  return result;
}

export async function getAllAdmin(token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getalladmin`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

// Modul
export async function createModul(data, token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/modul/create`, {
      method: "POST",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
  return result;
}

export async function getAllGrade(token) {
  return await (
    await fetch(`${BASE_URL}/v1/modul/getallgrade`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getAllLesson(token) {
  return await (
    await fetch(`${BASE_URL}/v1/modul/getalllesson`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getAllChapter(token, id) {
  return await (
    await fetch(`${BASE_URL}/v1/modul/getallchapter/${id}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getAllModul(token) {
  return await (
    await fetch(`${BASE_URL}/v1/modul/getall`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function updateModul(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/modul/update`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function deleteModul(idModul, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/modul/${idModul}`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ idModul: idModul }),
    })
  ).json();
}

// Promo
export async function createPromo(data, token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/promo/create`, {
      method: "POST",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
  return result;
}

export async function editPromo(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/promoupdate`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function deletePromo(idPromo, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/deletepromo/${idPromo}`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ idPromo: idPromo }),
    })
  ).json();
}

export async function getAllPromo(token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getallpromo`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getPromoById(token, id) {
  return await (
    await fetch(`${BASE_URL}/v1/promo/${id}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getParentByIdStudent(token, id) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getparentbyidstudent/${id}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

// Payment
export async function getAllPayment(token) {
  return await (
    await fetch(`${BASE_URL}/v1/payment/getallbyadmin`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function paymentVerify(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/paymentverify`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

// Teacher
export async function getAllTeacher(token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getallteacher`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function statusTeacher(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/statusteacher`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function getTeacherDetail(id, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getteacherdetail/${id}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function deleteTeacher(idTeacher, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/deleteteacher/${idTeacher}`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ id: idTeacher }),
    })
  ).json();
}

// Student
export async function getAllStudent(token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getallstudent`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function statusStudent(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/statusstudent`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function getStudentDetail(id, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getstudentdetail/${id}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function deleteStudent(idStudent, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/deletestudent/${idStudent}`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ id: idStudent }),
    })
  ).json();
}

export async function studentUpdate(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/studentupdate/`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

// Class
export async function getAllClass(token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getallclass`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getAllClassDetail(id, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/class/${id}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getAllLessonSchedule(token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getalllesson`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function deleteClass(idClass, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/class/${idClass}`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ idClass: idClass }),
    })
  ).json();
}

export async function classCreate(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/classcreate`, {
      method: "POST",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function scheduleCreate(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/schedulecreate/`, {
      method: "POST",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function scheduleUpdate(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/scheduleupdate/`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function deleteSchedule(idSchedule, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/schedule/${idSchedule}`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ idSchedule: idSchedule }),
    })
  ).json();
}

// Quiz
export async function getAllQuiz(token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getallquiz`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getQuizDetail(idQuiz, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getquizdetail/${idQuiz}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function postQuiz(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/postquiz`, {
      method: "POST",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function updateQuiz(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/updatequiz/${data.id}`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function deleteQuiz(idQuiz, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/deletequiz/${idQuiz}`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ id: idQuiz }),
    })
  ).json();
}

// Exam
export async function getAllExam(token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getallexam`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getExamDetail(idExam, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/getexamdetail/${idExam}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function postExam(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/postexam`, {
      method: "POST",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function updateExam(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/updateexam/${data.id}`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function deleteExam(idQuiz, token) {
  return await (
    await fetch(`${BASE_URL}/v1/admin/deleteexam/${idQuiz}`, {
      method: "DELETE",
      headers: headersWithBearer(token),
      body: JSON.stringify({ id: idQuiz }),
    })
  ).json();
}

