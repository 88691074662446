import { useHistory } from 'react-router-dom';
import { NavDashboard } from '../../components/Navbar'

function DetailQuiz({data}) {
  const history = useHistory()

  if(data.length === 0) {
    return <div>Loading...</div>
  }

  const splitedLink = data.answerPdf?.split("/")
  splitedLink[splitedLink.length - 1] = "preview"
  const link = splitedLink.join('/')

  return (
    <div className='relative w-full h-screen bg-gray'>
      <div className='absolute inset-4 md:inset-7 z-10 h-12'>
        <NavDashboard title="Pembahasan Soal" />
      </div>
      <div className='mt-6 md:p-6 p-4 lg:pt-24 md:pt-36 pt-36 bottom-0 h-full absolute w-full flex flex-col'>
        <div className='flex md:flex-row flex-col justify-between md:items-center'>
          <div className='flex flex-col'>
            {/* <span className='txt-gray md:text-base text-sm'>Matematika</span>
            <span className='md:text-xl font-bold'>Bab 1 - Belajar Matematika</span> */}
          </div>
          <div className='md:flex items-center'>
            <span className='md:text-xl md:font-semibold mr-6'>Score {data.score}</span>
            <button onClick={() => history.push('/dashboard/quiz')} className='bg-blue text-white p-3 rounded-xl px-10 text-sm md:block hidden'>Selesai</button>
          </div>
        </div>
        <div className='bg-white overflow-y-auto h-full mt-6 rounded-xl p-6'>
          <iframe className="w-full h-full" src={`${link}`} title="pembahasan"/>
        </div>
      </div>
    </div>
  )
}

export default DetailQuiz