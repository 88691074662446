import { BASE_URL } from '../../constant'

async function registerService(data, type, sendMessageFromBackend, history, setStepForm) {
  console.log(data)
  const result = await (await fetch(`${BASE_URL}/v1/${type}/register`, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })).json()

  if(result.errors) {
    console.log(result)
    return sendMessageFromBackend(result.errors)
  }
  
  console.log(result)
  sendMessageFromBackend(result)
  setStepForm(1)
  history.push('/login')
}

export { registerService }