import { Fragment } from "react"
import { NavDashboard } from '../../components/Navbar'
import { useToggle } from "../../hooks/useToggle"
import Close from '../../components/Close'
import { Link } from "react-router-dom"
import { useState, useEffect } from 'react'
import { getProfile } from "../../service/profile/profileService";
import { useCookies } from "../../hooks/useCookies"
import { MainDashboard } from "../../layouts/Dashboard"

function Profile() {
  const { minimized } = useToggle()

  const { getCookie } = useCookies()
  const token = getCookie('token')
  const [profile, setProfile] = useState([])

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getProfile(token)
      if (profile.status === "Success") {
        return setProfile(profile.data)
      }

      console.log(profile) // check error
    }

    fetchProfile()

    return () => setProfile({})
  }, [token])

  console.log(profile)

  return (
    <Fragment>
      <MainDashboard>

        <NavDashboard title="Profile" />
        <div className={`mt-4 p-8 w-full lg:grid ${minimized ? 'grid-cols-1 justify-items-end' : 'grid-cols-1 lg:grid-cols-4'} gap-12 bg-white rounded-xl relative `}>
          <Close to='/dashboard/modul' className='absolute top-6' />
          <div className={`${minimized ? ' w-11/12 lg:flex-row flex-col' : 'col-start-1 col-end-2 row-start-1 row-end-2 flex-col mb-20'} relative flex bg-blue mt-20  shadow-gray rounded-xl text-white justify-center items-center`}>
            <div className={`${minimized ? '-left-20 top-14 bottom-14' : '-top-20 h-32'} lg:absolute w-32 rounded-xl`}>
              <img className='h-full w-full' src={`https://api.starbimbel.com/foto/student/${profile.image}`} alt="profile" />
            </div>
            <div className={`${minimized ? 'flex flex-row justify-center items-center' : ''} w-4/5`}>
              <div className={`${minimized ? 'w-full flex flex-col items-start' : ''}`}>
                <div className={`${minimized ? 'w-4/5' : 'lg:mt-12 items-center '} border-b-2 border-gray-100 py-6 flex flex-col `}>
                  <h2>{profile.fullName}</h2>
                  {/* <span className="text-sm">Kelas 1 SD</span> */}
                </div>

                <div className={`${minimized ? '' : "items-center border-b-2 border-gray-100"}  py-6 flex flex-col`}>
                  {/* <span className="text-xs">Mulai Gabung Sejak</span>
                  <h3>-</h3> */}
                  <span className="text-xs mt-3">Asal Sekolah</span>
                  <h4>{profile.school}</h4>
                </div>
              </div>

              <div className={`${minimized ? "w-2/5 px-20 border-l-2 border-gray-100" : ""}`}>
                {minimized && <span className='mb-4 text-xl'>Kontak</span>}
                <div className='flex flex-col text-sm py-6'>
                  <span>{profile.phone}</span>
                  <span className={`${minimized ? 'mt-4' : 'mt-2'}`}>{profile.email}</span>
                </div>
              </div>
            </div>
          </div>

          <div className={`${minimized ? '' : 'col-start-2 row-start-1 col-end-5 row-end-2'}`}>
            <DetailProfile {...profile} />
          </div>
        </div>
      </MainDashboard>
    </Fragment>
  )
}

function DetailProfile(profile) {
  const { minimized } = useToggle()

  return (
    <div className={`w-full ${minimized ? 'lg:mt-4 mt-12' : 'mt-20'}`}>
      <div className={`${minimized ? 'lg:flex-row flex-col justify-evenly' : 'flex-col'} flex w-full`}>
        <div className={`${minimized ? 'order-2' : 'order-1'}`}>
          <div className={`${minimized ? 'flex-col' : ""} lg:flex-row flex-col flex w-full`}>
            <div className={`${minimized ? 'mt-12' : 'mt-0'} flex lg:w-48 flex-col pb-4 border-b-2 border-gray-200 mr-12 lg:mt-0`}>
              <span className='txt-gray'>Nama Lengkap</span>
              <span className='font-medium'>{profile.fullName}</span>
            </div>
            <div className={`${minimized ? 'mt-12' : ""} flex lg:w-48 lg:mt-0 mt-12 flex-col pb-4 border-b-2 border-gray-200`}>
              <span className='txt-gray'>Nama Panggilan</span>
              <span className='font-medium'>{profile.name}</span>
            </div>
          </div>

          <div className='flex w-full mt-12'>
            <div className='flex lg:w-48 w-full flex-col pb-4 border-b-2 border-gray-200'>
              <span className='txt-gray'>Tempat Tanggal Lahir</span>
              <span className='font-medium'>{profile.birthplace}, {(profile.birthdate)?.toString().split("T")[0]}</span>
            </div>
          </div>
        </div>

        <div className={`${minimized ? 'order-1 lg:w-1/2' : 'order-2'}`}>
          <div className={`flex w-full ${minimized ? 'mt-0' : 'mt-12'}`}>
            <div className='flex w-full flex-col pb-4 border-b-2 border-gray-200'>
              <span className='txt-gray'>Alamat Lengkap</span>
              <span className='font-medium'>{profile.address}</span>
            </div>
          </div>

          {/* <div className='flex w-full mt-12'>
            <div className='flex w-full flex-col pb-4 border-b-2 border-gray-200'>
              <span className='txt-gray'>Pilihan Jenis Kelas Pendaftaran</span>
              <span className='font-medium'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus, reprehenderit</span>
            </div>
          </div> */}
        </div>
      </div>

      <div className='mt-8 w-full flex justify-end text-sm'>
        <Link to="/dashboard/profile/edit?type=password">
          <button className='mr-6 txt-blue p-4'>Edit Password</button>
        </Link>
        <Link to='/dashboard/profile/edit'>
          <button className='bg-blue text-white p-4 rounded-xl'>Edit Profile</button>
        </Link>
      </div>
    </div>
  )
}

export default Profile