import { Fragment, useEffect, useState } from 'react'
import { EditPasswordTeacherForm, EditTeacherForm } from '../../../components/Form/teacher'
import { NavDashboard } from '../../../components/Navbar'
import { useEditType } from '../../../hooks/useEditType'
import { useQuery } from '../../../hooks/useQuery'
import { ContentDashboard, MainDashboard } from '../../../layouts/Dashboard'
import { useCookies } from '../../../hooks/useCookies'
import { getDetailTeacher, updateTeacher } from '../../../service/teacher/teacherService'

function EditProfile() {
  const { type, changeEditTypeTo } = useEditType()
  const editType = useQuery("type")

  const { getCookie } = useCookies()
  const token = getCookie("token")

  const [profile, setProfile] = useState([])

  useEffect(() => {
    const fetchProfile = async () => {
      const result = await getDetailTeacher(token)
      setProfile(result.data)
    }
    fetchProfile()
  }, [token])
  

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Profile" />
        <ContentDashboard>
          {(type === "profil" && editType !== "password") && <EditTeacherForm token={token} updateTeacher={updateTeacher} profile={profile} changeEditTypeTo={changeEditTypeTo} />}
          {(editType === "password" || type !== "profil") && <EditPasswordTeacherForm token={token}/>}
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  )
}

export default EditProfile