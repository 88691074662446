import Container from "../../components/ContainerLogin"
import { FormLogin } from "../Login"

function Login() {
  return (
    <Container title="Halo, Selamat Datang">
      <FormLogin teacher/>
    </Container>
  )
}

export default Login