import { Fragment } from "react"
import { NavDashboard } from '../../../components/Navbar'
import { ContentDashboard, MainDashboard } from '../../../layouts/Dashboard'
import { AddPackageForm } from "../../../components/Form/admin/package"
import { useCookies } from '../../../hooks/useCookies'

function AddPackage() {
  const { getCookie } = useCookies()
  const token = getCookie('token')

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Paket" />
        <ContentDashboard>
          <AddPackageForm token={token} />
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  )
}

export default AddPackage