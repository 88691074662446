import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { routes, RouteWithSubRoute } from '../routes'

function App() {
    return (
        <Router>
            <Switch>
                <RouteWithSubRoute routes={routes} />
            </Switch>
        </Router>
    )
}

export default App