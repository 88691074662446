import { useContext, createContext, useState } from 'react'

const signUpContext = createContext({
  student: {
    fullName: "",
    name: "",
    email: "",
    password: "",
    birthplace: "",
    birthdate: "",  
    phone: "",  
    image: "",
    school: "",
    address: ""
  },
  parent: {
    name: "",
    email: "",
    password: "",
    username: "",
    image: "",
    phone: "",
    whatsapp: "",
    telegram: "",
    instagram: "",
    informBy: ""
  }
})

function SignUpProvider() {
  const [state, setState] = useState({
    student: {
      fullName: "",
      name: "",
      email: "",
      password: "",
      birthplace: "",
      birthdate: "",  
      phone: "",  
      image: "",
      school: "",
      address: ""
    },
    parent: {
      name: "",
      email: "",
      password: "",
      username: "",
      image: "",
      phone: "",
      whatsapp: "",
      telegram: "",
      instagram: "",
      informBy: ""
    }
  })

  const [stepForm, setStepForm] = useState(1)
  const [endStep, setEndStep] = useState(false)
  const [typeForm, setTypeForm] = useState('parent')

  const handleChange = (event) => {
    setState(prevValue => ({
      ...prevValue,
      [event.target.name]: event.target.value
    }))
  }

  const setParent = (data) => {
    setState(prevValue => ({
      ...prevValue,
      parent: {
        ...prevValue.parent,
        ...data
      }
    }))
  }

  const setStudent = (data) => {
    setState(prevValue => ({
      ...prevValue,
      student: {
        ...prevValue.student,
        ...data
      }
    }))
  }

  return {
    parent: state.parent,
    student: state.student,
    stepForm,
    endStep,
    typeForm,

    eventListener: {
      setStepForm,
      setEndStep,
      handleChange,
      setParent,
      setStudent,
      setState,
      setTypeForm,
      getAllState: state
    }
  }
}

export const ProviderSignupData = ({ children }) => {
  const state = SignUpProvider();

  return (
    <signUpContext.Provider value={state}>
      {children}
    </signUpContext.Provider>
  )
}

export const useSignUp = () => useContext(signUpContext);