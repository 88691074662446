import { Fragment } from 'react'
import { ContentDashboard } from '../../../layouts/Dashboard'
import { AddStudentForm } from '../../../components/Form/parent'
import { NavDashboard } from '../../../components/Navbar'
import { useCookies } from '../../../hooks/useCookies'

function AddStudent() {
  const { getCookie } = useCookies()
  const token = getCookie('token')

  return (
    <Fragment>
      <NavDashboard title="Tambah Murid" />
      <ContentDashboard>
        <AddStudentForm token={token}/>
      </ContentDashboard>
    </Fragment>
  )
}

export default AddStudent