import { Fragment, useEffect, useState } from "react";
import { NavDashboard } from "../../../components/Navbar";
import { MainDashboard } from "../../../layouts/Dashboard";
import {
  getAllLesson,
  getAllModul,
  getExamDetail,
  updateExam,
} from "../../../service/admin/adminService";
import { useCookies } from "../../../hooks/useCookies";
import { useQuery } from "../../../hooks/useQuery";
import { EditExamForm } from "../../../components/Form/admin/exam";

function EditExam() {
  const { getCookie } = useCookies();
  const token = getCookie("token");

  const [lesson, setLesson] = useState([]);
  const [modul, setModul] = useState([]);
  const [detailExam, setDetailExam] = useState([]);
  const id = useQuery("id");

  useEffect(() => {
    const fetchLesson = async () => {
      const lesson = await getAllLesson(token);
      const modul = await getAllModul(token);
      const detailExam = await getExamDetail(id, token);

      setLesson(lesson.data);
      setModul(modul.data);
      setDetailExam(detailExam.data);
    };
    fetchLesson();
  }, [token, id]);

  if (detailExam.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Ujian" />
        <div className="mt-6">
          <EditExamForm
            token={token}
            lesson={lesson}
            modul={modul}
            editExam={updateExam}
            detailExam={detailExam}
            id={id}
          />
        </div>
      </MainDashboard>
    </Fragment>
  );
}

export default EditExam;
