import Close from '../Close'

export function TitleForm({ children, iconClose, hrefClose, linkEditPassword, hrefLinkEditPassword = false }) {
  return (
    <div className='flex justify-between items-center'>
      <h1 className='md:text-xl text-md font-medium'>{children}</h1>
      <div className='flex items-center'>
        {linkEditPassword && <span className='md:text-sm text-xs txt-blue cursor-pointer mr-4' onClick={() => hrefLinkEditPassword('edit-password')}>Edit Password</span>}
        {iconClose && <Close to={`${hrefClose}`} />}
      </div>
    </div>
  )
}