import { Suspense } from "react";

function Loading({ children }) {
  return (
    <Suspense fallback={<Circle />}>
      {children}
    </Suspense>
  )
}

function Circle() {
  return (
    <div className='w-full flex-col h-screen flex justify-center items-center overflow-y-hidden'>
      <h1 className='font-normal'>Loading...</h1>
      <div className='flex mt-2'>
        <div className='w-6 h-8 bg-blue-600 animate-bounce'></div>
        <div className='w-6 h-8 bg-blue-400 animate-bounce mx-2'></div>
        <div className='w-6 h-8 bg-blue-200 animate-bounce'></div>
      </div>
    </div>
  )
}

export default Loading