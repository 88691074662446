import { Fragment } from "react"
import { AddAdminForm } from "../../components/Form/admin"
import { NavDashboard } from "../../components/Navbar"
import { ContentDashboard, MainDashboard } from "../../layouts/Dashboard"
import { useCookies } from '../../hooks/useCookies'

function AddAdmin() {
  const { getCookie } = useCookies()
  const token = getCookie('token')

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Admin" />
        <ContentDashboard>
          <AddAdminForm token={token}/>
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  )
}

export default AddAdmin