/* eslint-disable no-restricted-globals */
import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AddPromoForm } from '../../../components/Form/admin/promo'
import { NavDashboard } from '../../../components/Navbar'
import { useCookies } from '../../../hooks/useCookies'
import { getAllPromo, deletePromo } from '../../../service/admin/adminService'

function PromoManage() {
  const [showMenu, setShowMenu] = useState('data-promo')
  const [promoData, setPromoData] = useState([])
  const [hasDeletedPromo, setHasDeletedPromo] = useState(false)
  const [hasAddedPromo, setHasAddedPromo] = useState(false)
 
  const { getCookie } = useCookies()
  const token = getCookie('token')

  useEffect(() => {
    const fetchAllPromo = async () => {
      const result = await getAllPromo(token)
      setPromoData(result.data)
      setHasDeletedPromo(false)
      setHasAddedPromo(false)
    }

    fetchAllPromo()
  }, [token, hasDeletedPromo, hasAddedPromo])

  console.log(promoData)

  return (
    <Fragment>
      <div className='relative h-screen w-full md:p-6 p-4 bg-gray overflow-hidden'>
        <NavDashboard title="Kelola Promo" />
        <div className='md:hidden flex mt-6 py-1 text-xs'>
          <button onClick={() => setShowMenu('data-promo')} className={`${showMenu === 'data-promo' ? 'bg-blue text-white' : 'bg-white'} p-4 rounded-xl mr-4`}>Data Promo</button>
          <button onClick={() => setShowMenu('add-promo')} className={`${showMenu === 'add-promo' ? 'bg-blue text-white' : 'bg-white'} mr-6 p-4 rounded-xl`}>Masukan Promo</button>
        </div>
        <div className='overflow-hidden flex md:flex-row flex-col absolute md:bottom-6 bottom-4 md:left-6 left-4 md:right-6 right-4 lg:top-20 md:top-36 top-52'>
          {showMenu === "data-promo" && (
            <div className='p-6 bg-white rounded-xl md:mr-6 md:w-2/6 pb-24 md:pb-16 h-full'>
              <h1 className='text-xl font-medium'>Data Promo</h1>
              <span className='text-sm'>{promoData.length} Data Promo</span>
              <div className='grid md:grid-cols-1 sm:grid-cols-2 md:gap-0 gap-6 md:mt-0 h-full mt-6 overflow-y-auto'>
                {promoData.map((promo, id) => {
                  return <CardPromo setHasDeletedPromo={setHasDeletedPromo} token={token} key={id} promo={promo}/>
                })}
              </div>
            </div>
          )}
          {(showMenu === "add-promo" || document.body.clientWidth >= 768) && (
            <div className='overflow-y-scroll w-full h-full'>
              <AddPromoForm setHasAddedPromo={setHasAddedPromo} token={token} />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

function CardPromo({ promo, token, setHasDeletedPromo }) {

  const handleDeletePromo = async (idPromo) => {
    const confirmation = confirm("Yakin ingin menghapus promo ini?")
    if(confirmation) {
      const result = await deletePromo(idPromo, token)
      console.log(result)
      return setHasDeletedPromo(true)
    }
  }

  return (
    <div className='rounded-2xl p-5 bg-blue-100 md:mt-6 h-52'>
      <div className='flex text-xs items-center justify-between'>
        <span className='text-gray'>{promo.id}</span>
        <div className='transform scale-75 cursor-pointer' onClick={() => handleDeletePromo(promo.id)}>
          <img src="/assets/icons/btn-trash.png" alt="delete" />
        </div>
      </div>
      <div className='flex flex-col mt-2 pb-4 border-b-2 border-blue-400 mb-3'>
        <h1 className='text-xl font-medium break-words'>{promo.name}</h1>
        <span className='text-sm'>Diskon {promo.cutoff}</span>
      </div>
      <Link to={`/admin/promo/edit?id=${promo.id}`}>
        <div className='text-xs items-center relative flex cursor-pointer'>
          <span>{promo.startdate.split("T")[0]} - {promo.enddate.split("T")[0]}</span>
          <div className='w-6 transform -rotate-90 absolute right-0 cursor-pointer'>
            <img src="/assets/icons/arrow.png" alt="arrow" />
          </div>
        </div>
      </Link>
    </div>
  )
}

export default PromoManage