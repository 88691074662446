import { Section, Title } from '../Section';

function Contact({text, text2}) {

    const handleClickButtonContact = () => {
        window.open('https://wa.me/message/HOPZWXM2YM3GN1', '_blank')
    }

    return (
        <Section className='flex flex-col items-center bg-blue text-white rounded-2xl text-center relative' id='kontak'>
            <Title>
                <span className='md:text-5xl text-3xl'>
                   {text || 'Apa yang Ingin Anda Tanyakan?'} 
                </span>
            </Title>
            <button onClick={handleClickButtonContact} className='bg-white rounded-xl text-black py-4 px-6 flex items-center mt-4 txt-black md:text-2xl text-sm'>
                <div className='mr-4 transform scale-125 animate-pulse'>
                    <img src="/assets/icons/whatsapp.png" alt="Whatsapp" />
                </div>
                    {text2 || 'Hubungi Kami'}
                </button>
            <div className='absolute bottom-0 right-0 md:w-auto w-1/3'>
                <img src="/assets/icons/line_contact.png" alt="line" />
            </div>
        </Section>
    )
}

export default Contact