import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { resetPassword } from "../service/common";

function ResetPassword() {
  const {
    params: { email, token },
  } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (email && token) {
      const reset = async () => {
        const result = await resetPassword(email, token);
        if (!result.errors) {
          alert(result.message);
          return history.push("/login");
        }
      };

      reset();
    }
  }, [email, token, history]);

  return <div>Reseting...</div>;
}

export default ResetPassword;
