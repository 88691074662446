import { Fragment, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { NavDashboard } from '../../../components/Navbar'
import { useQuery } from '../../../hooks/useQuery'
import { useEditType } from '../../../hooks/useEditType'
import { ContentDashboard } from '../../../layouts/Dashboard'
import { useCookies } from '../../../hooks/useCookies'
import { getProfile } from '../../../service/profile/profileService'
import { EditStudentForm, EditPasswordStudentForm } from '../../../components/Form/student'

function EditStudent() {
  const studentID = useQuery("id")
  const { type, changeEditTypeTo } = useEditType()
  const { getCookie } = useCookies()
  const token = getCookie('token')
  const [profile, setProfile] = useState([])

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getProfile(token)
      if (profile.status === "Success") {
        return setProfile(profile.data)
      }

      console.log(profile) // check error
    }

    fetchProfile()

    return () => setProfile({})
  }, [token])

  console.log(profile)

  if (!studentID) {
    return <Redirect to='/parent/students' />
  }

  return (
    <Fragment>
      <NavDashboard title="Murid" />
      <ContentDashboard>
        {type === "profil" ? <EditStudentForm admin token={token} changeEditTypeTo={changeEditTypeTo} /> : <EditPasswordStudentForm />}
      </ContentDashboard>
    </Fragment>
  )
}

export default EditStudent