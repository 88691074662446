/* eslint-disable no-undef */
import { Fragment, useRef, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { usePaymentData } from '../../hooks/usePaymentData'
import { PopupChoosePackage } from '../Popup'

function Card({ children, className = "" }) {
  return (
    <div className={`bg-white rounded-3xl w-auto ${className}`}>
      {children}
    </div>
  )
}

export function CardGuru({ name, tutor, nameImage, type = 'png' }) { // card at landing page
  return (
    <Card className='p-6 mr-12 shadow-lg h-full'>
      {/* <div className='w-full h-60 rounded-3xl bg-cover lg:bg-center bg-no-repeat' style={{ backgroundImage: `url("/assets/teacher_img/${nameImage}.${type}")` }}> </div> */}
      <img className='w-full h-60 object-cover rounded-3xl' src={`/assets/teacher_img/${nameImage}.${type}`} alt={`${nameImage}`}/>
      <div className='mt-6'>
        <h3 className='font-semibold md:text-lg text-md'>{name}</h3>
        <span className='mt-3 mb-6 block txt-black md:text-base text-sm'>{tutor}</span>
      </div>
    </Card>
  )
}

export function CardTestiMoni({ className, name, school, text, imgName, typeExt = "jpg" }) { // card at landing page
  return (
    <Card className={`${className} h-96 p-6 shadow-lg mr-12`}>
      <div className='relative'>
        <img className='w-20 h-20 rounded-full' src={`/assets/images/${imgName}.${typeExt}`} alt={`${imgName}`} />
        <div className='absolute right-0 top-0'>
          <img loading='lazy' src="/assets/icons/kutip.png" alt="kutip" />
        </div>
      </div>
      <div className='mt-8'>
        <h3 className='font-semibold text-lg'>{name}</h3>
        <span className='text-sm'>Murid {school}</span>
        <p className='mt-4 md:text-base text-sm h-28 z-10 overflow-auto custom-scroll'>{text} </p>
        <div className='mt-4'>
          <img src="/assets/icons/stars_5.png" alt="star" />
        </div>
      </div>
    </Card>
  )
}

export function CardProgram({ icon }) { // card program landing page
  return (
    <Card className='md:p-12 p-6 shadow-lg'>
      <div className='md:w-auto w-20'>
        <img loading='lazy' src={`/assets/icons/${icon}.png`} alt="pencil" />
      </div>
      <div className='mt-8'>
        <h4 className='md:text-4xl text-xl'>PRIVAT SD 123</h4>
        <h3 className='md:text-3xl text-2xl my-4 font-semibold txt-blue'>Aplikasi 150k/tahun</h3>
        <ul className='mt-4 txt-black md:text-base text-sm'>
          <li className='flex flex-col relative pl-4'>
            SD 123 online Rp 400k/bulan
                      <span>
              (8 Pertemuan @ 60 menit)
                      </span>
            <div className='absolute -left-0 top-2 w-2 h-2 rounded border border-black'></div>
          </li>
          <li className='flex flex-col mt-2 relative pl-4'>
            SD 123 offline Rp 480k/bulan
                      <span>
              (8 Pertemuan @60 menit belum termasuk biaya transportasi)
                      </span>
            <div className='absolute -left-0 top-2 w-2 h-2 rounded border border-black'></div>
          </li>
        </ul>
      </div>
      <div className='flex flex-col mt-4'>
        <span className='block md:text-xl text-md txt-black'>Pendaftaran</span>
        <span className='block md:text-3xl text-2xl font-bold txt-dark'>100k</span>
      </div>
    </Card>
  )
}

export function CardPackage({ classtype, meet, time, type, id, price, per, grades, setStep }) { // cart parent side page murid after click pilih paket
  const [lockPackage, setLockPackage] = useState(false)
  const packageCardRef = useRef()
  const params = useParams()
  const history = useHistory()

  const filterGrades = grades.map(grade => grade.grade).sort((a, b) => a - b)

  const { eventListener: { addPackageID, removePackageID, addStudentID } } = usePaymentData()

  const handleClickCart = () => {
    const packageID = packageCardRef.current.dataset.packageid

    if (lockPackage) {
      removePackageID(packageID)
      return setLockPackage(false)
    }

    addPackageID(packageID)
    setLockPackage(true)
  }

  const handleClickBuyNow = () => {
    // eslint-disable-next-line no-restricted-globals
    const prompt = confirm("Yakin ingin bayar Package ini?")

    if (prompt) {
      const packageID = packageCardRef.current.dataset.packageid

      addStudentID(params.id)

      if (lockPackage) {
        history.push('/parent/payment')
        return setStep(2)
      }

      addPackageID(packageID)
      setStep(2)
      history.push('/parent/payment')
    }

  }

  return (
    <div ref={packageCardRef} data-packageid={id} className='flex flex-col shadow-gray p-6 rounded-xl items-center'>
      <span className='text-sm txt-blue'>{type.type} Kelas - {filterGrades.join("/")}</span>
      <div className='flex flex-col items-center mt-6'>
        <span>{classtype}</span>
        <span className='block text-xl mt-2'><span className='text-2xl font-semibold'>Rp{price.toLocaleString()}/</span>{per}</span>
      </div>
      <span className='text-xs mt-1'>{meet} pertemuan @ {time}</span>
      <div className='mt-8 flex items-center'>
        <button className='bg-lightblue-3 txt-blue text-sm py-2 px-6 rounded-md' onClick={handleClickBuyNow}>Beli Sekarang</button>
        <button className={`${lockPackage ? 'bg-blue' : 'bg-lightblue-3'} hidden w-8 h-8 ml-4 rounded-md flex items-center justify-center cursor-pointer`} onClick={handleClickCart}>
          <img className='transform scale-75' src={`/assets/icons/${lockPackage ? 'cart_white' : 'cart_blue'}.png`} alt="cart" />
        </button>
      </div>
    </div>
  )
}

export function CardPaymentPackage({ classtype, type, price, per, grades, setPayment, payment, id }) { // cart parent side page murid payment step 2 after click bayar
  const filterGrades = grades.map(grade => grade.grade).sort((a, b) => a - b)

  // const handleClickTrash = (packageID) => {
  //   setPayment(payment.filter(data => data.id !== packageID))
  // }

  return (
    <div className='flex flex-col shadow-gray p-6 rounded-xl md:items-center relative'>
      <span className='md:text-sm text-md txt-blue'>{type.type}</span>
      <div className='flex flex-col md:items-center md:mt-6 mt-3'>
        <span className='md:text-base text-sm'>{classtype}</span>
        <span className='block text-xl md:mt-2'><span className='text-2xl font-semibold'>{price}/</span>{per}</span>
      </div>
      <span className='text-xs mt-1'>Kelas {filterGrades.join('/')}</span>
      <div className='md:mt-8 flex items-center md:relative md:right-0 md:top-0 absolute right-6 top-6'>
        {/* <div className='bg-lightblue-3 w-8 h-8 ml-4 rounded-md flex items-center justify-center cursor-pointer' onClick={() => handleClickTrash(id)}>
          <img className='transform scale-75' src="/assets/icons/trash-2.png" alt="cart" />
        </div> */}
      </div>
    </div>
  )
}

export function CardPaymentMethod({ type, active, number }) {
  const cardBankRef = useRef()
  const [dataset, setDataset] = useState("")

  useEffect(() => {
    setDataset(cardBankRef.current.dataset.bank)
  }, [active])

  return (
    <Fragment>
      <div data-bank={type} ref={cardBankRef} className={`${active === dataset ? 'bg-lightblue-2' : ''} flex flex-col md:p-4 p-2 border-gray rounded-xl justify-center items-center cursor-pointer md:mr-6`}>
        <div className='md:w-32 h-12 flex items-center'>
          <img src={`/assets/icons/${type}.png`} alt={type} />
        </div>
        <span className='md:mt-4 mt-2 md:text-sm text-xs'>
          {number}
        </span>
      </div>
    </Fragment>
  )
}

export function CardStudent({ name, kelas, imageSrc, studentID }) {
  const buttonRef = useRef()
  const [openModal, setOpenModal] = useState(false)

  const handleMoveCard = () => {
    if (window.innerWidth >= 768) {
      buttonRef.current.classList.remove('border-black')
    }
    buttonRef.current.classList.add('border-white')
    buttonRef.current.children[0].src = '/assets/icons/arrow-right.png'
  }

  const handlLeaveCard = () => {
    if (window.innerWidth >= 768) {
      buttonRef.current.classList.add('border-black')
    }
    buttonRef.current.classList.remove('border-white')
    buttonRef.current.children[0].src = '/assets/icons/arrow-right-black.png'
  }

  const handleClickCard = () => {
    setOpenModal(true)
  }

  return (
    <Fragment>
      <PopupChoosePackage open={openModal} studentID={studentID} setOpen={setOpenModal} />
      <div onClick={handleClickCard} onMouseMove={handleMoveCard} onMouseLeave={handlLeaveCard} className='card shadow-gray flex md:p-0 p-6 md:h-64 h-max md:flex-col justify-evenly items-center rounded-xl hover:text-white cursor-pointer'>
        <div className='w-16 h-16 bg-cover bg-no-repeat bg-blue-200 rounded-full' style={{ backgroundImage: `url('https://api.starbimbel.com/foto/student/${imageSrc}')` }} />
        <div className='w-1/2 flex flex-col md:items-center justify-center font-normal'>
          <span className='block font-semibold md:text-base text-sm'>{name}</span>
          {/* <span className='block md:text-base text-xs'>Kelas {kelas}</span> */}
        </div>
        <div ref={buttonRef} className={`p-1 rounded-xl md:border md:border-black flex justify-center cursor-pointer`}>
          <img className='relative left-0.5' src='/assets/icons/arrow-right-black.png' alt="arrow" />
        </div>
      </div>
    </Fragment>
  )
}

export default Card