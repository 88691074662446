import { Fragment, useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import TextRed from "../../components/ErrorField"
import { HeaderSignUp } from "../../components/Header"
import { Input, InputGroup } from "../../components/Input"
import { CONSTANT } from "../../constant"
import { useCookies } from "../../hooks/useCookies"
import { signup } from "../../service/teacher/teacherService"
import { loadImage } from "../../utils/common/loadImage"
import { REGEX } from "../../utils/regex"

function Signup() {
  // const profileImageRef = useRef()
  //  const fileImageRef = useRef()
  // const [hasUploadImage, setHasUploadImage] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm()
  // const [image, setImage] = useState('')
  const [imageKTP, setImageKTP] = useState('')
  const [imageCV, setImageCV] = useState('')
  const { getCookie } = useCookies()
  const token = getCookie("token")
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState({})

  // const handleChangeImage = (image) => {
  //   if (image.target.files.length === 0) {
  //     return
  //   }

  //   loadImage(image, file => {
  //     profileImageRef.current.src = file.name
  //     setImage(file.name)
  //     setHasUploadImage(true)
  //   })
  // }

  const handleChangeImageKTP = (image) => {
    loadImage(image, file => {
      setImageKTP(file.name)
    })
  }

  const handleChangeImageCV = (image) => {
    loadImage(image, file => {
      setImageCV(file.name)
    })
  }

  const onSubmit = async (data) => {
    // data.image = image
    data.idCard = imageKTP
    data.cv = imageCV

    if(!imageKTP || !imageCV) {
      return alert("Foto KTP/CV Wajib diupload")
    }

    const result = await signup(data, token)
    if(result.status === "Success") {
      alert("Pendaftaran Berhasil")
      return history.push('/teacher')
    }

    setErrorMessage(result.errors)
    // if (!image) {
    //   return alert("Foto wajib diisi")
    // }
  }

  return (
    <Fragment>
      <HeaderSignUp />
      <span className='md:mx-24 mx-6 md:my-8 block border-gray'></span>
      <div className='md:p-24 m-6 md:mb-32 mb-32 relative'>
        <div className='border-gray md:p-12 p-6 rounded-md'>
          <form className='mt-6 md:px-4' onSubmit={handleSubmit(onSubmit)}>
            {/* <div className='flex items-center mb-6'>
              <div className='md:w-24 w-20 mr-4 md:h-24 h-20 bg-gray-300 flex justify-center rounded-full overflow-hidden'>
                <img ref={profileImageRef} className={`h-full w-full transform ${hasUploadImage ? 'scale-100' : 'scale-50'}`} src='/assets/icons/camera.png' alt="profile" />
              </div>
              <label ref={fileImageRef} className='bg-blue md:text-base text-xs p-3 text-white rounded-xl cursor-pointer' htmlFor='file'>Upload Foto Baru</label>
              <input {...register("image")} onChange={handleChangeImage} type="file" id='file' className='hidden' accept="image/png, image/jpeg" />
            </div> */}

            <InputGroup>
              <label className='mb-2 text-sm' htmlFor="name">Nama Lengkap*</label>
              <Input register={register("name", { required: true })} id="name" type="text" placeholder="Nama Lengkap" showIcon iconName="name-people" />
              {(errors.fullName && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.fullName && <TextRed>{errorMessage.fullName.message}</TextRed>)}
            </InputGroup>

            <div className='md:flex justify-between'>
              <InputGroup className='md:w-6/12 md:pr-4'>
                <label className='mb-2 text-sm' htmlFor="email">Email*</label>
                <Input register={register("email", { required: true, pattern: REGEX.email })} id="email" />
                {(errors.email && <TextRed>{CONSTANT.INVALID_EMAIL}</TextRed>) || (errorMessage.email && <TextRed>{errorMessage.email.message}</TextRed>)}
              </InputGroup>
              <InputGroup className='md:pl-4 md:w-6/12 '>
                <label className='mb-2 text-sm' htmlFor="password">Password*</label>
                <Input register={register("password", { required: true, pattern: REGEX.password })} id="password" type='password' className='w-full' />
                {(errors.password && <TextRed>{CONSTANT.INVALID_PASSWORD}</TextRed>) || (errorMessage.password && <TextRed>{errorMessage.password.message}</TextRed>)}
              </InputGroup>
            </div>

            <div className='md:flex justify-between'>
              <InputGroup className='md:w-6/12 md:pr-4'>
                <label className='mb-2 text-sm' htmlFor="maritalStatus">Status Pernikahan*</label>
                <select className="border-gray rounded-xl p-3 outline-none" {...register("maritalStatus")}>
                  <option defaultValue="belum kawin">Belum Kawin</option>
                  <option defaultValue="kawin">Kawin</option>
                  <option value="cerai hidup">Cerai Hidup</option>
                  <option value="cerai mati">Cerai Mati</option>
                </select>
                {errors.maritalStatus && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
              <InputGroup className='md:pl-4 md:w-6/12 '>
                <label className='mb-2 text-sm' htmlFor="birthdate">Tanggal Lahir*</label>
                <Input register={register("birthdate", { required: true })} id="birthdate" type='date' className='w-full' />
                {errors.birthdate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
            </div>

            <div className='md:flex justify-between'>
              <InputGroup className='md:w-6/12 md:pr-4'>
                <label className='mb-2 text-sm' htmlFor="lastEducation">Pendidikan Terkahir*</label>
                <Input register={register("lastEducation", { required: true })} id="lastEducation" />
                {(errors.lastEducation && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.lastEducation && <TextRed>{errorMessage.lastEducation.message}</TextRed>)}
              </InputGroup>
              <InputGroup className='md:pl-4 md:w-6/12 '>
                <label className='mb-2 text-sm' htmlFor="graduateYear">Tahun Kelulusan*</label>
                <Input register={register("graduateYear", { required: true })} id="graduateYear" className='w-full' />
                {(errors.graduateYear && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.graduateYear && <TextRed>{errorMessage.graduateYear.message}</TextRed>)}
              </InputGroup>
            </div>

            <div className='md:flex justify-between'>
              <InputGroup className='md:w-6/12 md:pr-4'>
                <label className='mb-2 text-sm' htmlFor="major">Jurusan*</label>
                <Input register={register("major", { required: true })} id="major" />
                {(errors.major && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.major && <TextRed>{errorMessage.major.message}</TextRed>)}
              </InputGroup>
              <InputGroup className='md:pl-4 md:w-6/12 '>
                <label className='mb-2 text-sm' htmlFor="currentActivities">Aktifitas Saat Ini*</label>
                <Input register={register("currentActivities", { required: true })} id="currentActivities" className='w-full' />
                {(errors.currentActivities && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.currentActivities && <TextRed>{errorMessage.currentActivities.message}</TextRed>)}
              </InputGroup>
            </div>

            <div className='md:flex justify-between'>
              <InputGroup className='md:w-6/12 md:pr-4'>
                <label className='mb-2 text-sm' htmlFor="whatsapp">Nomor Whatsapp*</label>
                <Input register={register("whatsapp", { required: true })} id="whatsapp" />
                {(errors.email && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.whatsapp && <TextRed>{errorMessage.whatsapp.message}</TextRed>)}
              </InputGroup>
              <InputGroup className='md:w-6/12 md:pl-4'>
                <label className='mb-2 text-sm' htmlFor="phone">Nomor Telepon*</label>
                <Input register={register("phone", { required: true })} id="phone" type="text" />
                {(errors.phone && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.phone && <TextRed>{errorMessage.phone.message}</TextRed>)}
              </InputGroup>
            </div>

            <InputGroup>
              <label className='mb-2 text-sm' htmlFor="address">Alamat Lengkap*</label>
              <textarea {...register("address", { required: true })} className='p-4 border-gray outline-none rounded-md' />
              {(errors.address && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.address && <TextRed>{errorMessage.address.message}</TextRed>)}
            </InputGroup>

            <InputGroup>
              <label className='mb-2 text-sm' htmlFor="residence">Alamat Domisili*</label>
              <textarea {...register("residence", { required: true })} className='p-4 border-gray outline-none rounded-md' />
              {(errors.residence && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.residence && <TextRed>{errorMessage.residence.message}</TextRed>)}
            </InputGroup>

            <div className='md:flex justify-between'>
              <div className='mt-6'>
                <span className='text-sm font-semibold'>Upload CV</span>
                <div className='flex flex-col'>
                  <div className='w-full h-52 rounded-xl border-4 p-4 border-dashed mt-4 flex flex-col items-center bg-lightblue-4 p-4'>
                    <div className='mt-6'>
                      <img src="/assets/icons/i-upload.png" alt="upload" />
                    </div>
                    <span className='text-sm mt-3 text-center'>{' '}
                      <label className='txt-blue cursor-pointer' htmlFor="cv">Browse</label>
                      <input {...register('cv')} onChange={handleChangeImageCV} required className='w-full m-auto' type='file' id='cv' accept="file_extension" />
                    </span>
                    <span className='text-sm txt-gray mt-2'>Supports Files PDF/JPG/PNG - Max. File Size 5 MB </span>
                  </div>
                </div>
              </div>
              <div className='mt-6'>
                <span className='text-sm font-semibold'>Upload KTP</span>
                <div className='flex flex-col'>
                  <div className='w-full h-52 rounded-xl border-4 p-4 border-dashed mt-4 flex flex-col items-center bg-lightblue-4 p-4'>
                    <div className='mt-6'>
                      <img src="/assets/icons/i-upload.png" alt="upload" />
                    </div>
                    <span className='text-sm mt-3 text-center'>{' '}
                      <label className='txt-blue cursor-pointer' htmlFor="idCard">Browse</label>
                      <input {...register('idCard')} onChange={handleChangeImageKTP} required className='w-full m-auto' type='file' id='idCard' accept="image/*" />
                    </span>
                    <span className='text-sm txt-gray mt-2'>Supports File JPG - Max. File Size 5 MB </span>
                  </div>
                </div>
              </div>
            </div>

            <div className='w-full flex justify-end'>
              <button className='mt-8 bg-blue py-4 px-8 text-white rounded-xl md:text-sm text-xs'>Simpan</button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default Signup