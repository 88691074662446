import { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { NavDashboard } from "../../components/Navbar";
import { useCookies } from "../../hooks/useCookies";
import { useQuery } from "../../hooks/useQuery";
import {
  getAllSchedule,
  getDetailTeacher,
} from "../../service/teacher/teacherService";
import { io } from "socket.io-client";
import { parseJwt } from "../../utils/common/parseJWT";

const socket = io(`${process.env.REACT_APP_SOCKET_URL}`);

function Group() {
  const [showChat, setShowChat] = useState(false);
  const [listGroup, setListGroup] = useState([]);
  const [profile, setProfile] = useState([]);
  const [listChat, setListChat] = useState([]);

  const { getCookie } = useCookies();
  const token = getCookie("token");

  const history = useHistory();
  const id = useQuery("id");

  const decodeToken = parseJwt(token);
  const { id: idTeacher, role } = decodeToken.user;

  const [lastMessage, setLastMessage] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAllSchedule(token);
      const profile = await getDetailTeacher(token);
      setListGroup(result.data);
      setProfile(profile.data);
    };

    fetchData();

    return () => setListGroup([]);
  }, [token]);

  useEffect(() => {
    socket.on("joined", (response) => {
      console.log(response);
    });

    socket.on("chats", (listChat) => {
      setListChat(
        listChat?.map((list) =>
          typeof list.idRoom === "number"
            ? { ...list, idRoom: list.idRoom.toString() }
            : list
        )
      );
    });

    socket.on("received-message", (data) => {
      setLastMessage(data);
    });
  }, []);

  const handleClickCardGroup = (idRoom) => {
    if (idRoom === id) return;

    history.push(`/teacher/group?id=${idRoom}`);
    setShowChat(true);
    joinRoom(idRoom);
  };

  const joinRoom = (id) => {
    socket.emit("join", { token: token, idRoom: id }, (err) => {
      if (err) {
        console.log(err);
      }
    });
  };

  return (
    <Fragment>
      <div className="relative w-full h-screen  bg-gray">
        <div className="absolute inset-4 md:inset-7 z-10 h-12">
          <NavDashboard title="Group" />
        </div>
        <div className="mt-6 md:p-6 p-4 lg:pt-24 md:pt-36 pt-36 bottom-0 h-full absolute w-full flex md:flex-row flex-col">
          {(!id || document.body.clientWidth >= 765) && (
            <div className="h-full md:w-2/6 bg-white rounded-xl overflow-hidden">
              <div className="flex justify-between mb-6 px-4 md:px-6 md:pt-6 pt-6 items-center">
                <div className="w-full border flex items-center bg-white py-2 px-4 rounded-xl">
                  <div className="cursor-pointer">
                    <img src="/assets/icons/search.png" alt="search-img" />
                  </div>
                  <div className="ml-4 w-full">
                    <input
                      className="outline-none text-sm w-full"
                      type="search"
                      placeholder="Search"
                    />
                  </div>
                </div>
                {/* <div className="p-2 cursor-pointer bg-blue rounded-md">
                  <img src="/assets/icons/plus.png" alt="add" />
                </div> */}
              </div>

              <div className="overflow-y-auto flex flex-col h-full pb-32">
                {listGroup.map((list) => {
                  return (
                    <CardGroup
                      data={list}
                      key={list.id}
                      onClick={() => handleClickCardGroup(list.id.toString())}
                      lastMessage={lastMessage}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {(document.body.clientWidth >= 765 || (showChat && id)) && (
            <Chat
              profile={profile}
              idRoom={id}
              idTeacher={idTeacher}
              group={listGroup}
              listChat={listChat}
              role={role}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}

function CardGroup({ onClick, data }) {
  // const [lastMessage, setLastMessage] = useState({})

  useEffect(() => {
    socket.on("received-message", (message) => {
      // setLastMessage(message)
    });
  }, []);

  return (
    <div
      onClick={onClick}
      className="flex items-center py-3 border-b hover:bg-blue-100 px-6"
    >
      <div className="rounded-full">
        <img
          className="w-18 h-18"
          src="/assets/avatar/default.png"
          alt="avatar"
        />
      </div>
      <div className="flex flex-col ml-3">
        <span className="md:text-md text-sm font-medium text-left">
          {data.title} - {data.lesson.name}
        </span>
        <span className="text-xs txt-gray">
          {/* {data.id === Number(lastMessage.idRoom) ? lastMessage.message ?? "" : ""} */}
        </span>
      </div>
      {/* <div className="flex flex-col text-xs justify-evenly h-full mr-3">
        <span className="txt-gray">2m</span>
        <span className="bg-blue text-center rounded-sm p-1 text-white">2</span>
      </div> */}
    </div>
  );
}

function Chat({ profile, idRoom, idTeacher, group, listChat, role }) {
  const [clickedAttach, setClickedAttach] = useState(false);
  const [typing, setTyping] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const scrollChatRef = useRef();

  const [chat, setChat] = useState([]);
  const filteredGroup = group.find((group) => group.id === Number(idRoom));

  useEffect(() => {
    setChat(listChat);
  }, [listChat]);

  const uniqueMessage = new Map();
  chat.map((ch) => uniqueMessage.set(ch.date, ch));

  const handleClickAttach = () => {
    if (clickedAttach) {
      return setClickedAttach(false);
    }

    setClickedAttach(true);
  };

  useEffect(() => {
    socket.on("typing", (name) => {
      setTyping(`${name} sedang mengetik...`);
    });

    socket.on("received-message", (data) => {
      setChat((prev) => [...prev, data]);
    });

    socket.on("stop-typing", () => {
      setTyping("");
    });

    return () => {
      setTyping("");
      setChat([]);
    };
  }, []);

  useEffect(() => {
    if (scrollChatRef.current) {
      scrollChatRef?.current?.scrollTo(0, scrollChatRef?.current?.scrollHeight);
    }
  }, [chat]);

  const handleTyping = (e) => {
    socket.emit("typing", profile.name);
    setMessageValue(e.target.value);
  };

  const handleClickSend = () => {
    socket.emit("send-message", messageValue, new Date());
    setMessageValue("");
    socket.emit("stop-typing");
  };

  const handleEnter = (e) => {
    const {
      key,
      target: { value: message },
    } = e;
    if (key === "Enter") {
      socket.emit("send-message", message, new Date());
      setMessageValue("");
      socket.emit("stop-typing");

      if (scrollChatRef.current) {
        scrollChatRef?.current.scrollTo(
          0,
          scrollChatRef?.current?.scrollHeight
        );
      }
    }
  };

  const handleChangeFile = (event) => {
    const { files } = event.target;
    const { name } = files[0];
    console.log(files[0]);
    socket.emit("send-file", files[0], name, new Date());

    setClickedAttach(false);
  };

  return (
    <div className="rounded-xl bg-white md:w-3/5 h-full md:ml-6 relative md:block overflow-hidden">
      <div className="flex border-b pb-4 px-4 md:px-6 pt-6">
        <div className="mr-3">
          <img src="/assets/avatar/default.png" alt="avatar" />
        </div>
        <div className="flex flex-col">
          <span className="text-md">
            Group {filteredGroup?.title} - {filteredGroup?.lesson?.name}
          </span>
          {/* <div className="flex text-xs">
            <span>5 Anggota</span>
            <span>,</span>
            <span className="text-green-300">1 Online</span>
          </div> */}
        </div>
      </div>

      {/* Chat - Mine */}
      <div className="h-full overflow-y-auto pb-52" ref={scrollChatRef}>
        {[...uniqueMessage.values()]
          .filter((data) => data.idRoom === idRoom)
          ?.map((data, id) => {
            return (
              <Fragment key={id}>
                {data.idMember === idTeacher && data.role === "teacher" ? (
                  <div key={id} className="px-6 pt-6 flex flex-col items-end">
                    <div className="flex items-center md:w-1/2 w-4/5">
                      <div className="flex flex-col w-full">
                        <div className="flex items-start justify-between">
                          <span className="text-xs txt-gray">{`${new Date(
                            data.date
                          ).getHours()}:${new Date(
                            data.date
                          ).getMinutes()}`}</span>
                        </div>

                        <div className="break-words text-xs mt-1 bg-lightblue-2 text-black p-3 rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl">
                          <>
                            {data.type === "text" ? (
                              <span>{data.message}</span>
                            ) : (
                              <div className="">
                                {`${data.message}`?.endsWith("png") ||
                                `${data.message}`?.endsWith("jpg") ||
                                `${data.message}`?.endsWith("jpeg") ? (
                                  <div className="w-full h-36 mb-3">
                                    <img
                                      className="w-full h-full object-cover"
                                      src={`${process.env.REACT_APP_BASE_URL}/${data.message}`}
                                      alt={data.message}
                                    />
                                  </div>
                                ) : (
                                  <span className="block w-full mb-2">
                                    {data.message}
                                  </span>
                                )}
                                <a
                                  className="w-full h-8 p-3 bg-white flex justify-center items-center"
                                  download
                                  href={`${process.env.REACT_APP_BASE_URL}/${data.message}`}
                                  target="blank"
                                >
                                  Download
                                </a>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key={id} className="px-6 pt-6 flex flex-col items-start">
                    <div className="flex items-center md:w-1/2 w-4/5">
                      {data.image && (
                        <div className="w-16 h-16 rounded-full mr-3 flex jutify-center items-center">
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}/foto/student/${data.image}`}
                            alt="profil"
                          />
                        </div>
                      )}
                      <div className="flex flex-col w-full">
                        <div className="flex items-start justify-between">
                          <span className="text-sm">{data.name}</span>
                          <span className="text-xs txt-gray">{`${new Date(
                            data.date
                          ).getHours()}:${new Date(
                            data.date
                          ).getMinutes()}`}</span>
                        </div>

                        <div className="break-words text-xs mt-1 bg-blue text-white p-3 rounded-tr-2xl rounded-br-2xl rounded-bl-2xl">
                          <>
                            {data.type === "text" ? (
                              <span>{data.message}</span>
                            ) : (
                              <div className="">
                                {`${data.message}`?.endsWith("png") ||
                                `${data.message}`?.endsWith("jpg") ||
                                `${data.message}`?.endsWith("jpeg") ? (
                                  <div className="w-full h-36 mb-3">
                                    <img
                                      className="w-full h-full object-cover"
                                      src={`${process.env.REACT_APP_BASE_URL}/${data.message}`}
                                      alt={data.message}
                                    />
                                  </div>
                                ) : (
                                  <span className="block w-full mb-2">
                                    {data.message}
                                  </span>
                                )}
                                <a
                                  className="w-full h-8 p-3 bg-white text-black flex justify-center items-center"
                                  download
                                  href={`${process.env.REACT_APP_BASE_URL}/${data.message}`}
                                  target="blank"
                                >
                                  Download
                                </a>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
      </div>
      {/* --End -- Chat */}

      <div className="flex absolute justify-between bottom-6 px-6 w-full">
        <div className="w-11/12 relative mr-2">
          <span className="text-sm mb-2">{typing}</span>
          <input
            type="text"
            value={messageValue}
            className="w-full pr-12 border-gray text-sm rounded-xl p-3 outline-none"
            placeholder="Ketik pesan"
            onChange={handleTyping}
            onKeyDown={handleEnter}
          />
          <div
            className="absolute right-3 top-3 z-40 cursor-pointer"
            onClick={handleClickAttach}
          >
            <img src="/assets/icons/attach.png" alt="attach" />
          </div>
          {clickedAttach && (
            <div className="flex flex-col absolute right-2 -top-20">
              <label
                htmlFor="file"
                className="bg-orange p-2 rounded-md w-max cursor-pointer"
              >
                <img src="/assets/icons/type-file.png" alt="file" />
                <input
                  onChange={handleChangeFile}
                  type="file"
                  id="file"
                  className="hidden"
                />
              </label>
              <label
                htmlFor="image"
                className="bg-pink mt-2 p-2 rounded-md w-max cursor-pointer"
              >
                <img src="/assets/icons/type-img.png" alt="file" />
                <input
                  onChange={handleChangeFile}
                  type="file"
                  id="image"
                  className="hidden"
                />
              </label>
            </div>
          )}
        </div>
        <div
          className="p-2 cursor-pointer flex justify-center items-center bg-blue rounded-md"
          onClick={handleClickSend}
        >
          <img src="/assets/icons/send.png" alt="send" />
        </div>
      </div>
    </div>
  );
}

export default Group;
