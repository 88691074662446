import { usePageTitle } from "../../hooks/usePageTitle";
import { SidebarUser } from '../../components/Sidebar';
import ContainerDashboard from '../../layouts/Dashboard'
import { Switch, Redirect } from "react-router-dom";
import { RouteWithSubRoute } from '../../routes'
import { useCookies } from '../../hooks/useCookies'
import { checkRole } from '../../utils/common/checkRole'
import { checkCurrentRole } from '../../utils/common/checkCurrentRole'
import Login from "../Login";
import { useState, useEffect } from 'react'
import { getProfile } from "../../service/profile/profileService";

function Dashboard({ routes }) {
  usePageTitle('Dashboard - Star Bimbel')
  const { getCookie } = useCookies()
  const token = getCookie('token')
  const [profile, setProfile] = useState([])

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getProfile(token)
      if (profile.status === "Success") {
        return setProfile(profile.data)
      }

      console.log(profile) // check error
    }

    fetchProfile()

    return () => setProfile({})
  }, [token])

  if (!token) {
    return <Login />
  }

  if (!checkRole("student", token)) {
    const currentRole = checkCurrentRole(token)
    return <Redirect to={`/${currentRole}`} />
  }

  return (
    <ContainerDashboard>
      <SidebarUser {...profile}/>
      <Redirect to="/dashboard/modul" />
      <Switch>
        <RouteWithSubRoute routes={routes} />
      </Switch>
    </ContainerDashboard>
  )
}

export default Dashboard
