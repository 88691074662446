const adminListSidebar = [
  {
    name: 'Kelola Pembayaran',
    href: 'payment',
    iconName: 'btn-kelola-pembayaran',
    role: 'admin'
  },
  {
    name: 'Kelola Guru',
    href: 'teachers',
    iconName: 'btn-kelola-guru',
    role: 'admin'
  },
  {
    name: 'Kelola Kelas',
    href: 'class',
    iconName: 'btn-kelola-kelas',
    role: 'admin'
  },
  {
    name: 'Kelola Murid',
    href: 'students',
    iconName: 'btn-kelola-murid',
    role: 'admin'
  },
  {
    name: 'Kelola Paket',
    href: 'package',
    iconName: 'btn-kelola-paket',
    role: 'admin'
  },
  {
    name: 'Kelola Modul',
    href: 'modul',
    iconName: 'btn-modul',
    role: 'admin'
  },
  {
    name: 'Kelola Kuis',
    href: 'quiz',
    iconName: 'btn-quiz',
    role: 'admin'
  },
  {
    name: 'Kelola Ujian',
    href: 'exam',
    iconName: 'btn-ujian',
    role: 'admin'
  },
  {
    name: 'Kelola Promo',
    href: 'promo',
    iconName: 'btn-kelola-promo',
    role: 'admin'
  },
  {
    name: 'Kelola Admin',
    href: 'all',
    iconName: 'btn-admin',
    role: 'superadmin'
  },
  // {
  //   name: 'Hapus Data',
  //   href: 'delete',
  //   iconName: 'btn-trash',
  //   role: 'admin'
  // },
]

const studentListSidebar = [
  {
    name: 'Modul',
    href: 'modul',
    iconName: 'btn-modul'
  },
  {
    name: 'Kuis Online',
    href: 'quiz',
    iconName: 'btn-quiz'
  },
  {
    name: 'Ujian Online',
    href: 'exam',
    iconName: 'btn-ujian'
  },
  {
    name: 'Kelas Online',
    href: 'class',
    iconName: 'btn-kelas-online'
  },
  {
    name: 'Group',
    href: 'group',
    iconName: 'btn-group'
  },
]

const parentListSidebar = [
  {
    name: 'Kelola Murid',
    href: 'students',
    iconName: 'btn-kelola-murid'
  },
  {
    name: 'Kelola Pembayaran',
    href: 'payment',
    iconName: 'btn-kelola-pembayaran'
  },
]

const teacherListSidebar = [
  {
    name: 'Modul & Kelas',
    href: 'modul',
    iconName: 'btn-modul'
  },
  {
    name: 'Kuis Online',
    href: 'quiz',
    iconName: 'btn-quiz'
  },
  {
    name: 'Ujian Online',
    href: 'exam',
    iconName: 'btn-ujian'
  },
  {
    name: 'Kelas Online',
    href: 'online-class',
    iconName: 'btn-kelas-online'
  },
  {
    name: 'Group',
    href: 'group',
    iconName: 'btn-group'
  },
]

export {
  adminListSidebar,
  studentListSidebar,
  parentListSidebar,
  teacherListSidebar
}