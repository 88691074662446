import { Link } from 'react-router-dom'

function Container({ title, subTitle, children }) {

  return (
    <div className='flex h-screen w-full p-6 relative'>
      <div className='w-2/6 hidden lg:flex bg-blue overflow-hidden rounded-2xl relative'>
        <div className='p-8 text-white'>
          <h1 className='lg:text-2xl xl:text-3xl font-semibold h-max'>{title}</h1>
          <p className='mt-4 lg:text-sm xl:text-base'>{subTitle}</p>
        </div>
        <div className='absolute -bottom-10 -left-5 w-full transform lg:scale-125 xl:scale-90'>
          <img src="/assets/images/login.png" alt="login" />
          <div className='absolute top-0 transform scale-125 -z-10'>
            <img src="/assets/images/line_login.png" alt="line" />
          </div>
        </div>
      </div>

      <div className='lg:w-4/6 w-full flex justify-center items-center relative'>
        <div className='flex flex-col lg:w-2/5 sm:w-2/5 w-full'>
          {children}
        </div>
      </div>
    </div>
  )
}

export function TextDontHaveAnAccount({ teacher, me }) {
  console.log(me)
  return (
    <p className='sm:absolute mt-4 sm:mt-0 text-black top-0 right-0 md:text-sm text-xs'>Belum punya akun?
      <Link to={`${teacher || me === "teacher" ? '/signup-teacher' : '/signup'}`}>
        <span className='txt-blue cursor-pointer'> Daftar disini</span>
      </Link>
    </p>
  )
}

export function TextHaveAnAccount() {
  return (
    <p className='sm:absolute mt-4 sm:mt-0 text-black top-0 right-0 md:text-sm text-xs'>Sudah punya akun?
      <Link to='/login'>
        <span className='txt-blue cursor-pointer'> Masuk disini</span>
      </Link>
    </p>
  )
}

export default Container