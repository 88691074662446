import { Fragment, useEffect, useState } from "react";
import { AddQuizForm } from "../../../components/Form/admin/quiz";
import { NavDashboard } from "../../../components/Navbar";
import { MainDashboard } from "../../../layouts/Dashboard";
import { postQuiz, getAllLesson, getAllModul } from "../../../service/admin/adminService";
import { useCookies } from "../../../hooks/useCookies";

function AddQuiz() {
  const { getCookie } = useCookies();
  const token = getCookie("token");

  const [lesson, setLesson] = useState([]);
  const [modul, setModul] = useState([]);

  useEffect(() => {
    const fetchLesson = async () => {
      const lesson = await getAllLesson(token);
      const modul = await getAllModul(token)
      setLesson(lesson.data);
      setModul(modul.data);
    };
    fetchLesson();
  }, [token]);

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Kuis" />
        <div className="mt-6">
          <AddQuizForm addQuiz={postQuiz} token={token} lesson={lesson} modul={modul}/>
        </div>
      </MainDashboard>
    </Fragment>
  );
}

export default AddQuiz;
