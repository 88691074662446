import Cookies from 'js-cookie'

function useCookies() {
  const setCookie = (name, value, path = '', secure = true, expires = 365) => {
    Cookies.set(name, value, { path: path, secure: secure, expires: expires })
  }

  const getCookie = (name) => {
    if (Cookies.getJSON(name)) {
      return Cookies.getJSON(name)
    }

    return false
  }

  const removeCookie = (name, path = '') => {
    Cookies.remove(name, { path: path })
  }

  const getAllCookies = () => {
    return Cookies.getJSON()
  }

  return {
    setCookie,
    getCookie,
    removeCookie,
    getAllCookies
  }
}

export { useCookies }