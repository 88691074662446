import { useEffect } from "react";
import { Fragment, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { NavDashboard } from "../../../components/Navbar";
import { PopupDeleteConfirmation } from "../../../components/Popup";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableData,
} from "../../../components/Table";
import { useCookies } from "../../../hooks/useCookies";
import { useQuery } from "../../../hooks/useQuery";
import {
  getAllStudent,
  getStudentDetail,
  deleteStudent,
  statusStudent,
  getParentByIdStudent,
} from "../../../service/admin/adminService";
import ShowProfile from "../profile/ShowProfile";

function StudentsManage() {
  const history = useHistory();
  const match = useRouteMatch();
  const id = useQuery("id");
  const [showMenu, setShowMenu] = useState("list-student");
  const [showPopup, setShowPopup] = useState(false);
  const { getCookie } = useCookies();

  const [detailStudent, setDetailStudent] = useState([]);
  const [hasUpdateStudent, setHasUpdate] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const [students, setStudents] = useState([]);
  const [parent, setParent] = useState([]);
  const token = getCookie("token");

  useEffect(() => {
    const fetchStudent = async () => {
      const result = await getAllStudent(token);
      setStudents(result.data);
      setHasUpdate(false);
    };

    fetchStudent();
  }, [token, hasUpdateStudent]);

  useEffect(() => {
    const fetchParent = async () => {
      const result = await getParentByIdStudent(token, id);
      console.log(result);
      setParent(result.data);
    };

    fetchParent();
  }, [token, id]);

  const handleClickStudent = async (id) => {
    const result = await getStudentDetail(id, token);
    setDetailStudent(result.data);
    history.push(`${match.url}?id=${id}`);
  };

  const handleClickTab = (tabName) => {
    if (tabName === "list-student") {
      return setShowMenu(tabName);
    }

    setShowMenu(tabName);
  };

  const handleClickTrash = (event, idStudent) => {
    event.stopPropagation();
    setShowPopup(true);
    setActiveId(idStudent);
  };

  console.log(parent);

  return (
    <Fragment>
      <div
        className={`relative h-screen w-full md:p-7 p-4 bg-gray overflow-hidden ${
          id && "overflow-y-auto"
        }`}
      >
        <NavDashboard title="Kelola Murid" />
        <PopupDeleteConfirmation
          setState={setHasUpdate}
          token={token}
          action={deleteStudent}
          id={activeId}
          open={showPopup}
          setOpen={setShowPopup}
          text="Yakin ingin menghapus Murid?"
        />
        {!id ? (
          <div className="overflow-hidden flex md:flex-row flex-col absolute md:bottom-7 bottom-4 md:left-7 left-4 md:right-7 right-4 lg:top-20 md:top-36 top-36">
            {showMenu === "list-student" && (
              <div className="bg-white overflow-hidden h-full pb-16 md:mt-0 mt-6 rounded-2xl p-6 md:w-3/5 md:mr-6 md:order-1 order-2">
                <h1 className="text-xl font-medium">Data Murid</h1>
                <Table className="mt-4 h-full md:pb-16">
                  <TableHead className="md:flex hidden">
                    <TableRow className="text-sm">
                      <TableData className="w-full">Nama Murid</TableData>
                      <TableData className=""></TableData>
                      <TableData className="text-">Status</TableData>
                      <TableData className="text-center">Action</TableData>
                    </TableRow>
                  </TableHead>
                  <TableBody className="overflow-y-auto h-full">
                    {students.map((student) => (
                      <TableRow
                        key={student.id}
                        className="items-center hover:bg-blue-50 relative"
                        onClick={() => handleClickStudent(student.id)}
                      >
                        <TableData className="flex items-center w-max md:block hidden">
                          <div className="">
                            <div className="">
                              <img
                                className="md:w-24 md:h-14 object-cover"
                                src={`http://api.starbimbel.com/foto/student/${student.image}`}
                                alt="profile"
                              />
                            </div>
                          </div>
                        </TableData>
                        <TableData className="w-full">
                          <div className="md:pl-4 md:mt-0 mt-2">
                            <span className="text-sm block font-medium w-3/5 break-words">
                              {student.name}
                            </span>
                            <span className="text-xs block">
                              {student.school}
                            </span>
                          </div>
                        </TableData>
                        <TableData className="text-center">
                          {student.status ? (
                            <span className="md:static absolute top-2 left-0 block rounded-xl m-auto w-max md:py-2 md:px-6 md:border-2 border-green-200 text-green-200 md:text-base text-xs">
                              Active
                            </span>
                          ) : (
                            <span className="md:static absolute top-2 left-0 block rounded-xl m-auto w-max md:py-2 md:px-6 md:border-2 border-red-400 text-red-400 md:text-base text-xs">
                              Non Active
                            </span>
                          )}
                        </TableData>
                        <TableData className="text-center">
                          <i
                            className="block md:static absolute right-0 top-5 md:w-6 md:h-6 w-4 h-4 bg-cover  m-auto cursor-pointer bg-no-repeat"
                            style={{
                              backgroundImage:
                                "url('/assets/icons/trash-red.png')",
                            }}
                            onClick={(event) =>
                              handleClickTrash(event, student.id)
                            }
                          />
                        </TableData>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
            <div className="flex flex-col md:w-2/5 md:order-2 order-1">
              <div className="rounded-2xl bg-blue text-white p-6 h-max shadow-gray">
                <h1 className="text-xl font-medium">Statistik</h1>
                <ul className="text-sm mt-4 list-disc px-6">
                  <li className="relative">
                    <span>Murid Aktif</span>
                    <span className="absolute right-0">
                      {students.filter((student) => student.status).length}
                    </span>
                  </li>
                  <li className="relative">
                    <span>Murid Non Aktif</span>
                    <span className="absolute right-0">
                      {students.filter((student) => !student.status).length}
                    </span>
                  </li>
                  {/* <li className='relative'>
                    <span>Murid Mendaftar</span>
                    <span className='absolute right-0'>21</span>
                  </li> */}
                </ul>
              </div>
              <div className="mt-6 flex text-xs md:hidden">
                <button
                  className={`mr-3 py-3 px-6 ${
                    showMenu === "list-student"
                      ? "bg-blue text-white"
                      : "bg-white"
                  }  rounded-xl`}
                  onClick={() => handleClickTab("list-student")}
                >
                  Data Murid
                </button>
                <button
                  className={` ${
                    showMenu === "students-has-registered"
                      ? "bg-blue text-white"
                      : "bg-white"
                  } py-3 px-6 rounded-xl`}
                  onClick={() => handleClickTab("students-has-registered")}
                >
                  Murid Mendaftar
                </button>
              </div>
              {(showMenu === "students-has-registered" ||
                document.body.clientWidth > 768) && (
                <div className="rounded-2xl bg-white mt-6 p-6 overflow-hidden pb-20">
                  <h1 className="text-xl font-medium">Murid Mendaftar</h1>
                  <ul className="mt-6 overflow-y-auto h-full">
                    {students
                      .filter((student) => student.status === false)
                      .map((student) => (
                        <li
                          key={student.id}
                          className="flex items-center items-center border-b border-gray-900 pb-3"
                        >
                          <div className="w-14 h-14 flex items-center">
                            <img
                              className="w-full"
                              src={`http://api.starbimbel.com/foto/student/${student.image}`}
                              alt="profile"
                            />
                          </div>
                          <div className="ml-4 flex flex-col text-sm">
                            <span>{student.name}</span>
                            <span>{student.school}</span>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            {/* <button className='p-2 bg-blue text-white text-md rounded-md'>
            Lihat Detail Orang Tua
          </button> */}
            <ShowProfile
              apiDelete={deleteStudent}
              token={token}
              setHasUpdate={setHasUpdate}
              onSave={statusStudent}
              data={detailStudent}
              setOpen={setShowPopup}
              type="students"
            />
            <div className="my-8 bg-white p-4 rounded-md">
              <h2 className="text-xl">Data Orang Tua</h2>
              <div className="mt-4 flex flex-col md:text-base text-sm">
                <span>Nama: {parent?.name}</span>
                <span>Nomor Telepon: {parent?.phone}</span>
                <span>Telegram: {parent?.telegram}</span>
                <span>Whatsapp: {parent?.whatsapp}</span>
                <span>Email: {parent?.email}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default StudentsManage;
