import { Fragment, useEffect, useState } from "react"
import { NavDashboard } from '../../../components/Navbar'
import { AddModulForm } from "../../../components/Form/admin/modul"
import { useCookies } from '../../../hooks/useCookies'
import { getAllGrade, getAllLesson, createModul } from '../../../service/admin/adminService'
import { MainDashboard } from '../../../layouts/Dashboard'

function AddModul() {
  const { getCookie } = useCookies()
  const token = getCookie('token')
  const [gradeAndLesson, setGradeAndLesson] = useState({
    grade: [],
    lesson: []
  })

  useEffect(() => {
    const fethData = async () => {
      const grade = await getAllGrade(token)
      const lesson = await getAllLesson(token)

      setGradeAndLesson({
        grade, lesson
      })
    }

    fethData()
  }, [token])

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Tambah Modul" />
        <div className='flex mt-6'>
          <AddModulForm token={token} gradeAndLesson={gradeAndLesson} createModul={createModul} />
        </div>
      </MainDashboard>
    </Fragment>
  )
}

export default AddModul