const { useState, useEffect } = require("react");

function usePageTitle(title) {
  const [state] = useState(title);

  useEffect(() => {
    document.title = title
  }, [title])

  return state
}

export { usePageTitle }