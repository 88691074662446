function Container({ children }) {
  return (
    <div className='w-full flex'>
      {children}
    </div>
  )
}

export function MainDashboard({ children }) {
  return (
    <div className='md:p-7 p-4 bg-gray w-full h-screen overflow-y-auto overflow-x-hidden'>
      {children}
    </div>
  )
}

export function ContentDashboard({ children }) {
  return (
    <div className='bg-white rounded-xl mt-5 p-6 relative'>
      {children}
    </div>
  )
}

export default Container