function Section({ marginTop = "mt-8", id = "#", children, className }) {
    return (
        <section className={`xl:flex py-10 md:py-16 px-0 ${marginTop} ${className}`} id={id}>
            {children}
        </section>
    )
}

function FlexColw50({ children, className = '' }) {
    return (
        <div className={`xl:flex xl:flex-col xl:w-1/2 ${className}`}>
            {children}
        </div>
    )
}

function Title({ children, width = "w-5/6", marginTop='mt-6' }) {
    return (
        <h1 className={`text-3xl lg:text-4xl xl:text-5xl ${marginTop} sm:leading-tight md:leading-normal lg:leading-normal xl:leading-tight font-semibold relative w-full xl:${width}`}>
            {children}
        </h1>
    )
}

function SubTitle({ children, marginTop = 'mt-8' }) {
    return (
        <h3 className={`w-full text-sm md:text-base txt-black xl:w-2/3 ${marginTop} sub-title`}>{children}</h3>
    )
}

export {
    Section,
    FlexColw50,
    Title,
    SubTitle,
}