import { Fragment, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { NavDashboard } from '../../../components/Navbar'
import { PopupDeleteConfirmation } from '../../../components/Popup'
import { Table, TableBody, TableHead, TableRow, TableData } from '../../../components/Table'
import { useQuery } from '../../../hooks/useQuery'
import { MainDashboard } from '../../../layouts/Dashboard'
import ShowProfile from '../profile/ShowProfile'
import { useCookies } from '../../../hooks/useCookies'
import { getAllTeacher, getTeacherDetail, deleteTeacher, statusTeacher } from '../../../service/admin/adminService'

function TeachersManage() {
  const history = useHistory()
  const match = useRouteMatch()
  const id = useQuery("id")
  const [showMenu, setShowMenu] = useState('list-teacher')
  const [showPopup, setShowPopup] = useState(false)

  const [detailTeacher, setDetailTeacher] = useState([])
  const [hasUpdateTeacher, setHasUpdate] = useState(false)
  const [activeId, setActiveId] = useState(null)

  const { getCookie } = useCookies()

  const [teachers, setTeachers] = useState([])
  const token = getCookie("token")

  useEffect(() => {
    const fetchStudent = async () => {
      const result = await getAllTeacher(token)
      setTeachers(result.data)
      setHasUpdate(false)
    }

    fetchStudent()
  }, [token, hasUpdateTeacher])

  console.log(teachers)

  const handleClickTeacher = async (id) => {
    const result = await getTeacherDetail(id, token)
    setDetailTeacher(result.data)
    history.push(`${match.url}?id=${id}`)
  }

  const handleClickTab = (tabName) => {
    if (tabName === 'list-teacher') {
      return setShowMenu(tabName)
    }

    setShowMenu(tabName)
  }

  const handleClickTrash = (event, idTeacher) => {
    event.stopPropagation()
    setShowPopup(true)

    setActiveId(idTeacher)
  }

  return (
    <Fragment>
      <MainDashboard>

        <NavDashboard title="Kelola Guru" />
        <PopupDeleteConfirmation setState={setHasUpdate} token={token} id={activeId} action={deleteTeacher} open={showPopup} setOpen={setShowPopup} text="Yakin ingin menghapus Guru?" />
        {!id ? (
          <div className='flex md:flex-row flex-col mt-6'>
            {showMenu === "list-teacher" && (
              <div className='bg-white md:mt-0 mt-6 rounded-2xl p-6 md:w-3/5 md:mr-6 md:order-1 order-2'>
                <h1 className='text-xl font-medium'>Data Guru</h1>
                <Table className='mt-4'>
                  <TableHead className='md:flex hidden'>
                    <TableRow className='text-sm'>
                      <TableData className='w-full'>Nama Guru</TableData>
                      <TableData className='text-center'>Status</TableData>
                      <TableData className='text-center'>Action</TableData>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teachers.map(teacher => (
                      <TableRow key={teacher.id} className='items-center hover:bg-blue-50 relative' onClick={() => handleClickTeacher(teacher.id)}>
                        <TableData className='flex items-center w-full'>
                          <div className='flex items-center'>
                            {/* <input type="checkbox" className='mr-4 md:block hidden' /> */}
                            {/* <div className='w-14 h-14 flex justify-center items-center'>
                              <img className='w-full' src="/assets/avatar/default.png" alt="profile" />
                            </div> */}
                            <div className='mt-2 md:mt-0'> {/* ml-4 */}
                              <span className='text-sm block font-medium'>{teacher.name}</span>
                              {/* <span className='text-xs block'>Kelas 1SD</span> */}
                            </div>
                          </div>
                        </TableData>
                        <TableData className='text-center'>{
                          teacher.status ? (
                            <span className='md:static absolute top-2 left-0 block rounded-xl m-auto w-max md:py-2 md:px-6 md:border-2 border-green-200 text-green-200 md:text-base text-xs'>Active</span>
                          ) : <span className='md:static absolute top-2 left-0 block rounded-xl m-auto w-max md:py-2 md:px-6 md:border-2 border-red-400 text-red-400 md:text-base text-xs'>Non Active</span>}
                        </TableData>
                        <TableData className='text-center'>
                          <i className='block md:w-6 md:h-6 w-4 h-4 bg-cover  m-auto cursor-pointer bg-no-repeat' style={{ backgroundImage: "url('/assets/icons/trash-red.png')" }} onClick={(event) => handleClickTrash(event, teacher.id)} />
                          {/* <span className='block rounded-xl m-auto w-max py-2 px-6 border-2 border-red-400 text-red-400'>Non Active</span> */}
                        </TableData>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
            <div className='flex flex-col md:w-2/5 md:order-2 order-1'>
              <div className='rounded-2xl bg-blue text-white p-6 h-max shadow-gray'>
                <h1 className='text-xl font-medium'>Statistik</h1>
                <ul className='text-sm mt-4 list-disc px-6'>
                  <li className='relative'>
                    <span>Guru Aktif</span>
                    <span className='absolute right-0'>{teachers.filter(teacher => teacher.status).length}</span>
                  </li>
                  <li className='relative'>
                    <span>Guru Non Aktif</span>
                    <span className='absolute right-0'>{teachers.filter(teacher => !teacher.status).length}</span>
                  </li>
                  {/* <li className='relative'>
                    <span>Guru Mendaftar</span>
                    <span className='absolute right-0'>21</span>
                  </li> */}
                </ul>
              </div>
              <div className='mt-6 flex text-xs md:hidden'>
                <button className={`mr-3 py-3 px-6 ${showMenu === "list-teacher" ? 'bg-blue text-white' : 'bg-white'}  rounded-xl`} onClick={() => handleClickTab('list-teacher')}>Data Guru</button>
                <button className={` ${showMenu === "teacher-has-registered" ? 'bg-blue text-white' : 'bg-white'} py-3 px-6 rounded-xl`} onClick={() => handleClickTab('teacher-has-registered')}>Guru Mendaftar</button>
              </div>
              {(showMenu === "teacher-has-registered" || document.body.clientWidth > 768) && (
                <div className='rounded-2xl bg-white mt-6 p-6'>
                  <h1 className='text-xl font-medium'>Guru Mendaftar</h1>
                  <ul className='mt-6'>
                    {teachers.filter(teacher => teacher.status === false).map(teacher => (
                      <li key={teacher.id} className='flex items-center items-center border-b border-gray-900 pb-3'>
                        {/* <div className='w-14 h-14 flex items-center'>
                          <img className='w-full' src="/assets/avatar/default.png" alt="profile" />
                        </div> */}
                        <div className='flex flex-col text-sm'> {/* ml-4 */}
                          <span>{teacher.name}</span>
                          {/* <span>Kelas 1SD</span> */}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : <ShowProfile setHasUpdate={setHasUpdate} onSave={statusTeacher} token={token} apiDelete={deleteTeacher} data={detailTeacher} setOpen={setShowPopup} type="teachers" />}
      </MainDashboard>
    </Fragment>
  )
}

export default TeachersManage