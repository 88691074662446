import { BASE_URL } from '../../constant'

export async function signup(data) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/create`, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data)
  })).json()
  return result
}

export async function getDetailTeacher(token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getdetail`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}

export async function getAllSchedule(token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getallschedule`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}

export async function getAllLesson(token, idGrade) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getallLessonbyid/${idGrade}`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}

export async function getAllGrade(token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getallgrade`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}

export async function getAllChapter(token, idModul) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getallchapter/${idModul}`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}

export async function updateTeacher(data, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/update`, {
    method: "PUT",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify(data)
  })).json()
  return result
}

export async function checkPassword(password, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/passwordCek`, {
    method: "PUT",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify(password)
  })).json()
  return result
}

export async function changePassword(password, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/changepassword`, {
    method: "PUT",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify(password)
  })).json()
  return result
}

export async function getAllClass(token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getallclass`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}


// QUIZ
export async function getAllQuiz(idGrade, idClass, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getallquiz/${idGrade}/${idClass}`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}

export async function createClassQuiz(data, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/createclassquiz`, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify(data)
  })).json()
  return result
}

export async function getAllScoringQuiz(idClass, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getallscoringquiz/${idClass}`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}

export async function restartQuiz(idScore, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/restartquiz/${idScore}`, {
    method: "PUT",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify({ restart: 1})
  })).json()
  return result
}

// EXAM
export async function getAllExam(idGrade, idClass, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getallexam/${idGrade}/${idClass}`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}

export async function createClassExam(data, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/createclassexam`, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify(data)
  })).json()
  return result
}

export async function getAllScoringExam(idClass, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/getallscoringexam/${idClass}`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  })).json()
  return result
}

export async function restartExam(idScore, token) {
  const result = await (await fetch(`${BASE_URL}/v1/teacher/restartexam/${idScore}`, {
    method: "PUT",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify({ restart: 1})
  })).json()
  return result
}

