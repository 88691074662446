import { Fragment, useEffect, useState } from 'react'
import { ShowProfile } from '../../admin/profile/ShowProfile'
import { NavDashboard } from '../../../components/Navbar'
import { MainDashboard } from '../../../layouts/Dashboard'
import { useCookies } from '../../../hooks/useCookies'
import { getDetailTeacher } from '../../../service/teacher/teacherService'

function Profile() {
  const { getCookie } = useCookies()
  const token = getCookie("token")

  const [profile, setProfile] = useState([])

  useEffect(() => {
    const fetchProfile = async () => {
      const result = await getDetailTeacher(token)
      setProfile(result.data)
    }
    fetchProfile()
  }, [token])

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Profil" />
        <ShowProfile hideChecked canEdit hiddenIconDelete type="teachers" path="modul" closeTo='/teacher' data={profile}/>
      </MainDashboard>
    </Fragment>
  )
}

export default Profile