import { Fragment } from "react"

function Input({onChange = () => {}, name, autofocus, onKeyDown = () => {}, onMouseMove = () => {}, onClick = () => {}, classIcon = "", required, onBlur = () => false, onFocus = () => false, readOnly = false, className = '', register, type, defaultValue, id, placeholder, showIcon = false, cursorIcon = 'cursor-default', iconName, eventClickIcon = () => false, ...props }) {
  return (
    <Fragment>
      <input {...props} name={name} autoFocus={autofocus} required={required} onChange={onChange} onKeyDown={onKeyDown} onClick={onClick} onMouseMove={onMouseMove} onBlur={onBlur} onFocus={onFocus} readOnly={readOnly} className={`border-gray py-3 px-5 rounded-2xl outline-none text-sm ${className}`}
        {...register} type={type} id={id} defaultValue={defaultValue} placeholder={placeholder}
      />
      {showIcon && (
        <div className={`absolute w-4 h-4 top-11 right-3 ${classIcon} ${cursorIcon}`} onClick={eventClickIcon}>
          <img src={`/assets/icons/${iconName}.png`} alt={iconName} />
        </div>
      )}
    </Fragment>
  )
}

function InputGroup({ children, className, onMouseLeave = () => {} }) {
  return (
    <div className={`flex flex-col mt-4 relative ${className}`} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  )
}

export {
  Input,
  InputGroup
}