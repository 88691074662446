import { BASE_URL } from '../../constant'

async function getProfile(token) {
  const result = await (await fetch(`${BASE_URL}/v1/profile`, {
    method: "GET",
    headers: {
      "Accept": "application/json",
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    },
  })).json()
  return result
}

export { getProfile }