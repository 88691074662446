import { Link } from 'react-router-dom'
import ContainerLogin, { TextDontHaveAnAccount } from '../components/ContainerLogin'
import { useForm } from "react-hook-form";
import { Input, InputGroup } from '../components/Input'
import { REGEX } from '../utils/regex'
import { useQuery } from '../hooks/useQuery';
import { forgotPassword } from '../service/common';

function ForgotPassword() {
  return (
    <ContainerLogin title="Hai, Selamat Datang Kembali di Star Bimbel" subTitle="Mau Belajar Apa Hari Ini?">
      <FormForgotPassword />
    </ContainerLogin>
  )
}

function FormForgotPassword() {
  const me = useQuery("me")
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    if(data.email) {
      const result =  await forgotPassword(data.email)
      if(!result.errors) {
        return alert("Pesan telah dikirim, Silahkan cek email anda!")
      }
    }
  };

  return (
    <>
      <h1 className='text-2xl font-semibold'>Lupa Password?</h1>
      <p className='text-xs md:text-sm mt-3'>
        Masukkan alamat email Anda yang anda gunakan untuk bergabung  dan
        kami akan mengirimkan instruksi untuk mengatur ulang kata sandi Anda.
      </p>
      <Link to={`${me === "teacher" ? "/teacher" : `${me === "admin" ? '/admin' : "/login"}`}`}>
        <div className='absolute top-0 sm:left-12 bg-gray p-3'>
          <img src='/assets/icons/exit.png' alt="exit" />
        </div>
      </Link>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <label className='mb-2 text-sm' htmlFor="email">Email</label>
          <Input register={register("email", {
            pattern: REGEX.email,
            maxLength: 35,
            required: true,
          })} type="email" id="email" placeholder="Alamat Email" showIcon iconName='email' />
          {errors.email && <span className='text-xs text-red-500 mt-1'>Alamat Email tidak valid</span>}
        </InputGroup>
        <button className='w-full md:text-base text-sm p-3 rounded-2xl text-white bg-blue mt-6 shadow-md'>Kirim</button>
        <TextDontHaveAnAccount me={me} />
      </form>
    </>
  )
}

export default ForgotPassword