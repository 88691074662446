import { Fragment, useEffect, useState } from "react"
import { NavDashboard } from '../../../components/Navbar'
import { EditModulForm } from "../../../components/Form/admin/modul"
import { useCookies } from '../../../hooks/useCookies'
import { useQuery } from '../../../hooks/useQuery'
import { Redirect } from "react-router-dom"
import { getAllGrade, getAllLesson, updateModul, getAllModul, getAllChapter, deleteModul } from "../../../service/admin/adminService"
import { MainDashboard } from "../../../layouts/Dashboard"

function EditModul() {
  const { getCookie } = useCookies()
  const token = getCookie('token')
  const id = useQuery("id")
  const [gradeAndLesson, setGradeAndLesson] = useState({
    grade: [],
    lesson: [],
    specificModulById: [],
    allChapter: []
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fethData = async () => {
      const grade = await getAllGrade(token)
      const lesson = await getAllLesson(token)
      const specificModulById = await getAllModul(token)
      const allChapter = await getAllChapter(token, id)

      setGradeAndLesson({
        grade, 
        lesson, 
        specificModulById, 
        allChapter: allChapter.data.map(chapter => ({
          order: chapter.order,
          link: chapter.link,
          description: chapter.description
        }))
      })

      setLoading(false)
    }

    fethData()

    return () => setGradeAndLesson({})
  }, [token, id])

  if (!id) {
    return <Redirect to='/admin/modul' />
  }

  if(loading) {
    return <div>Loading</div>
  }

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Ubah Modul" />
        <div className='flex mt-6'>
          <EditModulForm token={token} gradeAndLesson={gradeAndLesson} updateModul={updateModul} deleteModul={deleteModul}/>
        </div>
      </MainDashboard>
    </Fragment>
  )
}

export default EditModul