import { Fragment, useEffect, useState } from 'react'
import { NavDashboard } from '../../../components/Navbar'
import { useToggle } from '../../../hooks/useToggle'
import { usePaymentData } from '../../../hooks/usePaymentData'
import { CardStudent } from '../../../components/Cards'
import { ContentDashboard } from '../../../layouts/Dashboard'
import { getStudent } from '../../../service/parent/parentService'
import { useCookies } from '../../../hooks/useCookies'

function StudentsManage() {
  const { minimized } = useToggle()
  const { eventListener: { resetDataPayment } } = usePaymentData()
  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(true)

  const { getCookie } = useCookies()
  const token = getCookie('token')

  useEffect(() => {
    resetDataPayment()

    
  }, [resetDataPayment])

  useEffect(() => {
    const getStudents = async () => {
      const students = await getStudent(token)
      if (students.status === "success") {
        setStudents(students.data.student)
        return setLoading(false)
      }

      console.log(students)
      return setLoading(false)
    }

    getStudents()

    return () => setStudents({})
  }, [token])

  return (
    <Fragment>
      <NavDashboard title="Murid" />
      <ContentDashboard>
        {loading && <p>Loading..</p>}
        {!loading && (
          <Fragment>
            <div className='flex flex-col mb-6'>
              <h1 className='text-xl font-medium'>Data Murid</h1>
              <span className='mt-3 text-sm'>{students.length} Murid</span>
            </div>
            <div className={`grid ${minimized ? 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4' : 'lg:grid-cols-2 xl:grid-cols-3'} gap-6 `}>
              {students.map(student => {
                console.log(student)
                return <CardStudent key={student.id} name={student.name} studentID={student.id} imageSrc={student.image} />
              })}
            </div>
          </Fragment>
        )}
      </ContentDashboard>
    </Fragment>
  )
}

export default StudentsManage