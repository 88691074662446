import { SidebarParent } from '../../components/Sidebar'
import { usePageTitle } from "../../hooks/usePageTitle"
import { Redirect, Switch } from 'react-router-dom';
import ContainerDashboard, { MainDashboard } from '../../layouts/Dashboard'
import { RouteWithSubRoute } from '../../routes'
import { useCookies } from '../../hooks/useCookies'
import { checkRole } from '../../utils/common/checkRole'
import { checkCurrentRole } from '../../utils/common/checkCurrentRole'
import Login from "../Login";
import { getProfile } from '../../service/profile/profileService'
import { useEffect, useState } from 'react';

function Dashboard({ routes }) {
  usePageTitle('Dashboard Parent - Star Bimbel')
  const [profile, setProfile] = useState([])

  const { getCookie } = useCookies()
  const token = getCookie('token')

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getProfile(token)
      if(profile.status === "Success") {
        return setProfile(profile.data)
      }

      console.log(profile) // check error
    }

    fetchProfile()

  }, [token])

  if (!token) {
    return <Login />
  }

  if (!checkRole("parent", token)) {
    const currentRole = checkCurrentRole(token)

    if(currentRole === "student") {
      return <Redirect to={`/dashboard`} />
    }

    return <Redirect to={`/${currentRole}`} />
  }

  return (
    <ContainerDashboard>
      <SidebarParent {...profile}/>
      <Redirect to='/parent/students' />
      <MainDashboard>
        <Switch>
          <RouteWithSubRoute routes={routes} />
        </Switch>
      </MainDashboard>
    </ContainerDashboard>
  )
}

export default Dashboard