import { Fragment, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Input, InputGroup } from "../../../Input"
import { PopupInput } from "../../../Popup"
import { CONSTANT } from "../../../../constant"
import TextRed from "../../../ErrorField"
import { useForm, Controller } from "react-hook-form"
import { useQuery } from '../../../../hooks/useQuery'
import Select from 'react-select'

export function AddClassForm({ token, students, classCreate }) {
  const [showPopup, setShowPopup] = useState(false)
  const { register, handleSubmit, formState: { errors }, control } = useForm()
  const [errorMessage, setErrorMessage] = useState({})
  const history = useHistory()

  const onSubmit = async (data) => {
    if (parseInt(data.ammount) < data.student.length) {
      return alert("Total Murid melebihi jumlah siswa yang seharusnya, silahkan cek input jumlah siswa")
    }

    const result = await classCreate(data, token)
    if (result.status === "Success") {
      alert("Tambah Kelas Berhasil")
      return history.push('/admin/class')
    }

    setErrorMessage(result.errors)
  }

  const studentsOption = students.map(student => ({
    value: student.id,
    label: student.name
  }))

  return (
    <Fragment>
      <PopupInput open={showPopup} setOpen={setShowPopup} title="Tambahkan Murid" text="Nama Murid" />
      <form onSubmit={handleSubmit(onSubmit)} className='flex md:flex-row flex-col md:mb-0 mb-12'>
        <div className='md:w-3/5 bg-white rounded-xl p-6 md:mr-6 md:mb-0 mb-6'>
          <h1 className='text-xl font-medium'>Tambah Kelas</h1>
          <div className='mt-6 border-gray-300'>
            <InputGroup>
              <label className="mb-2 text-sm" htmlFor="name">Nama Kelas</label>
              <Input register={register('name', { required: true })} />
              {(errors.name && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.name && <TextRed>{errorMessage.name.message}</TextRed>)}
            </InputGroup>
            <InputGroup>
              <label className="mb-2 text-sm" htmlFor="classGrade">Tingkatan Kelas</label>
              <Input type="number" register={register('classGrade', { required: true })} />
              {(errors.classGrade && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.classGrade && <TextRed>{errorMessage.classGrade.message}</TextRed>)}
            </InputGroup>
            <InputGroup>
              <label className="mb-2 text-sm" htmlFor="ammount">Jumlah Siswa</label>
              <Input type="number" register={register('ammount', { required: true })} />
              {(errors.ammount && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.ammount && <TextRed>{errorMessage.ammount.message}</TextRed>)}
            </InputGroup>
          </div>
        </div>

        <div className='flex flex-col md:w-2/5'>
          <div className='bg-white rounded-xl p-6'>
            <h1 className='text-xl font-medium'>Murid</h1>
            <div className='flex items-end justify-between flex-wrap'>
              <InputGroup className='w-full'>
                <label className="mb-2 text-sm" htmlFor="murid">Nama Murid</label>
                <Controller
                  control={control}
                  defaultValue=""
                  name="student"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select value={studentsOption.filter(c => value.includes(c.value))} onChange={val => onChange(val.map(c => c.value))} options={studentsOption} isMulti />
                  )}
                />
                {errors.murid && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
            </div>
          </div>

          <div className='mt-6 flex justify-between'>
            <Link to="/admin/class">
              <button type="button" className='py-4 px-8 bg-white rounded-xl '>Kembali</button>
            </Link>
            <button type="submit" className='py-4 px-8 bg-blue rounded-xl text-white'>Simpan</button>
          </div>
        </div>
      </form>
    </Fragment>
  )
}

export function AddScheduleClassForm({ token, teachers, lessonSchedule, createSchedule }) {
  const { register, handleSubmit, formState: { errors }, control } = useForm()
  const history = useHistory()
  const id = useQuery("id")

  const onSubmit = async (data) => {
    data.idClass = id

    const result = await createSchedule(data, token)
    if (result.status === "Success") {
      alert("Tambah jadwal berhasil")
      return backToPreviousPage()
    }

    console.log(result)
  }

  const backToPreviousPage = () => {
    history.push(`/admin/class/schedules?id=${id}`)
  }

  const teachersOption = teachers.map(teacher => ({
    value: teacher.id,
    label: teacher.name
  }))

  const lessonScheduleOption = lessonSchedule.map(lesson => ({
    value: lesson.id,
    label: lesson.name
  }))

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)} className='md:mb-0 mb-12'>
        <div className='bg-white rounded-xl p-6 md:mr-6 md:mb-0 mb-6'>
          <h1 className='text-xl font-medium'>Tambah Jadwal Kelas</h1>
          <div className='mt-8'>
            <InputGroup className=''>
              <label className="mb-2 text-sm" htmlFor="title">Judul</label>
              <Input type="text" register={register("title", { required: true })} />
              {errors.title && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
            <InputGroup>
              <label htmlFor="idTeacher" className='text-sm mb-2'>Nama Guru</label>
              <Controller
                name="idTeacher"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => {
                  return <Select value={teachersOption.filter(val => val.value === value)} onChange={val => onChange(val.value)} options={teachersOption} />
                }}
              />
              {errors.teacher && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
            <div className='flex md:flex-row flex-col'>
              <InputGroup className='md:w-1/2'>
                <label className="mb-2 text-sm" htmlFor="startTime">Jam Mulai</label>
                <Input type="time" register={register("startTime", { required: true })} />
                {errors.startTime && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
              <InputGroup className='md:w-1/2 md:ml-3'>
                <label className="mb-2 text-sm" htmlFor="endTime">Jam Selesai</label>
                <Input type="time" register={register("endTime", { required: true })} />
                {errors.endTime && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
            </div>
            <InputGroup>
              <label className="mb-2 text-sm" htmlFor="date">Hari / Tanggal</label>
              <Input type="date" register={register("date", { required: true })} />
              {errors.date && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
            <InputGroup>
              <label className="mb-2 text-sm" htmlFor="link">Link</label>
              <Input register={register("link", { required: true })} />
              {errors.link && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
            <InputGroup>
              <label htmlFor="mapel" className='text-sm mb-2'>Mapel</label>
              <Controller
                name="idLesson"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => {
                  return <Select value={lessonScheduleOption.filter(val => val.value === value)} onChange={val => onChange(val.value)} options={lessonScheduleOption} />
                }}
              />
              {errors.teacher && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
          </div>
        </div>

        <div className='mt-6 flex justify-between'>
          <button className='py-4 px-8 bg-white rounded-xl' type="button" onClick={backToPreviousPage}>Kembali</button>
          <button className='py-4 px-8 bg-blue rounded-xl text-white'>Simpan</button>
        </div>
      </form >
    </Fragment >
  )
}

