import { createContext, useContext, useState } from "react"

const modulContext = createContext({})

function ProviderCategoryModul() {
  const [category, setCategory] = useState("")
  const [hiddenMenu, setHiddenMenu] = useState(false)

  const changeCategory = (category) => {
    setCategory(category)
    if(window.innerWidth < 1024) {
      setHiddenMenu(true)
    }
  }

  const handleHiddenMenu = (value) => {
    setHiddenMenu(value)
  }

  return {
    category,
    hiddenMenu,
    event: {
      changeCategory,
      setHiddenMenu: handleHiddenMenu
    }
  }
}

export default function ProviderModul({ children }) {
  const state = ProviderCategoryModul()
  return (
    <modulContext.Provider value={state}>
      {children}
    </modulContext.Provider>
  )
}

export const useCategoryModul = () => useContext(modulContext)