import { Link } from 'react-router-dom'

function Close({ to, className }) {
  return (
    <div className={`${className} right-6 flex items-center`}>
      <Link to={to}>
        <div className='cursor-pointer bg-gray p-4 rounded-xl'>
          <img src='/assets/icons/exit.png' alt='close' />
        </div>
      </Link>
    </div>
  )
}

export default Close