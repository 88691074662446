/* eslint-disable no-unused-vars */
import { Fragment, useState } from "react";
import { Input, InputGroup } from "../../../Input";
import { useHistory } from "react-router-dom";
import TextRed from "../../../ErrorField";
import { Controller, useForm } from "react-hook-form";
import { CONSTANT } from "../../../../constant";
import Select from "react-select";

export function EditExamForm({
  token,
  editExam,
  lesson,
  modul,
  detailExam,
  id,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState({});
  const [kunciJawaban, setKunciJawaban] = useState(
    detailExam?.examKeys.map((key) => ({
      number: key.number,
      answer: key.answer,
    }))
  );

  const [exam, setExam] = useState({
    title: detailExam?.title,
    description: detailExam?.description,
    totalQuestion: detailExam?.totalQuestion,
    idLesson: detailExam?.idLesson,
    idModul: detailExam?.idModul,
    duration: detailExam?.duration,
    linkPdf: detailExam?.linkPdf,
    answerPdf: detailExam?.answerPdf,
    answerKey: kunciJawaban,
  });

  console.log(detailExam);

  const onSubmit = async () => {
    exam.id = id;
    console.log("hi")

    if (exam?.answerKey?.length === 0) {
      return alert("Kunci Jawaban Masih Kosong, harap diisi");
    }

    if (exam.answerKey.length > parseInt(exam.totalQuestion)) {
      return alert(
        "Total kunci jawaban tidak sama dengan jumlah soal, harap di cek!"
      );
    } else if (exam.answerKey.length < parseInt(exam.totalQuestion)) {
      return alert(
        "Total jumlah soal tidak sama dengan total kunci jawaban, harap di cek!"
      );
    }

    const distinctItems = new Set(
      exam.answerKey.map((data) => parseInt(data.number))
    );
    console.log(distinctItems);
    if (distinctItems.size < exam.answerKey.length) {
      return alert("Ada beberapa nomor yang duplikat, silahkan hapus!");
    }

    console.log(exam);
    const result = await editExam(exam, token);
    console.log(result);
    if (result.status === "Success") {
      alert("Edit Ujian Berhasil");
      return history.push("/admin/exam");
    }

    setErrorMessage(result.errors);
  };

  const handleChangeForm = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "number" || name === "answer") {
      return setKunciJawaban((prev) => [{ ...prev[0], [name]: value }]);
    }

    setExam((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickAddKunci = () => {
    setExam((prev) => ({
      ...prev,
      answerKey: [...(prev?.answerKey ?? []), ...kunciJawaban],
    }));

    setValue("number", "");
    setValue("answer", "");
  };

  const handleClickEnterKunci = (e) => {
    if (e.key === "Enter") {
      return e.preventDefault();
    }
  };

  const handleClickRemove = (event) => {
    const target = event.target.dataset.id;
    exam.answerKey[target] = null;
    setExam((prev) => ({
      ...prev,
      answerKey: exam.answerKey.filter((data) => data !== null),
    }));
  };

  const lessonOptions = lesson?.map((lesson) => ({
    value: lesson.id,
    label: lesson.name,
  }));

  const modulOptions = modul?.map((modul) => ({
    value: modul.idModul,
    label: modul.nameModul,
  }));

  if (detailExam.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex md:flex-row flex-col"
      >
        <div className="rounded-xl p-6 bg-white md:w-3/5 md:mr-6">
          <h1 className="text-xl font-medium">Edit Ujian</h1>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="title">
              Judul
            </label>
            <Input
              defaultValue={detailExam.title}
              onChange={handleChangeForm}
              name="title"
              required
            />
            {errors.title && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="description">
              Deskripsi
            </label>
            <textarea
              onChange={handleChangeForm}
              defaultValue={detailExam.description}
              name="description"
              required
              className="rounded-xl border-gray outline-none p-3 text-sm"
            />
            {errors.description && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="totalQuestion">
              Jumlah Soal
            </label>
            <Input
              type="number"
              onChange={handleChangeForm}
              defaultValue={detailExam.totalQuestion}
              name="totalQuestion"
              required
            />
            {errors.totalQuestion && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <div className="flex md:flex-row flex-col">
            <InputGroup className="md:w-1/2 md:mr-4">
              <label className="mb-2 text-sm" htmlFor="idLesson">
                Nama Pelajaran
              </label>
              <Controller
                name="idLesson"
                defaultValue={setValue("idLesson", detailExam.idLesson)}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => {
                  const label = lesson.filter(
                    (data) => data.id === detailExam.idLesson
                  );
                  return (
                    <Select
                      defaultValue={{
                        label: label?.[0]?.name,
                        value: detailExam?.idLesson,
                      }}
                      onChange={(val) => (exam.idLesson = val.value)}
                      options={lessonOptions}
                    />
                  );
                }}
              />
              {errors.idLesson && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
            <InputGroup className="md:w-1/2">
              <label className="mb-2 text-sm" htmlFor="idModul">
                Modul
              </label>
              <Controller
                name="idModul"
                defaultValue={setValue("idModul", detailExam.idModul)}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => {
                  const label = modul.filter(
                    (data) => data.idModul === detailExam.idModul
                  );
                  return (
                    <Select
                      defaultValue={{
                        label: label?.[0]?.nameModul,
                        value: detailExam?.idModul,
                      }}
                      onChange={(val) => (exam.idModul = val.value)}
                      options={modulOptions}
                    />
                  );
                }}
              />
              {errors.idModul && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
          </div>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="duration ">
              Durasi
            </label>
            <Input
              type="number"
              onChange={handleChangeForm}
              name="duration"
              defaultValue={detailExam.duration}
              required
            />
            {errors.duration && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="linkPdf">
              Link Pdf (soal)
            </label>
            <Input
              defaultValue={detailExam.linkPdf}
              onChange={handleChangeForm}
              name="linkPdf"
              required
            />
            {errors.linkPdf && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="answerPdf">
              Link Pdf (Pembahasan)
            </label>
            <Input
              defaultValue={detailExam.answerPdf}
              onChange={handleChangeForm}
              name="answerPdf"
              required
            />
            {errors.answerPdf && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
        </div>
        <div className="flex flex-col md:w-2/5 md:mt-0 mt-6 md:mb-0 mb-6">
          <div className="rounded-xl p-6 bg-white ">
            <h1 className="text-xl font-medium">Kunci Jawaban</h1>
            <div className="flex md:flex-row flex-col pb-6 border-b">
              <InputGroup className="md:w-1/2 md:pr-3">
                <label className="mb-2 text-sm" htmlFor="number">
                  Nomor
                </label>
                <Input
                  onChange={handleChangeForm}
                  type="number"
                  name="number"
                  onKeyDown={handleClickEnterKunci}
                />
                {errors.number && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
              <InputGroup className="md:w-1/2">
                <label className="mb-2 text-sm" htmlFor="answer">
                  Jawaban
                </label>
                <Input
                  type="text"
                  name="answer"
                  onKeyDown={handleClickEnterKunci}
                  onChange={handleChangeForm}
                />
                {errors.answer && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
            </div>
            <button
              onClick={handleClickAddKunci}
              type="button"
              className="bg-blue p-3 rounded-xl text-white w-full mt-4 text-sm"
            >
              Tambah Kunci Jawaban
            </button>
            {exam?.answerKey?.map((data, id) => {
              return (
                <Fragment key={id}>
                  {data.number && (
                    <div className="flex mt-6 text-sm items-center">
                      <div
                        data-id={id}
                        className="mr-2 cursor-pointer"
                        onClick={handleClickRemove}
                      >
                        <img
                          data-id={id}
                          src="/assets/icons/exit.png"
                          alt="remove"
                        />
                      </div>
                      <span>
                        Nomor {data.number} - {data.answer}
                      </span>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>

          <div className="flex justify-between mt-6">
            <button
              onClick={() => history.push("/admin/exam")}
              className="p-4 bg-white rounded-md"
              type="button"
            >
              Kembali
            </button>
            <button type="submit" className="p-4 bg-blue text-white rounded-md">
              Simpan
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

export function AddExamForm({ addExam, token, lesson, modul }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState({});
  const [kunciJawaban, setKunciJawaban] = useState([]);

  const [exam, setExam] = useState({});

  const onSubmit = async () => {
    if (exam?.answerKey?.length === 0) {
      return alert("Kunci Jawaban Masih Kosong, harap diisi");
    }

    if (exam.answerKey.length > parseInt(exam.totalQuestion)) {
      return alert(
        "Total kunci jawaban tidak sama dengan jumlah soal, harap di cek!"
      );
    } else if (exam.answerKey.length < parseInt(exam.totalQuestion)) {
      return alert(
        "Total jumlah soal tidak sama dengan total kunci jawaban, harap di cek!"
      );
    }

    const distinctItems = new Set(exam.answerKey.map((data) => data.number));
    if (distinctItems.size < exam.answerKey.length) {
      return alert("Ada beberapa nomor yang duplikat, silahkan hapus!");
    }

    const result = await addExam(exam, token);
    if (result.status === "Success") {
      alert("Tambah Ujian Berhasil");
      return history.push("/admin/exam");
    }

    console.log(result);
    setErrorMessage(result.errors);
  };

  const handleChangeForm = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "number" || name === "answer") {
      return setKunciJawaban((prev) => [
        { ...prev[0], [name]: value.toUpperCase() },
      ]);
    }

    setExam((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickAddKunci = () => {
    setExam((prev) => ({
      ...prev,
      answerKey: [...(prev?.answerKey ?? []), ...kunciJawaban],
    }));

    setValue("number", "");
    setValue("answer", "");
  };

  const handleClickEnterKunci = (e) => {
    if (e.key === "Enter") {
      return e.preventDefault();
    }
  };

  const handleClickRemove = (event) => {
    const target = event.target.dataset.id;
    exam.answerKey[target] = null;
    setExam((prev) => ({
      ...prev,
      answerKey: exam.answerKey.filter((data) => data !== null),
    }));
  };

  // const lessonOptions = lesson?.map((lesson) => ({
  //   value: lesson.id,
  //   label: lesson.name,
  // }));

  // const modulOptions = modul?.map((modul) => ({
  //   value: modul.idModul,
  //   label: modul.nameModul,
  // }));

  return (
    <Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex md:flex-row flex-col"
      >
        <div className="rounded-xl p-6 bg-white md:w-3/5 md:mr-6">
          <h1 className="text-xl font-medium">Tambah Ujian</h1>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="title">
              Judul
            </label>
            <Input onChange={handleChangeForm} name="title" required />
            {errors.title && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="description">
              Deskripsi
            </label>
            <textarea
              onChange={handleChangeForm}
              name="description"
              required
              className="rounded-xl border-gray outline-none p-3 text-sm"
            />
            {errors.description && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="totalQuestion">
              Jumlah Soal
            </label>
            <Input
              type="number"
              onChange={handleChangeForm}
              name="totalQuestion"
              required
            />
            {errors.totalQuestion && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <div className="flex md:flex-row flex-col">
            <InputGroup className="md:w-1/2 md:mr-4">
              <label className="mb-2 text-sm" htmlFor="idLesson">
                Nama Pelajaran
              </label>
              <select
                className="rounded-xl border-gray p-3 outline-none"
                required
                onChange={handleChangeForm}
                name="idLesson"
              >
                <option value="" hidden>
                  --Pilih--
                </option>
                {lesson?.map((lesson, id) => {
                  return (
                    <option key={id} value={lesson.id}>
                      {lesson.name}
                    </option>
                  );
                })}
              </select>
              {errors.idLesson && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
            <InputGroup className="md:w-1/2">
              <label className="mb-2 text-sm" htmlFor="idModul">
                Modul
              </label>
              <select
                className="rounded-xl border-gray p-3 outline-none"
                onChange={handleChangeForm}
                name="idModul"
                required
              >
                <option value="" hidden>
                  --Pilih--
                </option>
                {modul?.map((modul, id) => {
                  return (
                    <option key={id} value={modul.idModul}>
                      {modul.nameModul}
                    </option>
                  );
                })}
              </select>
              {errors.idModul && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
          </div>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="duration ">
              Durasi
            </label>
            <Input
              type="number"
              onChange={handleChangeForm}
              name="duration"
              required
            />
            {errors.duration && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="linkPdf">
              Link Pdf (soal)
            </label>
            <Input onChange={handleChangeForm} name="linkPdf" required />
            {errors.linkPdf && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 text-sm" htmlFor="answerPdf">
              Link Pdf (Pembahasan)
            </label>
            <Input onChange={handleChangeForm} name="answerPdf" required />
            {errors.answerPdf && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
        </div>
        <div className="flex flex-col md:w-2/5 md:mt-0 mt-6 md:mb-0 mb-6">
          <div className="rounded-xl p-6 bg-white ">
            <h1 className="text-xl font-medium">Kunci Jawaban</h1>
            <div className="flex md:flex-row flex-col pb-6 border-b">
              <InputGroup className="md:w-1/2 md:pr-3">
                <label className="mb-2 text-sm" htmlFor="number">
                  Nomor
                </label>
                <Input
                  onChange={handleChangeForm}
                  type="number"
                  name="number"
                  onKeyDown={handleClickEnterKunci}
                />
                {errors.number && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
              <InputGroup className="md:w-1/2">
                <label className="mb-2 text-sm" htmlFor="answer">
                  Jawaban
                </label>
                <Input
                  type="text"
                  name="answer"
                  onKeyDown={handleClickEnterKunci}
                  onChange={handleChangeForm}
                />
                {errors.answer && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
            </div>
            <button
              onClick={handleClickAddKunci}
              type="button"
              className="bg-blue p-3 rounded-xl text-white w-full mt-4 text-sm"
            >
              Tambah Kunci Jawaban
            </button>
            {exam?.answerKey?.map((data, id) => {
              return (
                <Fragment key={id}>
                  {data.number && (
                    <div className="flex mt-6 text-sm items-center">
                      <div
                        data-id={id}
                        className="mr-2 cursor-pointer"
                        onClick={handleClickRemove}
                      >
                        <img
                          data-id={id}
                          src="/assets/icons/exit.png"
                          alt="remove"
                        />
                      </div>
                      <span>
                        Nomor {data.number} - {data.answer}
                      </span>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>

          <div className="flex justify-between mt-6">
            <button
              onClick={() => history.push("/admin/exam")}
              className="p-4 bg-white rounded-md"
              type="button"
            >
              Kembali
            </button>
            <button type="submit" className="p-4 bg-blue text-white rounded-md">
              Simpan
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
}
