function Footer() {
  return (
    <footer className='grid md:grid-cols-3 grid-cols-1 md:gap-12 gap-6 md:mt-24 mt-16 md:mb-16 mb-2'>
      <div>
        <div className='w-max -ml-3'>
          <img src="/assets/icons/logo.png" alt="logo" />
        </div>
        <p className='md:mt-5 mt-2 txt-black md:text-base text-sm'>Star Bimbel adalah Bimbingan Belajar dengan tatap muka Online/Offline untuk jenjang SD, SMP, SMA/SMK.</p>
      </div>
      <div>
        <span className='font-semibold md:text-lg text-md'>Alamat</span>
        <p className='md:mt-5 mt-2 txt-black md:text-base text-sm'>Office dan bimbel :
        Jl Galuh IV Blok E 10 Komp LAN 1 Pisangan Ciputat Timur Tangerang Selatan Banten
        </p>
        <p className='md:mt-5 mt-2 txt-black md:text-base text-sm'>Bimbel: Jl Inpres no 50 Cireundeu Ciputat Timur Tangerang Selatan Banten</p>
      </div>
      <div>
        <span className='font-semibold md:text-lg text-md'>Kontak</span>
        <div className='md:mt-5 mt-2 txt-black md:text-base text-sm flex flex-col'>
          <span>Telepon: 085710388785</span>
          <span>Instagram : starbimbelcireundeu</span>
        </div>
      </div>
    </footer>
  )
}

export default Footer