import { BASE_URL } from "../../constant";

async function getStudent(token) {
  // ambil semua data murid
  try {
    return await (
      await fetch(`${BASE_URL}/v1/parent/getmurid`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    ).json();
  } catch (error) {
    console.log(error);
  }
}

async function addStudent(data, token) {
  // tambah murid
  const result = await (
    await fetch(`${BASE_URL}/v1/parent/addmurid`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  ).json();

  console.log(result);
  return result;
}

async function passwordCheck(data, token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/parent/passwordCek`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  ).json();

  return result;
}

async function changePassword(data, token) {
  console.log(data);
  const result = await (
    await fetch(`${BASE_URL}/v1/parent/changepassword`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  ).json();

  return result;
}

async function getAllPackage(token) {
  return await (
    await fetch(`${BASE_URL}/v1/package/getallpackage`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
}

async function getPackagePayment(token, id) {
  const result = await (
    await fetch(`${BASE_URL}/v1/package/getpackagepayment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(id),
    })
  ).json();

  return result;
}

async function getAllPaymentData(token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/payment/getallparentside`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  return result;
}

async function checkPromo(data, token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/promo/checkpromo`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  ).json();

  return result;
}

async function postPaymentData(data, token) {
  const result = await (
    await fetch(`${BASE_URL}/v1/payment/request`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  ).json();

  return result;
}

async function updateParent(body, token) {
  return await (
    await fetch(`${BASE_URL}/v1/parent`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
  ).json();
}

async function updateStudentByParent(body, token, id) {
  return await (
    await fetch(`${BASE_URL}/v1/parent/editmurid/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
  ).json();
}

async function getScoreQuizStudent(token, idStudent) {
  return await (
    await fetch(`${BASE_URL}/v1/parent/showquiz/${idStudent}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
}

async function getScoreExamStudent(token, idStudent) {
  return await (
    await fetch(`${BASE_URL}/v1/parent/showexam/${idStudent}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
}

export {
  checkPromo,
  changePassword,
  getStudent,
  addStudent,
  getPackagePayment,
  getAllPackage,
  updateParent,
  updateStudentByParent,
  getAllPaymentData,
  postPaymentData,
  passwordCheck,
  getScoreExamStudent,
  getScoreQuizStudent,
};
