import { BASE_URL } from "../../constant";

const headersWithBearer = (token) => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
});

export async function updateStudent(body, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(body),
    })
  ).json();
}

export async function getAllLesson(token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/getalllesson`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getallchapter(id, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/getallchapter/${id}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function getAllSchedule(token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/getallschedule`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function passwordCheck(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/passwordCek`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function changePassword(data, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/changepassword`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify(data),
    })
  ).json();
}

export async function getAllQuiz(token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/getallquiz`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function startQuiz(idScore, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/startquiz/${idScore}`, {
      method: "PUT",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function continueQuiz(idClassQuiz, idQuiz, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/continue/${idQuiz}/${idClassQuiz}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function doneQuiz(data, idScore, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/donequiz/${idScore}`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify({ quizAnswer: data }),
    })
  ).json();
}

export async function getPembahasan(idScore, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/getresult/${idScore}`, {
      headers: headersWithBearer(token),
    })
  ).json();
}

// EXAM
export async function getAllExam(token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/getallexam`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function startExam(idScore, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/startexam/${idScore}`, {
      method: "PUT",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function continueExam(idClassExam, idQuiz, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/continueExam/${idQuiz}/${idClassExam}`, {
      method: "GET",
      headers: headersWithBearer(token),
    })
  ).json();
}

export async function doneExam(data, idScore, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/doneexam/${idScore}`, {
      method: "PUT",
      headers: headersWithBearer(token),
      body: JSON.stringify({ examAnswer: data }),
    })
  ).json();
}

export async function getPembahasanExam(idScore, token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/getresultExam/${idScore}`, {
      headers: headersWithBearer(token),
    })
  ).json();
}

// proteksi
export async function checkOnlineClass(token) {
  return await (
    await fetch(`${BASE_URL}/v1/student/checkonlineclass`, {
      headers: headersWithBearer(token),
    })
  ).json();
}