import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
// import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { NavDashboard } from "../../../components/Navbar";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableRow,
} from "../../../components/Table";
import { useCookies } from "../../../hooks/useCookies";

import { ContentDashboard } from "../../../layouts/Dashboard";
import {
  getScoreExamStudent,
  getScoreQuizStudent,
  getStudent,
} from "../../../service/parent/parentService";

import ReactToPrint from "react-to-print";

function Score() {
  const [activeTab, setActiveTab] = useState("quiz");
  const { id } = useParams();

  const rapotRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => rapotRef.current,
  // });

  return (
    <Fragment>
      <NavDashboard title="Murid" />
      <Tab setActiveTab={setActiveTab} activeTab={activeTab} />
      <ContentDashboard>
        <div className="flex justify-between items-center">
          <h1>Lihat Skor</h1>
          <div className="flex text-sm">
            <ReactToPrint
              trigger={() => <button> Print Rapot</button>}
              content={() => rapotRef.current}
              documentTitle="Rapot"
              bodyClass="w-full p-3"
            />
            <div className="hidden">
              <Rapot refRapot={rapotRef} />
            </div>
          </div>
        </div>

        <Detail activeTab={activeTab} id={id} />
      </ContentDashboard>
    </Fragment>
  );
}

function Tab({ setActiveTab, activeTab }) {
  return (
    <div className="flex my-4">
      <button
        className={`${
          activeTab === "quiz" ? "bg-blue text-white" : "bg-white"
        } bg-white p-2 mr-4 rounded hover:shadow`}
        onClick={() => setActiveTab("quiz")}
      >
        Skor Kuis
      </button>
      <button
        className={`${
          activeTab === "exam" ? "bg-blue text-white" : "bg-white"
        } bg-white p-2 rounded hover:shadow`}
        onClick={() => setActiveTab("exam")}
      >
        Skor Ujian
      </button>
    </div>
  );
}

function Detail({ activeTab, id }) {
  const [score, setScore] = useState([]);
  const { getCookie } = useCookies();
  const token = getCookie("token");

  const [detailStudent, setDetailStudent] = useState([]);

  useEffect(() => {
    const getScore = async () => {
      if (activeTab === "quiz") {
        const result = await getScoreQuizStudent(token, id);
        if (result.status === "Success") {
          setScore(result.data);
        }
      } else if (activeTab === "exam") {
        const result = await getScoreExamStudent(token, id);
        if (result.status === "Success") {
          setScore(result.data);
        }
      }
    };

    const getDetail = async () => {
      const result = await getStudent(token);
      setDetailStudent(
        result.data.student.filter((student) => student.id === Number(id))
      );
    };

    getDetail();
    getScore();
  }, [activeTab, token, id]);

  return (
    <Fragment>
      <div className="flex flex-col mt-6 text-sm">
        <span>Murid : {detailStudent[0]?.name}</span>
      </div>

      <div className="mt-6">
        <Table className="md:text-md text-xs">
          <TableHead className="md:flex hidden">
            <TableRow>
              <TableData>No</TableData>
              <TableData>Tanggal</TableData>
              <TableData>Mapel</TableData>
              <TableData>Tutor</TableData>
              <TableData>Nilai</TableData>
              <TableData>Keterangan</TableData>
            </TableRow>
          </TableHead>
          <TableBody>
            {score?.map((sc, id) => (
              <TableRow key={id} className="md:flex-row flex-col relative">
                <TableData className="md:block hidden">{id + 1}</TableData>
                <TableData className="md:relative absolute right-0 top-1 text-right md:text-left md:text-sm text-xs">
                  {sc.startTime?.split("T")[0]}
                </TableData>
                <TableData className="md:w-32 w-full">
                  <span className="md:hidden underline">Mapel:</span> {sc.name}
                </TableData>
                <TableData className="md:w-32 w-full">
                  <span className="md:hidden underline">Tutor:</span> {sc.tutor}
                </TableData>
                <TableData className="md:w-32 w-full">
                  <span className="md:hidden underline">Nilai:</span> {sc.score}
                </TableData>
                <TableData className="md:w-32 w-full">
                  <span className="md:hidden underline">Keterangan:</span>{" "}
                  {sc.status}
                </TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Fragment>
  );
}

function Rapot({ refRapot }) {
  const { id } = useParams();

  const [scoreQuiz, setScoreQuiz] = useState([]);
  const [scoreExam, setScoreExam] = useState([]);

  const { getCookie } = useCookies();
  const token = getCookie("token");

  const [detailStudent, setDetailStudent] = useState([]);

  useEffect(() => {
    const getScore = async () => {
      const resultScQuiz = await getScoreQuizStudent(token, id);
      const resultScExam = await getScoreExamStudent(token, id);

      setScoreQuiz(resultScQuiz.data);
      setScoreExam(resultScExam.data);
    };

    const getDetail = async () => {
      const result = await getStudent(token);
      setDetailStudent(
        result.data.student.filter((student) => student.id === Number(id))
      );
    };

    getDetail();
    getScore();
  }, [token, id]);

  return (
    <div ref={refRapot} className="w-full">
      <span className="name">Nama : {detailStudent[0]?.name}</span>
      <table className="table">
        <thead>
          <tr>
            <th className="th">No</th>
            <th className="th">Tanggal</th>
            <th className="th">Mapel</th>
            <th className="th">Nilai</th>
          </tr>
        </thead>
        <tbody>
          {scoreQuiz.map((score, id) => (
            <tr key={id}>
              <td className="td">{id + 1}</td>
              <td className="td">{score.startTime?.split("T")[0] ?? "-"}</td>
              <td className="td">{score.name}</td>
              <td className="td">{score.score}</td>
            </tr>
          ))}
          <tr>
            <td className="td" colspan={3}>
              Rata-Rata Kuis
            </td>
            <td className="td">
              {scoreQuiz
                .map((data) => data?.score)
                .reduce((acc, curr) => Number(acc) + Number(curr), 0) /
                scoreQuiz.length}
            </td>
          </tr>
        </tbody>
      </table>

      <span className="page-break"></span>

      <table className="table">
        <thead>
          <tr>
            <th className="th">No</th>
            <th className="th">Tanggal</th>
            <th className="th">Mapel</th>
            <th className="th">Nilai</th>
          </tr>
        </thead>
        <tbody>
          {scoreExam.map((exam, id) => (
            <tr key={id}>
              <td className="td">{id + 1}</td>
              <td className="td">{exam.startTime?.split("T")[0] ?? "-"}</td>
              <td className="td">{exam.name}</td>
              <td className="td">{exam.score}</td>
            </tr>
          ))}
          <tr>
            <td className="td" colspan={3}>
              Rata-Rata Ujian
            </td>
            <td className="td">
              {scoreExam
                .map((data) => data?.score)
                .reduce((acc, curr) => Number(acc) + Number(curr), 0) /
                scoreExam.length}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Score;
