import { Route } from 'react-router-dom'
import { lazy } from 'react'
import Loading from '../layouts/Loading'

// 
import Home from '../pages/Home'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import ForgotPassword from '../pages/ForgotPassword'

// Murid
import Dashboard from '../pages/users/Dashboard'
import Modul from '../pages/users/Modul'
import Quiz from '../pages/users/Quiz'
import Exam from '../pages/users/Exam'
import Class from '../pages/users/Class'
import Group from '../pages/users/Group'
import Profile from '../pages/users/Profile'
import EditProfile from '../pages/users/EditProfile'

// Parent 
import StudentsManageParent from '../pages/parent/student/StudentsManage'
import ParentDashboard from '../pages/parent/Dashboard'
import ScoreStudent from '../pages/parent/student/ScoreStudent'
import PackageStudent from '../pages/parent/student/PackageStudent'
import PaymentManageParent from '../pages/parent/payment/PaymentManage'
import EditParent from '../pages/parent/EditParent'
import EditStudent from '../pages/parent/student/EditStudent'
import AddStudent from '../pages/parent/student/AddStudent'

// Admin
import AdminDashboard from '../pages/admin/Dashboard'
import ClassManageAdmin from '../pages/admin/class/ClassManage'
import SchedulesClass from '../pages/admin/class/SchedulesClass'
import AddClass from '../pages/admin/class/AddClass'
import ModulManageAdmin from '../pages/admin/modul/ModulManage'
import AddModul from '../pages/admin/modul/AddModul'
import EditModul from '../pages/admin/modul/EditModul'
import ExamManageAdmin from '../pages/admin/exam/ExamManage'
import PackageManageAdmin from '../pages/admin/package/PackageManage'
import AddPackage from '../pages/admin/package/AddPackage'
import PromoManageAdmin from '../pages/admin/promo/PromoManage'
import EditPromo from '../pages/admin/promo/EditPromo'
import QuizManageAdmin from '../pages/admin/quiz/QuizManage'
import StudentsManageAdmin from '../pages/admin/student/StudentsManage'
import TeachersManageAdmin from '../pages/admin/teacher/TeachersManage'
import PaymentManageAdmin from '../pages/admin/payment/PaymentManage'
import EditQuiz from '../pages/admin/quiz/EditQuiz'
import AddQuiz from '../pages/admin/quiz/AddQuiz'
import AddExam from '../pages/admin/exam/AddExam'
import EditExam from '../pages/admin/exam/EditExam'
import AllAdmin from '../pages/admin/AllAdmin'
import AddAdmin from '../pages/admin/AddAdmin'
import DeleteData from '../pages/admin/DeleteData'

// teacher
import TeacherDashboard from '../pages/teacher/Dashboard'
import ExamTeacher from '../pages/teacher/Exam'
import QuizTeacher from '../pages/teacher/Quiz'
import OnlineClass from '../pages/teacher/OnlineClass'
import SignupTeacher from '../pages/teacher/Signup'
import GroupTeacher from '../pages/teacher/Group'
import ClassAndModul from '../pages/teacher/ClassAndModul'
import EditTeacher from '../pages/teacher/Profile/EditProfile'
import ProfileTeacher from '../pages/teacher/Profile'
import AddScheduleClass from '../pages/admin/class/AddScheduleClass'
import ResetPassword from '../pages/ResetPassword'

// NotFound
const PageNotFound = lazy(() => import('../pages/errors/404'))

const routes = [
    {
        path: "/",
        page: Home,
        exact: true
    },
    {
        path: "/login",
        page: Login,
        exact: true
    },
    {
        path: "/signup",
        page: Signup,
        exact: true
    },
    {
        path: "/signup-teacher",
        page: SignupTeacher,
    },
    {
        path: '/forgot-password',
        page: ForgotPassword,
        exact: true
    },
    {
        path: '/password/resetconfim/:token/:email',
        page: ResetPassword,
        exact: true
    },
    {
        path: "/dashboard",
        page: Dashboard,
        routes: [
            {
                path: "/dashboard/modul",
                page: Modul,
            },
            {
                path: "/dashboard/quiz",
                page: Quiz,
            },
            {
                path: "/dashboard/exam",
                page: Exam,
            },
            {
                path: "/dashboard/class",
                page: Class,
            },
            {
                path: "/dashboard/group",
                page: Group,
            },
            {
                path: "/dashboard/profile",
                page: Profile,
                exact: true,
            },
            {
                path: "/dashboard/profile/edit",
                page: EditProfile,
            }
        ]
    },
    {
        path: "/admin",
        page: AdminDashboard,
        routes: [
            {
                path: "/admin/class",
                page: ClassManageAdmin,
                exact: true
            },
            {
                path: "/admin/delete",
                page: DeleteData,
            },
            {
                path: "/admin/all",
                page: AllAdmin,
                exact: true
            },
            {
                path: "/admin/all/add",
                page: AddAdmin,
            },
            {
                path: "/admin/class/add",
                page: AddClass,
            },
            {
                path: "/admin/class/schedules",
                page: SchedulesClass,
                exact: true,
            },
            {
                path: "/admin/class/schedules/add",
                page: AddScheduleClass,
            },
            {
                path: "/admin/exam",
                page: ExamManageAdmin,
                exact: true
            },
            {
                path: "/admin/exam/add",
                page: AddExam,
            },
            {
                path: "/admin/exam/edit",
                page: EditExam,
            },
            {
                path: "/admin/modul",
                page: ModulManageAdmin,
                exact: true
            },
            {
                path: "/admin/modul/add",
                page: AddModul,
            },
            {
                path: "/admin/modul/edit",
                page: EditModul,
            },
            {
                path: "/admin/package",
                page: PackageManageAdmin,
                exact: true,
            },
            {
                path: "/admin/package/add",
                page: AddPackage,
            },
            {
                path: "/admin/payment",
                page: PaymentManageAdmin,
            },
            {
                path: "/admin/promo",
                page: PromoManageAdmin,
                exact: true
            },
            {
                path: "/admin/promo/edit",
                page: EditPromo,
            },
            {
                path: "/admin/quiz",
                page: QuizManageAdmin,
                exact: true,
            },
            {
                path: "/admin/quiz/edit",
                page: EditQuiz,
            },
            {
                path: "/admin/quiz/add",
                page: AddQuiz,
            },
            {
                path: "/admin/students",
                page: StudentsManageAdmin,
            },
            {
                path: "/admin/teachers",
                page: TeachersManageAdmin,
            },
        ],
    },
    {
        path: "/parent",
        page: ParentDashboard,
        routes: [
            {
                path: "/parent/edit",
                page: EditParent,
            },
            {
                path: "/parent/add-student",
                page: AddStudent,
            },
            {
                path: "/parent/payment",
                page: PaymentManageParent,
            },
            {
                path: "/parent/students",
                page: StudentsManageParent,
                exact: true,
            },
            {
                path: "/parent/students/edit",
                page: EditStudent,
            },
            {
                path: "/parent/students/package/:id",
                page: PackageStudent,
            },
            {
                path: "/parent/students/score/:id",
                page: ScoreStudent,
                exact: true
            },
        ]
    },
    {
        path: "/teacher",
        page: TeacherDashboard,
        routes: [
            {
                path: "/teacher/profile",
                page: ProfileTeacher,
                exact: true
            },
            {
                path: "/teacher/profile/edit",
                page: EditTeacher,
            },
            {
                path: "/teacher/signup",
                page: SignupTeacher
            },
            {
                path: "/teacher/quiz",
                page: QuizTeacher
            },
            {
                path: "/teacher/exam",
                page: ExamTeacher
            },
            {
                path: "/teacher/group",
                page: GroupTeacher
            },
            {
                path: "/teacher/modul",
                page: ClassAndModul
            },
            {
                path: "/teacher/online-class",
                page: OnlineClass
            },
        ]
    }
]

export function RouteWithSubRoute({ routes }) {
    return (
        <>
            {routes.map((route, id) => {
                return (
                    <Route exact={route.exact} path={route.path} key={id}>
                        <Loading>
                            <route.page {...route} />
                        </Loading>
                    </Route>
                )
            })}
        </>
    )
}

function RouteNotFound() {
    return (
        <Route>
            <Loading>
                <PageNotFound />
            </Loading>
        </Route>
    )
}

export {
    routes,
    RouteNotFound
}