import React from 'react';
import ReactDOM from 'react-dom';
import App from './pages/App';
import './tailwind.css'
import { ProviderSignupData } from './hooks/useSignup'
import ToggleProvider from './hooks/useToggle';
import { PaymentDataProvider } from './hooks/usePaymentData'
import HiddenProvider from './hooks/useHidden';
import ProviderModul from './hooks/useCategoryModul'
import ErrorBackendProvider from './hooks/useMessageBackend'

ReactDOM.render(
  <React.StrictMode>
    <ProviderSignupData>
      <ToggleProvider>
        <PaymentDataProvider>
          <HiddenProvider>
            <ProviderModul>
              <ErrorBackendProvider>
                <App />
              </ErrorBackendProvider>
            </ProviderModul>
          </HiddenProvider>
        </PaymentDataProvider>
      </ToggleProvider>
    </ProviderSignupData>
  </React.StrictMode>,
  document.getElementById('root')
);