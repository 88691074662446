import { Fragment, useState, useEffect } from "react"
import { NavDashboard } from "../../components/Navbar"
// import { Tab } from '../users/Quiz'
import { useToggle } from '../../hooks/useToggle'
import { PopupConfirmation } from '../../components/Popup'
import { MainDashboard } from "../../layouts/Dashboard"
import { useCookies } from '../../hooks/useCookies'
import { getAllSchedule } from '../../service/students/studentService'

function Class() {
  const { minimized } = useToggle()
  const [showPopup, setShowPopup] = useState(false)
  const [schedule, setSchedule] = useState([])
  const [link, setLink] = useState("")

  const { getCookie } = useCookies()
  const token = getCookie("token")

  useEffect(() => {
    const fetchSchedule = async () => {
      const result = await getAllSchedule(token)
      console.log(result)
      setSchedule(result.data)
    }
    fetchSchedule()
  }, [token])

  const handleClickCard = (link) => {
    setLink(link)
    setShowPopup(true)
  }

  const handleClickStartClass = (link) => {
    window.open(`${link}`, '_blank')
  }

  console.log(schedule)

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelas Online" />
        <PopupConfirmation onClickOK={() => handleClickStartClass(link)} open={showPopup} setOpen={setShowPopup} title="Anda yakin ingin mulai kelas sekarang?" textCancel="Tidak Sekarang" textOK="Mulai" />
        {/* <div className='mt-3'>
          <Tab />
        </div> */}
        <div className={`mb-6 grid ${minimized ? 'grid-cols-2' : 'md:grid-cols-3 grid-cols-2 one-column'} mt-6 md:gap-6 gap-3`}>
          {schedule.map(data => (
            <Card key={data.id} data={data} onClick={handleClickCard} />
          ))}
        </div>
      </MainDashboard>
    </Fragment>
  )
}

function Card({ onClick, data }) {
  const { minimized } = useToggle()

  return (
    <div onClick={() => onClick(data.link)} className={`flex hover:border-blue-200 border-2 relative ${minimized ? 'flex-row justify-center' : 'flex-col justify-center'} bg-white rounded-xl p-4`}>
    <div className={`md:w-44 w-32 ${minimized ? 'm-auto' : 'm-auto mb-6'}`}>
      <img className='w-full' src={`/assets/images/online_class_img.png`} alt="illutration" />
    </div>
    <div className={`flex flex-col justify-between ${minimized ? ' items-start mr-3 p-6' : 'md:text-center'}`}>
      <div className='flex flex-col'>
        <span className='txt-gray text-sm'>{data.mClass.name}</span>
        <h1 className='font-semibold md:text-base text-md mt-2'>{data.lesson.name} - {data.title}</h1>
      </div>

      <div className={`flex ${minimized ? 'flex-col' : 'flex-col md:items-center'} `}>
        <div className='flex text-sm md:mt-6 mt-2'>
          <span className=' md:text-sm text-xs flex'>Guru: {data.teacher.name}</span>
        </div>

        <div className='flex md:flex-col items-center justify-between'>
          <div className='flex text-sm md:mt-6 mt-3'>
            <div className='mr-3 md:block hidden'>
              <img src="/assets/icons/calendar.png" alt="date" />
            </div>
            <span className='font-medium md:text-base text-xs'>{data.date}</span>
          </div>

          <div className='flex text-sm md:mt-6 mt-3'>
            <div className='mr-3 md:block hidden'>
              <img src="/assets/icons/clock.png" alt="date" />
            </div>
            <span className='txt-blue md:text-base text-xs'>{data.startTime.split(':').slice(0, 2).join(':')} - {data.endTime.split(':').slice(0, 2).join(':')}</span>
          </div>
        </div>

        <div onClick={() => onClick(data.link)} className={`${minimized && 'absolute right-10 bottom-10'} p-2 rounded-md bg-blue mt-5 cursor-pointer md:block hidden`}>
          <img src="/assets/icons/arrow-right.png" alt="next" />
        </div>
      </div>
    </div>
  </div>
  )
}


export default Class