import { BASE_URL } from '../../constant'
import { parseJwt } from '../../utils/common/parseJWT'
import { expiredToken } from '../../utils/common/getExpiredToken'

async function loginService(data, setCookie, history, type) {
  console.log(data)
  const result = await (await fetch(`${BASE_URL}/v1/${type}/login`, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })).json()

  if (result.status === "success") {
    const parseToken = parseJwt(result.data.token)
    const expired = expiredToken(parseToken.exp)

    console.log(parseToken)

    setCookie('token', result.data.token, "/", true, expired)

    if (parseToken.user.role === "student") {
      return history.push('/dashboard/modul')
    } else if (parseToken.user.role === "parent") {
      return history.push('/parent/students')
    } else if (parseToken.user.role === "teacher") {
      return history.push('/teacher/modul')
    }

    return history.push('/admin/payment')
  }

  alert("Login gagal, pastikan email dan password benar.")
}

export { loginService }