function loadImage(image, callback) {
  const files = image.target.files[0];
  const reader = new FileReader();

  if(files.size >= 3000000) {
    return alert("Ukuran foto tidak boleh lebih dari 3mb")
  }

  reader.onload = function (e) {
    const blob = image.target.files[0].slice(0, image.target.files[0].size, 'image/png');
    const newFile = new File([blob], e.target.result)

    console.log(blob, newFile)

    callback(newFile)
  }

  if (files) {
    reader.readAsDataURL(files);
  } else {
    console.log("error")
  }
}

export { loadImage }