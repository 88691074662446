import { createContext, useContext, useState } from 'react'

const messageContext = createContext({})

function MessageProvider() {
  const [messageBackend, setMessageBackend] = useState({})

  const sendMessageFromBackend = (message) => {
    setMessageBackend(message)
  }

  return {
    messageBackend,
    sendMessageFromBackend
  }
}


export default function MessageBackendProvider({ children }) {
  const state = MessageProvider()
  return (
    <messageContext.Provider value={state}>
      {children}
    </messageContext.Provider>
  )
}

export const useMessageBackend = () => useContext(messageContext)