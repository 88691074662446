export const handleMoveCard = (cardRef, borderRef, iconTrashRef) => {
  cardRef.current.classList.add('bg-blue')
  cardRef.current.classList.remove('text-black')
  cardRef.current.classList.add('text-white')

  borderRef.current.classList.remove('border-gray-800')

  iconTrashRef.current.src = '/assets/icons/trash-white.png'
}

export const handleLeaveCard = (cardRef, borderRef, iconTrashRef) => {
  cardRef.current.classList.remove('text-white')
  cardRef.current.classList.remove('bg-blue')
  borderRef.current.classList.add('border-gray-800')

  iconTrashRef.current.src = '/assets/icons/trash-black.png'
}

export const handleClickTrash = (event, callback) => {
  event.stopPropagation()
  callback()
}