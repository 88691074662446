import { Fragment, useRef, useState } from "react"
import { NavDashboard } from "../../components/Navbar"
import { PopupDeleteConfirmation } from "../../components/Popup"
import { ContentDashboard, MainDashboard } from "../../layouts/Dashboard"
import { handleLeaveCard, handleMoveCard } from "../../utils/card"

function DeleteData() {
  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Hapus Data" />
        <ContentDashboard>
          <h1 className='text-xl font-medium'>Data</h1>
          <div className='grid md:grid-cols-3 gap-6 mt-6'>
            <Card />
          </div>
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  )
}

function Card() {
  const cardRef = useRef(), iconTrashRef = useRef(), borderRef = useRef()
  const [open, setOpen] = useState(false)

  const handleClickDelete = (event) => {
    event.stopPropagation()
    setOpen(true)
  }

  return (
    <Fragment>
      <PopupDeleteConfirmation open={open} setOpen={setOpen} text="Yakin ingin menghapus data ini" />
      <div className='text-black p-6 border rounded-xl flex flex-col cursor-pointer' ref={cardRef} onMouseMove={() => handleMoveCard(cardRef, borderRef, iconTrashRef)} onMouseLeave={() => handleLeaveCard(cardRef, borderRef, iconTrashRef)} >
        <div className='flex items-center justify-between'>
          <h1 className='text-lg font-medium'>Ujian Matematika</h1>
          <div className='cursor-pointer' onClick={handleClickDelete}>
            <img ref={iconTrashRef} src="/assets/icons/trash-black.png" alt="delete" />
          </div>
        </div>
        <div ref={borderRef} className='flex flex-col pb-4 border-b border-gray-800'>
          <p className='text-sm my-4 w-11/12'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Possimus, necessitatibus.</p>
          <span className='text-md font-medium'>50 Soal</span>
        </div>
        <div className='flex justify-between mt-4 text-xs'>
          <div className='flex flex-col'>
            <span>ID Pelajaran</span>
            <span>098989</span>
          </div>
          <div className='flex flex-col'>
            <span>ID Modul</span>
            <span>098989</span>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default DeleteData