import { useLocation, useRouteMatch } from 'react-router-dom'

function ActiveLink({ children, href }) {
  const { pathname } = useLocation()
  const match = useRouteMatch(pathname)

  const check = match.path.includes(href) ? 'txt-blue' : 'txt-gray'

  return (
    <div className={check}>
      {children}
    </div>
  )
}

export function ButtonActive({ children, href }) {
  const { pathname } = useLocation()
  const match = useRouteMatch(pathname)

  const check = match.path.includes(href) ? '' : 'filter grayscale'

  return (
    <div className={href === 'logout' ? '' : check}>
      {children}
    </div>
  )
}

export default ActiveLink