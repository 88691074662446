import { Fragment, useRef, useState, useEffect } from "react";
import { NavDashboard } from "../../../components/Navbar";
import { ContentDashboard, MainDashboard } from "../../../layouts/Dashboard";
import { handleLeaveCard, handleMoveCard } from "../../../utils/card";
import { PopupDeleteConfirmation } from "../../../components/Popup";
import { deleteExam, getAllExam } from "../../../service/admin/adminService";
import { useHistory } from "react-router-dom";
import { useCookies } from "../../../hooks/useCookies";

function ExamManage() {
  const history = useHistory();
  const [exam, setExam] = useState([]);
  const [hasDeleted, setHasDeleted] = useState(false);

  const { getCookie } = useCookies();
  const token = getCookie("token");

  const handleClickAdd = () => {
    history.push("/admin/exam/add");
  };

  const handleClickEdit = (examId) => {
    history.push(`/admin/exam/edit?id=${examId}`);
  };

  useEffect(() => {
    const getQuiz = async () => {
      const result = await getAllExam(token);
      setExam(result.data);
      setHasDeleted(false);
    };

    getQuiz();
  }, [token, hasDeleted]);

  console.log(exam);

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Ujian" />
        <ContentDashboard>
          <div className="flex justify-between items-center">
            <h1 className="text-xl font-medium">Data Ujian</h1>
            <div onClick={handleClickAdd}>
              <div className="p-2 rounded-md bg-blue cursor-pointer">
                <img src="/assets/icons/plus.png" alt="add" />
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-6 mt-6">
            {exam.map((data, id) => {
              return (
                <div key={id} onClick={() => handleClickEdit(data.id)}>
                  <Card
                    exam={data}
                    token={token}
                    setHasDeleted={setHasDeleted}
                  />
                </div>
              );
            })}
          </div>
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  );
}

function Card({ exam, token, setHasDeleted }) {
  const cardRef = useRef(),
    iconTrashRef = useRef(),
    borderRef = useRef();
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState();

  const handleClickDelete = (event, examId) => {
    event.stopPropagation();
    setOpen(true);
    setCurrentId(examId);
  };

  return (
    <Fragment>
      <PopupDeleteConfirmation
        open={open}
        setOpen={setOpen}
        text="Yakin ingin menghapus ujian ini"
        action={deleteExam}
        token={token}
        setState={() => setHasDeleted(true)}
        id={currentId}
        redirect="/admin/exam"
      />
      <div
        className="text-black p-6 border rounded-xl flex flex-col cursor-pointer"
        ref={cardRef}
        onMouseMove={() => handleMoveCard(cardRef, borderRef, iconTrashRef)}
        onMouseLeave={() => handleLeaveCard(cardRef, borderRef, iconTrashRef)}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-medium w-4/5">{exam.title}</h1>
          <div
            className="cursor-pointer"
            onClick={(event) => handleClickDelete(event, exam.id)}
          >
            <img
              ref={iconTrashRef}
              src="/assets/icons/trash-black.png"
              alt="delete"
            />
          </div>
        </div>
        <div
          ref={borderRef}
          className="flex flex-col pb-4 border-b border-gray-800"
        >
          <p className="text-sm my-4 w-11/12">{exam.description}</p>
          <span className="text-md font-medium">{exam.totalQuestion} Soal</span>
        </div>
        <div className="flex justify-between mt-4 text-xs">
          <div className="flex flex-col">
            <span>Pelajaran</span>
            <span>{exam.lesson}</span>
          </div>
          <div className="flex flex-col text-right">
            <span>Modul</span>
            <span>{exam.modul}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ExamManage;
