import Footer from "../components/Footer";
import Header from "../components/Header";
import About from "../components/About";
import CarouselDiscount from "../components/Discount";
// import Program from '../components/Program';
import Testimoni from "../components/Testimoni";
import Contact from "../components/Contact";
import Teacher from "../components/Teacher";
import { usePageTitle } from "../hooks/usePageTitle";
import { useEffect, useState } from "react";
import DashboardAdmin from "./admin/Dashboard";
import DashboardTeacher from "./teacher/Dashboard";
import { getCompanyProfile } from "../service/common";
import { routes } from "../routes";

function Homepage() {
  usePageTitle("Star Bimbel");
  const [subDomain, setSubDomain] = useState("");
  const [loading, setLoading] = useState(true);

  const [dataAtCompany, setDataAtCompany] = useState({
    promo: [],
    package: [],
  });

  console.log(dataAtCompany);

  useEffect(() => {
    const subDomain = window.location.host.split(".")[0];
    setSubDomain(subDomain);
    setLoading(false);

    const fetchData = async () => {
      const result = await getCompanyProfile();
      setDataAtCompany(result);
    };

    fetchData();
  }, []);

  if (subDomain === "admin") {
    return <DashboardAdmin routes={routes} />;
  } else if (subDomain === "teacher") {
    return <DashboardTeacher routes={routes} />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="md:px-20 px-4 py-8">
      <Header />
      <About />
      <CarouselDiscount promo={dataAtCompany.data} />
      <div className="my-20 text-center w-full" id="program">
        <button
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=6285710388785&text=Halo%20Star%20Bimbel%2C%20saya%20tertarik%20untuk%20melihat%20paket-paket%20yang%20tersedia%20apakah%20saya%20boleh%20meminta%20list%20harganya%3F%20Terima%20kasih",
              "blank"
            )
          }
          className="bg-blue p-4 text-white rounded-md"
        >
          Info Program dan Harga Klik disini
        </button>
      </div>
      {/* <Program data={dataAtCompany.package}/> */}
      <Testimoni />
      <Teacher />
      <Contact />
      <Footer />
    </div>
  );
}

export default Homepage;
