import { scrollToSection } from '../../utils/common/smoothScroll';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import { useHidden } from '../../hooks/useHidden'
// import { checkCurrentRole } from '../../utils/common/checkCurrentRole';
// import { useCookies } from '../../hooks/useCookies';

function Nav() {
    const [hiddenList, setHiddenList] = useState(true)

    const showListNav = () => {
        if (hiddenList) {
            return setHiddenList(false)
        }

        setHiddenList(true)
    }

    const scrollToSectionAndHiddenListNav = (scrollTo) => {
        setTimeout(() => setHiddenList(true), 1000)
        scrollToSection(scrollTo)
    }

    return (
        <nav className='flex md:flex-row flex-col md:static relative'>
            <div className='mr-12 md:w-auto w-16'>
                <img src="/assets/icons/logo.png" alt='logo' />
            </div>
            <div className='w-full flex items-center justify-between md:relative'>
                <ul className={`w-full ${hiddenList ? 'hidden transition' : 'animate-toBottom'} h-72 md:h-auto flex md:flex md:flex-row flex-col items-center lg:w-1/2 md:w-7/12 justify-around lg:text-base text-sm`}>
                    <li className='cursor-pointer w-max' onClick={() => scrollToSectionAndHiddenListNav('tentang')}>Tentang Kami</li>
                    <li className='cursor-pointer w-max' onClick={() => scrollToSectionAndHiddenListNav('program')}>Program</li>
                    <li className='cursor-pointer w-max' onClick={() => scrollToSectionAndHiddenListNav('testimoni')}>Testimoni</li>
                    <li className='cursor-pointer w-max' onClick={() => scrollToSectionAndHiddenListNav('guru')}>Guru</li>
                    <li className='cursor-pointer w-max' onClick={() => scrollToSectionAndHiddenListNav('kontak')}> Kontak</li>
                    <Link to='/login'>
                        <li className='md:hidden cursor-pointer w-max border-2 py-2 px-6 rounded-xl border-gray'>Login</li>
                    </Link>
                </ul>
                <div className='hidden md:flex items-center lg:text-base text-sm'>
                    <Link to='/login'>
                        <button className='mr-8'>Login</button>
                    </Link>
                    <Link to='/signup'>
                        <button className='bg-blue px-6 py-2 rounded-xl text-white'>Sign Up</button>
                    </Link>
                </div>
                <Hamburger handleClickHamburger={showListNav} className='absolute right-0 top-0 md:hidden' />
            </div>
        </nav>
    )
}

export function NavDashboard({ title, onSearch }) {
    const { setHidden, hidden } = useHidden()

    const handleClickHamburger = () => {
        if(hidden) {
            return setHidden(false)
        }

        setHidden(true)
    }

    // const {getCookie} = useCookies()
    // const token = getCookie("token")
    
    // const role = checkCurrentRole(token);

    return (
        <nav className='flex justify-between items-center relative pb-14 md:pb-14 lg:pb-0'>
            <h1 className='text-xl font-semibold'>{title}</h1>
            {/* <img className='w-12 lg:hidden cursor-pointer' src={`/assets/avatar/admin.png`} alt="profile" /> */}
            <div className='flex items-center'>
                <div className={`absolute lg:static lg:w-max w-3/5 left-0 bottom-0 w-full md:w-max flex md:flex items-center bg-white py-2 px-4 rounded-xl`}>
                    <div className='cursor-pointer'>
                        <img src="/assets/icons/search.png" alt="search-img" />
                    </div>
                    {/* {role === "teacher" && ( */}
                    <div className='ml-4'>
                        <input onKeyUp={onSearch} className='outline-none text-sm' type="search" placeholder="Search" />
                    </div>
                    {/* )} */}
                </div>
                {/* <div className={`md:absolute right-10 bottom-0 transform lg:scale-100 scale-90 md:right-0 md:bottom-0 lg:block ml-6 bg-white py-2 px-2 rounded-xl lg:relative cursor-pointer`}>
                    <img className='transform scale-75' src="/assets/icons/notif.png" alt="notif" />
                    <div className='w-4 h-4 rounded-xl bg-red-500 absolute top-1 right-1 flex justify-center items-center text-white text-xs'>2</div>
                </div> */}
                <div className='block ml-4 transform scale-75 lg:hidden'>
                    <Hamburger handleClickHamburger={handleClickHamburger}/>
                </div>
            </div>
        </nav>
    )
}

export function Hamburger({ handleClickHamburger, className = '' }) {
    return (
        <div className={`flex flex-col h-10 w-8 justify-evenly cursor-pointer ${className}`} onClick={handleClickHamburger}>
            <span className='bg-black h-0.5'></span>
            <span className='bg-black h-0.5'></span>
            <span className='bg-black h-0.5'></span>
        </div>
    )
}

export default Nav