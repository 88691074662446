/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { NavDashboard } from "../../components/Navbar";
import { PopupConfirmation } from "../../components/Popup";
import { useToggle } from "../../hooks/useToggle";
import { MainDashboard } from "../../layouts/Dashboard";
import { useQuery } from "../../hooks/useQuery";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import StartExam from "./StartExam";
import DetailExam from "./DetailExam";
import {
  continueExam,
  getAllExam,
  getPembahasanExam,
  startExam as startExamStudent,
} from "../../service/students/studentService";
import { useCookies } from "../../hooks/useCookies";

function Exam() {
  const { minimized, setHiddenSidebar } = useToggle();
  const [showPopup, setShowPopup] = useState(false);
  const [startExam, setStartExam] = useState(false);
  const id = useQuery("id");
  const type = useQuery("type");
  const detail = useQuery("detail");
  const history = useHistory();
  const countDownRef = useRef()

  const [activeExamId, setActiveExamId] = useState();
  const [currentIdScoring, setCurentIdScoring] = useState();
  const [examClassId, setExamClassId] = useState()

  const { getCookie } = useCookies();
  const token = getCookie("token");

  const [exam, setExam] = useState([]);
  const [dataStartExam, setDataStartExam] = useState([]);
  const [dataDetailExam, setDataDetailExam] = useState([])
  const [actionType, setActionType] = useState();
  const [hasCloseExam, setHasCloseExam] = useState(false)


  useEffect(() => {
    const getExam = async () => {
      const result = await getAllExam(token);
      console.log(result)
      setExam(result.data);
    };
    setHasCloseExam(false)

    getExam();
  }, [hasCloseExam, token]);

  useEffect(() => {
    if (id) {
      return setHiddenSidebar(true);
    }

    setHasCloseExam(true)
    return setHiddenSidebar(false);
  }, [id, setHiddenSidebar]);


  const handleClickCard = (data, type) => {
    setShowPopup(true);
    setCurentIdScoring(data.id);
    setActiveExamId(data.idExam);
    setExamClassId(data.idClassExam)
    setActionType(type);
  };

  console.log(currentIdScoring)

  const handleClickOKPopup = async (type) => {
    setStartExam(true);
    history.push(`/dashboard/exam?id=${activeExamId}`);

    if (type === "startExam") {
      const result = await startExamStudent(currentIdScoring, token);
      if (result.status === "Success") {
        setDataStartExam(result.data);
      } else if (result.status === "Failed" || result.status === "Error") {
        console.log("uuu");
        alert(result.message);
        return history.goBack();
      }
    }

    if (type === "continueExam") {
      const result = await continueExam(examClassId, activeExamId, token);
      console.log(result);
      if (result.status === "Success") {
        setDataStartExam(result.data);
      } else if (result.status === "Failed" || result.status === "Error") {
        console.log("uuu");
        alert(result.message);
        return history.goBack();
      }
    }

    setShowPopup(false);
  };

  if (startExam && id) {
    return (
      <StartExam
        idScoring={currentIdScoring}
        data={dataStartExam}
        popup={setShowPopup}
        token={token}
        countDownRef={countDownRef}
      />
    );
  }

  if (type === "done" && detail) {
    return <DetailExam data={dataDetailExam}/>;
  }

  return (
    <Fragment>
      <MainDashboard>
        <PopupConfirmation
          onClickOK={() => handleClickOKPopup(actionType)}
          open={showPopup}
          setOpen={setShowPopup}
          textCancel="Tidak Sekarang"
          textOK="Mulai"
          title="Anda yakin ingin mulai ujian sekarang"
        />
        <NavDashboard title="Ujian" />
        <div className="mt-6 mb-6">
          <Tab />
          <div
            className={`mt-6 grid ${
              minimized
                ? "grid-cols-3"
                : "md:grid-cols-1 grid-cols-2 one-column"
            } gap-3 md:gap-6`}
          >
            {(!type || type === "not-doing") && (
              <Fragment>
                {exam
                  .filter((exam) => exam.status === "belum mengerjakan")
                  .map((data) => {
                    return (
                      <CardRetangle
                        key={data.id}
                        data={data}
                        onClick={() =>
                          handleClickCard(data, "startExam")
                        }
                      />
                    );
                  })}
              </Fragment>
            )}
            {type === "doing" && (
              <Fragment>
                {exam
                  .filter((exam) => exam.status === "sedang mengerjakan")
                  .map((data) => {
                    return (
                      <CardRetangle
                        key={data.id}
                        data={data}
                        onClick={() =>
                          handleClickCard(data, "continueExam")
                        }
                      />
                    );
                  })}
              </Fragment>
            )}
            {type === "done" && (
              <Fragment>
                {exam
                  .filter((exam) => exam.status === "selesai")
                  .map((data) => {
                    return <CardRetangle token={token} key={data.id} done data={data} setDataDetailExam={setDataDetailExam}/>;
                  })}
              </Fragment>
            )}
          </div>
        </div>
      </MainDashboard>
    </Fragment>
  );
}

export function CardRetangle({ onClick = () => {}, exam, done, data, setDataDetailExam, token }) {
  const { minimized } = useToggle();
  const history = useHistory();
  const location = useLocation();

  const url = useMemo(() => location.pathname + location.search, []);

  const handleClickDetailPDF = async (id) => {
    history.push(`${url}&detail=${id}`);
    const result = await getPembahasanExam(id, token)
    if(result.status === "Success") {
      return setDataDetailExam(result.data)
    }
  };

  const startDate = {
    hours:
      String(new Date(data.startDate).getHours()).length === 1
        ? String(new Date(data.startDate).getHours()).padStart(2, 0)
        : String(new Date(data.startDate).getHours()),
    minutes:
      String(new Date(data.startDate).getMinutes()).length === 1
        ? String(new Date(data.startDate).getMinutes()).padStart(2, 0)
        : String(new Date(data.startDate).getMinutes()),
  };

  const endDate = {
    hours:
      String(new Date(data.endDate).getHours()).length === 1
        ? String(new Date(data.endDate).getHours()).padStart(2, 0)
        : String(new Date(data.endDate).getHours()),
    minutes:
      String(new Date(data.endDate).getMinutes()).length === 1
        ? String(new Date(data.endDate).getMinutes()).padStart(2, 0)
        : String(new Date(data.endDate).getMinutes()),
  };

  return (
    <div
      onClick={onClick}
      className={`flex hover:border-blue-200 border-2 ${
        minimized
          ? "flex-col justify-center"
          : "md:flex-row flex-col justify-center"
      } bg-white rounded-xl md:p-8 p-4`}
    >
      <div className={`md:w-44 w-32 ${minimized ? "m-auto" : "m-auto mb-6"}`}>
        <img
          className="w-full"
          src={`/assets/images/${exam ? "exam_img" : "quiz_img"}.png`}
          alt="illutration"
        />
      </div>
      <div
        className={`flex flex-col justify-between md:w-4/5 ${
          minimized ? "mt-12 text-center" : "md:pl-12"
        }`}
      >
        <div className="flex flex-col relative">
          <span className="txt-gray md:text-sm text-xs">{data.lesson}</span>
          <h1 className="font-semibold md:text-xl text-sm my-2">
            {data.title}
          </h1>
          <p className="md:text-sm text-xs md:mb-0 mb-3">{data.description}</p>
          {!done && (
            <p className="absolute right-0 md:top-0 text-xs">
              {data.startDate.split("T")[0]} s/d {data.endDate.split("T")[0]}
            </p>
          )}
        </div>

        <div
          className={`flex ${
            minimized
              ? "flex-col justify-center mt-6 items-center"
              : "md:flex-row flex-col items-start justify-between"
          } `}
        >
          {!done && (
            <Fragment>
              <div className="flex md:flex-col text-sm">
                <span
                  className={`txt-gray md:text-sm text-xs flex ${
                    minimized && "justify-center"
                  }`}
                >
                  Guru <span className="md:hidden block">:</span>{" "}
                </span>
                <span
                  className={`${
                    minimized ? "text-lg" : "md:text-sm text-xs md:ml-0 ml-2"
                  } font-medium`}
                >
                  {data.teacher}
                </span>
              </div>

              <div
                className={`${
                  minimized ? "flex-col" : "flex-row"
                } flex md:w-1/2 w-full md:mt-0 mt-3 justify-between`}
              >
                <div className={`flex flex-col text-sm ${minimized && "mt-3"}`}>
                  <span className="txt-gray md:block hidden">Waktu Mulai</span>
                  <span className="txt-blue">
                    {" "}
                    {startDate.hours}:{startDate.minutes} - {endDate.hours}:
                    {endDate.minutes}
                  </span>
                </div>
                <div className={`flex flex-col text-sm ${minimized && "mt-3"}`}>
                  <span className="txt-gray md:block hidden">Jumlah Soal</span>
                  <span className="font-medium">{data.totalQuestion} Soal</span>
                </div>
              </div>

              <div
                onClick={onClick}
                className={`p-2 rounded-md bg-blue cursor-pointer md:block hidden ${
                  minimized && "mt-6"
                }`}
              >
                <img src="/assets/icons/arrow-right.png" alt="next" />
              </div>
            </Fragment>
          )}

          {done && (
            <Fragment>
              <div
                className={`flex ${
                  minimized
                    ? "flex-col"
                    : "md:flex-row flex-col justify-between"
                } w-full`}
              >
                <h1
                  className={`md:text-2xl ${
                    minimized ? "mb-4" : ""
                  }  text-md font-medium`}
                >
                  Score {data.score}
                </h1>
                <button
                  className="bg-blue md:p-3 p-2 md:mt-0 mt-3 rounded-xl text-white md:text-sm text-xs md:w-auto w-full"
                  onClick={() => handleClickDetailPDF(data.id)}
                >
                  Pembahasan Soal
                </button>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export function Tab() {
  const history = useHistory();
  const match = useRouteMatch();
  const type = useQuery("type");

  const handleClickTab = (type) => {
    history.push(`${match.path}?type=${type}`);
  };

  return (
    <div className="flex w-full overflow-auto">
      <div
        onClick={() => handleClickTab("not-doing")}
        className="flex bg-white items-center w-44 md:w-max rounded-xl mr-3 cursor-pointer"
      >
        <div
          className={`${
            type === "not-doing" || !type ? "" : "grayscale filter"
          } pl-3 w-8`}
        >
          <img src="/assets/icons/bullet.png" alt="bullet" />
        </div>
        <span className="md:text-sm text-xs block md:p-3 p-2">
          Belum Mengerjakan
        </span>
      </div>
      <div
        onClick={() => handleClickTab("doing")}
        className="flex bg-white items-center w-44 md:w-max rounded-xl mr-3 cursor-pointer"
      >
        <div
          className={`${type === "doing" ? "" : "grayscale filter"} pl-3 w-8`}
        >
          <img src="/assets/icons/bullet.png" alt="bullet" />
        </div>
        <span className="md:text-sm text-xs block md:p-3 p-2">
          Sedang Mengerjakan
        </span>
      </div>
      <div
        onClick={() => handleClickTab("done")}
        className="flex bg-white items-center w-44 md:w-max rounded-xl mr-3 cursor-pointer"
      >
        <div
          className={`${
            type === "done" ? "" : "grayscale filter"
          } pl-3 w-7 md:w-8`}
        >
          <img src="/assets/icons/bullet.png" alt="bullet" />
        </div>
        <span className="md:text-sm text-xs block md:p-3 p-2">Selesai</span>
      </div>
    </div>
  );
}

export default Exam;
