function Alert({ children }) {
  return (
    <div className='fixed inset-0 bg-transparent flex justify-center items-center z-20'>
      {children}
    </div>
  )
}

export function AlertSuccess({ showAlert, onClickOK, text }) {
  if (showAlert) {
    return (
      <Alert>
        <div className='bg-white rounded-xl p-6'>
          <span>{text}</span>
          <div className='mt-6'>
            <button className='p-4 w-full bg-blue rounded-xl text-white' onClick={() => onClickOK(1)}>OK</button>
          </div>
        </div>
      </Alert>
    )
  }

  return <></>
}

export function AlertTimeOut({ showAlert, onClickOK, text }) {
  if (showAlert) {
    return (
      <Alert>
        <div className='bg-white rounded-xl p-6 w-4/5 md:w-2/6 text-center'>
          <span className='md:text-md text-sm text-center'>{text}</span>
          <div className='mt-6'>
            <button className='p-4 w-full bg-red-400 rounded-xl text-white md:text-md text-sm' onClick={onClickOK}>Selesai</button>
          </div>
        </div>
      </Alert>
    )
  }

  return <></>
}