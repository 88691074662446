import { useState } from "react"

function useEditType() {
  const [type, setType] = useState('profil')

  const changeEditTypeTo = (name) => {
    setType(name)
  }

  return {
    type,
    changeEditTypeTo
  }
}


export { useEditType }