import { Fragment, useEffect, useState } from "react"
import { NavDashboard } from "../../../components/Navbar"
import { AddClassForm } from '../../../components/Form/admin/class'
import { MainDashboard } from "../../../layouts/Dashboard"
import { getAllStudent, classCreate } from '../../../service/admin/adminService'
import { useCookies } from "../../../hooks/useCookies"

function AddClass() {
  const { getCookie } = useCookies()

  const [students, setStudents] = useState([])
  const token = getCookie("token")

  useEffect(() => {
    const fetchStudent = async () => {
      const result = await getAllStudent(token)
      setStudents(result.data)
    }

    fetchStudent()
  }, [token])

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Kelas" />
        <div className='mt-6'>
          <AddClassForm token={token} students={students} classCreate={classCreate}/>
        </div>
      </MainDashboard>
    </Fragment>
  )
}

export default AddClass