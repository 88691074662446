import { createContext, useContext, useState } from 'react'

const paymentDataContext = createContext({})

const initialState = {
  idStudent: "",
  name: "",
  total: "",
  status: "proses",
  bank: "",
  image: "",
  idPackage: []
}

export function Provider() {
  const [state, setState] = useState(initialState)
  const [stepPayment, setStepPayment] = useState(1)

  const onChangeDataPayment = (event) => {
    setState(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  const addPackageID = (newID) => {
    setState(prev => ({
      ...prev,
      idPackage: [
        ...prev.idPackage,
        newID
      ]
    }))
  }

  const removePackageID = (id) => {
    setState(prev => ({
      ...prev,
      idPackage: prev.idPackage.filter(currentID => currentID !== id)
    }))
  }

  const addImage = (fileName) => {
    setState(prev => ({
      ...prev,
      image: fileName
    }))
  }

  const addStudentID = (id) => {
    setState(prev => ({
      ...prev,
      idStudent: id
    }))
  }

  const resetDataPayment = () => {
    setState(initialState)
    setStepPayment(1)
  }

  return {
    state,
    stepPayment,
    eventListener: {
      onChangeDataPayment,
      addImage,
      addStudentID,
      addPackageID,
      removePackageID,
      resetDataPayment,
      setStepPayment,
    }
  }
}

export const PaymentDataProvider = ({ children }) => {
  const state = Provider()
  return (
    <paymentDataContext.Provider value={state}>
      {children}
    </paymentDataContext.Provider>
  )
}

export const usePaymentData = () => useContext(paymentDataContext)
