import { Fragment, useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { TitleForm } from "../.."
import { useMessageBackend } from "../../../../hooks/useMessageBackend"
import { createPackage } from "../../../../service/admin/adminService"
import TextRed from "../../../ErrorField"
import { Input, InputGroup } from "../../../Input"

export function AddPackageForm({ token }) {
  const [tingkatanKelas, setTingkatanKelas] = useState([])
  const [tingkatanEmpty, setTingkatanEmpty] = useState(false)
  const { register, handleSubmit } = useForm()
  const { sendMessageFromBackend, messageBackend } = useMessageBackend()
  const history = useHistory()

  const filterTingkatanKelas = Array.from(new Set(Array.from(new Set(tingkatanKelas)).join(',').toLowerCase().split(',')))

  const handleClickEnter = (event) => {
    if (event.code === "Space") {
      event.preventDefault()
    }

    if (event.key === "Enter") {
      event.preventDefault()
      const result = event.target.value.split(",")

      if (event.target.value !== "") {
        setTingkatanKelas(prev => [...prev, result.join(",")])
        setTingkatanEmpty(false)
      }
    }
  }

  const handleRemoveTingkatan = (value) => {
    setTingkatanKelas(filterTingkatanKelas.filter(tingkatan => tingkatan !== value))
  }

  const onSubmit = async (data) => {
    data.grades = filterTingkatanKelas.filter(tingkatan => tingkatan !== "").map(Number)
    console.log(data)
    if (tingkatanKelas.length === 0) {
      return setTingkatanEmpty(true)
    }
    const result = await createPackage(data, token)
    if (result.errors) {
      return sendMessageFromBackend(result.errors)
    }

    alert("Tambah paket berhasil")
    history.push('/admin/package')
  }

  return (
    <Fragment>
      <TitleForm iconClose hrefClose="/admin/package">Tambah Paket</TitleForm>
      <form className='mt-6 px-4' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex md:flex-row flex-col'>
          <InputGroup className='md:w-6/12 md:pr-4 relative'>
            <label className='mb-2 text-sm' htmlFor="id_type" >Jenis Paket</label>
            <select defaultValue="" {...register('id_type')} id="id_type" className='text-sm outline-none border-gray p-3 rounded-2xl'>
              <option value="" disabled hidden>Pilih Paket</option>
              <option value="1">Berkelompok</option>
              <option value="2">Privat</option>
              <option value="3">Regular</option>
            </select>
            {messageBackend.id_type && <TextRed>{messageBackend.id_type.message}</TextRed>}
          </InputGroup>
          <InputGroup className='md:w-6/12 md:pl-4' >
            <label className='mb-2 text-sm' htmlFor="classtype">Tipe Kelas</label>
            <select defaultValue="" {...register('classtype')} id="classtype" className='text-sm outline-none border-gray p-3 rounded-2xl'>
              <option value="" disabled hidden>Pilih Tipe Kelas</option>
              <option value="online">Online</option>
              <option value="offline">Offline</option>
              <option value="aplikasi">Aplikasi</option>
              <option value="aplikasi+pendaftaran">Aplikasi + Pendaftaran</option>
            </select>
            {messageBackend.classtype && <TextRed>{messageBackend.classtype.message}</TextRed>}
          </InputGroup>
        </div>

        <div className='flex md:flex-row flex-col'>
          <InputGroup className='md:w-6/12 md:pr-4 relative'>
            <label className='mb-2 text-sm' htmlFor="price" >Harga</label>
            <Input type="number" register={register('price')} id="price" placeholder="Masukan Harga Paket" />
            {messageBackend.price && <TextRed>{messageBackend.price.message}</TextRed>}
          </InputGroup>
          <InputGroup className='md:w-6/12 md:pl-4' >
            <label className='mb-2 text-sm' htmlFor="meet">Pertemuan</label>
            <Input type="number" register={register('meet')} id="meet" placeholder="Masukan Banyak Pertemuan" />
            {messageBackend.meet && <TextRed>{messageBackend.meet.message}</TextRed>}
          </InputGroup>
        </div>

        <div className='flex md:flex-row flex-col'>
          <InputGroup className='md:w-6/12 md:pr-4 relative'>
            <label className='mb-2 text-sm' htmlFor="time" >Waktu Pembelajaran</label>
            <Input register={register('time')} id="time" placeholder="Masukan Waktu Lama Pembelajaran" />
            {messageBackend.time && <TextRed>{messageBackend.time.message}</TextRed>}
          </InputGroup>
          <InputGroup className='md:w-6/12 md:pl-4' >
            <label className='mb-2 text-sm' htmlFor="per">Pilih Jangka Waktu</label>
            <select defaultValue="" {...register('per')} id="per" className='text-sm outline-none border-gray p-3 rounded-2xl'>
              <option value="" disabled hidden>Pilih Jangka Waktu</option>
              <option value="bulan">Bulan</option>
              <option value="tahun">Tahun</option>
            </select>
            {messageBackend.per && <TextRed>{messageBackend.per.message}</TextRed>}
          </InputGroup>
        </div>

        <InputGroup>
          <label className='mb-1 text-sm' htmlFor="waktuPembelajaran" >Tingkatan Kelas</label>
          <span className='text-xs text-red-500 mb-3'>Pisahkan tiap2 tingkatan dengan koma!</span>
          <textarea {...register('grades')} defaultValue="" className='border-gray rounded-2xl border-none outline-none p-3' rows={5} onKeyDown={handleClickEnter} />
        </InputGroup>

        <div className='mt-4 text-sm flex flex-col'>
          <span>Tingkatan yang dipilih</span>
          {tingkatanEmpty && <TextRed>Anda belum mengisi tingkatan kelas. harap diisi!</TextRed>}
          <div className='flex mt-2'>
            {filterTingkatanKelas?.map((tingkatan, id) => {
              return (
                <Fragment key={id}>
                  {tingkatan && (
                    <div className='py-2 px-6 bg-gray-200 w-max rounded-md mr-2 flex relative' key={id}>
                      {tingkatan}
                      <i onClick={() => handleRemoveTingkatan(tingkatan)} className='bg-no-repeat flex items-center justify-center bg-center rounded-xl transform scale-75 p-3 bg-white absolute shadow-md cursor-pointer -right-1 -top-1' style={{ backgroundImage: "url('/assets/icons/exit.png')" }}></i>
                    </div>
                  )}
                </Fragment>
              )
            })}
          </div>
        </div>

        <div className='w-full flex justify-end mt-12'>
          <button className='bg-blue px-8 py-3 text-white rounded-xl'>Tambah</button>
        </div>
      </form>
      <span>
      </span>
    </Fragment>
  )
}