import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { FlexColw50, Title } from '../Section';

function CarouselDiscount({ promo }) {
    return (
        <Carousel className='mt-8' autoPlay showStatus={false} showArrows={false} infiniteLoop={false} interval={5000} showThumbs={false}>
            {promo?.map((pmo, id) => <Discount key={id} {...pmo} />)}
        </Carousel>
    )
}

function Discount(promo) {
    // const discount = {
    //     package1: promo?.package1cutoff ? ((promo.package1price - promo.package1cutoff) / promo.package1price) * 100 : promo.package1price,
    //     package2: promo?.package2cutoff ? ((promo.package2price - promo.package2cutoff) / promo.package2price) * 100 : promo.package2price
    // }

    // console.log(discount)

    return (
        <div className='md:h-96 h-max bg-lightorange rounded-3xl flex md:justify-center overflow-hidden'>
            <FlexColw50 className='mt-3 md:my-auto text-left w-3/6 z-10'>
                <div className='m-auto md:pr-8 px-8 md:ml-2'>
                    <Title><span className='txt-dark'>{promo.header}</span></Title>
                    <span className='lg:text-2xl md:text-md text-sm'>#{promo.name}</span>
                </div>
                <div className='md:ml-9 ml-8 flex lg:flex-row w-max md:w-max flex-col bg-white rounded-xl p-4 md:mt-4 mt-4 ' >
                    <div className={`${promo.package2 && 'lg:border-r'} w-max`}>
                        <span className='txt-orange'>{promo.package1}</span>
                        <div className='mt-1 flex lg:flex-row flex-col justify-between w-full lg:pr-4 txt-dark'>
                            <span>Rp{promo.package1cutoff?.toLocaleString()}/Bulan</span>
                            <span className='lg:pl-2 relative'>
                                Rp{promo.package1price?.toLocaleString()}/Bulan
                                <div className='absolute w-full h-0.5 bg-red-400 top-0 right-0 transform rotate-6 origin-center translate-y-3'></div>
                            </span>
                        </div>
                    </div>
                    {promo.package2 && (
                        <div className='lg:pl-4 lg:pt-0 pt-6 w-max'>
                            <span className='txt-orange'>{promo.package2}</span>
                            <div className='mt-1 flex lg:flex-row flex-col justify-between w-full txt-dark'>
                                <span>Rp{promo.package2cutoff?.toLocaleString()}/Bulan</span>
                                <span className='lg:pl-2 relative'>
                                    Rp{promo.package2price?.toLocaleString()}/Bulan
                                    <div className='absolute w-full h-0.5 bg-red-400 top-0 right-0 transform rotate-6 origin-center translate-y-3'></div>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </FlexColw50>
            <FlexColw50 className='items-end lg:w-2/5 md:w-1/2 w-1/5 md:right-0 right-28 relative'>
                <div className='h-full w-max relative md:bottom-0 -bottom-20'>
                    <img loading='lazy' src='/assets/images/promo.png' className='transform md:scale-100 scale-75' alt='Promo' />
                </div>
                <button className='bg-pink absolute w-max h-max -right-40 bottom-10 md:right-20 md:bottom-20 text-white p-4 rounded-r-md rounded-bl-md md:text-base text-xs'>Coba Sekarang</button>
            </FlexColw50>
        </div>
    )
}

export default CarouselDiscount