import { useState } from 'react';
import { Title, SubTitle } from '../Section';

function About() {
    const [moreAbout, setMoreAbout] = useState(false)

    const handleClickMore = () => {
        setMoreAbout(true)
    }

    return (
        <section id="tentang" className='grid lg:grid-cols-2 mb-24'>
            <div className='order-2 md:text-left text-center'>
                <span className='w-max rounded-md'>Tentang Kami</span>
                <Title marginTop='md:mt-8 mt-2'>Apa yang harus anda <span className='txt-blue'>Ketahui?</span></Title>
                <SubTitle marginTop='md:mt-8 mt-4'>Star Bimbel merupakan bimbingan belajar untuk jenjang SD, SMP dan SMA dengan tatap muka online dan off line yang berprinsip kesuksesan adalah jumlah dari upaya kecil, yang diulangi dari hari ke hari.</SubTitle>
                {moreAbout && (
                    <div className='mt-2'>
                        <SubTitle marginTop='mt-4'>Pendidikan adalah paspor untuk masa depan, karena hari esok adalah milik mereka yang mempersiapkannya hari ini. </SubTitle>
                        <SubTitle marginTop='mt-4'>“Mari Belajar dengan Bahagia dan Tingkatkan Prestasi Bersama Star Bimbel”</SubTitle>
                        <span className='mt-4 block font-semibold text-black'>Metode Belajar</span>
                        <SubTitle marginTop='mt-2'>Berlatih Smart, Solusi Smart</SubTitle>
                    </div>
                )}
                {!moreAbout && <button className='btn btn-blue mt-10 md:text-base text-sm' onClick={handleClickMore}>Lihat Lebih Banyak</button>}
            </div>
            <div className='hidden lg:order-1 lg:flex'>
                <div className='-mt-20'>
                    <img src='/assets/images/about_img.png' alt='hero' />
                </div>
            </div>

            {moreAbout && (
                <>
                    <div className='flex mt-6 flex-col row-start-2 col-start-1 row-end-4 lg:col-end-3 col-end-2'>
                        <div className='grid lg:grid-cols-3 gap-6'>
                            <div className='xl:w-full lg:w-full p-6 rounded-xl bg-lightblue-2'>
                                <h4 className='mb-4 txt-dark font-semibold text-lg'>Target Sukses</h4>
                                <ul className='list-disc px-8 border-box'>
                                    <li>Penilaian Harian</li>
                                    <li>Penilaian Tengah Semester</li>
                                    <li>Penilaian Akhir Tahun</li>
                                    <li>SNMPTN dan SBMPTN</li>
                                </ul>
                            </div>
                            <div className='xl:w-full lg:w-full p-6 rounded-xl bg-lightblue-2'>
                                <h4 className='mb-4 txt-dark font-semibold text-lg'>Fasilitas One Stop Service Application</h4>
                                <ul className='list-disc px-8 border-box'>
                                    <li>Try Out Online</li>
                                    <li>Modul Online (Materi dan Pembahasan)</li>
                                    <li>Klinik PR Online</li>
                                </ul>
                            </div>
                            <div className='xl:w-full lg:w-full p-6 rounded-xl bg-lightblue-2'>
                                <h4 className='mb-4 txt-dark font-semibold text-lg'>Sistem Belajar</h4>
                                <ul className='list-disc px-8 border-box'>
                                    <li>Online dan Offline</li>
                                    <li>Privat (1-2 Siswa)</li>
                                    <li>Berkelompok (5-9 Siswa)</li>
                                    <li>Reguler (10-15 Siswa)</li>
                                </ul>
                            </div>
                        </div>
                        <div className='text-center mt-6'>
                            <button className='btn btn-blue md:text-base text-sm' onClick={() => setMoreAbout(false)}>Lihat Lebih Sedikit</button>
                        </div>
                    </div>
                </>
            )}
        </section>
    )
}

export default About
