import { Fragment, useEffect, useRef, useState } from "react"
import { NavDashboard } from "../../../components/Navbar"
import { PopupDeleteConfirmation, PopupInput, PopupPreviewEditClass } from '../../../components/Popup'
import { Link, useHistory } from "react-router-dom"
import { MainDashboard } from "../../../layouts/Dashboard"
import { useQuery } from '../../../hooks/useQuery'
import { useForm, Controller } from "react-hook-form"
import { InputGroup } from "../../../components/Input"
import Select from 'react-select'
import TextRed from "../../../components/ErrorField"
import { CONSTANT } from "../../../constant"
import { getAllStudent, getAllClassDetail, studentUpdate, deleteSchedule, getAllTeacher, scheduleUpdate, getAllLessonSchedule } from "../../../service/admin/adminService"
import { useCookies } from "../../../hooks/useCookies"

function SchedulesClass() {
  const [showPopup, setShowPopup] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [currentScheduleId, setCurrentScheduleId] = useState()
  const [currentPreview, setCurrentPreview] = useState()
  const [hasDeleted, setHasDeleted] = useState(false)
  const [hasUpdated, setHasUpdated] = useState(false)
  const [teachers, setTeachers] = useState([])

  const { getCookie } = useCookies()
  const token = getCookie("token")

  const history = useHistory()
  const id = useQuery("id")
  const [schedules, setSchedules] = useState([])
  const [lessonSchedule, setLessonSchedule] = useState([])

  useEffect(() => {
    const getAllSchedules = async () => {
      const teachers = await getAllTeacher(token)
      const result = await getAllClassDetail(id, token)
      const lessonSchedule = await getAllLessonSchedule(token)

      setSchedules(result.data)
      setTeachers(teachers.data)
      setLessonSchedule(lessonSchedule.data)

      setHasDeleted(false)
      setHasUpdated(false)
    }

    getAllSchedules()
  }, [token, id, hasDeleted, hasUpdated])

  const handleClickScheduleCardClass = (scheduleId) => {
    setCurrentPreview(schedules.schedules.filter(data => data.id === scheduleId))
    setShowPreview(true)
  }

  console.log(currentPreview)

  const handleClickAddJadwal = () => {
    history.push(`/admin/class/schedules/add?id=${id}`)
  }

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Kelas" />
        <PopupDeleteConfirmation setState={() => setHasDeleted(true)} redirect={`/admin/class/schedules?id=${id}`} token={token} id={currentScheduleId} action={deleteSchedule} open={showPopup} setOpen={setShowPopup} text="Yakin ingin menghapus Jadwal ini" />
        <PopupPreviewEditClass lessonSchedule={lessonSchedule} setState={() => setHasUpdated(true)} token={token} action={scheduleUpdate} teachers={teachers} data={currentPreview} open={showPreview} setOpen={setShowPreview} />
        <div className='flex md:flex-row flex-col mt-6'>
          <div className='md:w-3/5 bg-white rounded-xl p-6 md:mr-6 md:mb-0 mb-6'>
            <div>
              <CardTop schedules={schedules} />
            </div>

            <div className='mt-6'>
              <div className='flex justify-between'>
                <h1 className='text-2xl font-medium'>Jadwal Kelas</h1>
                <div className='bg-blue p-2 rounded-xl w-max cursor-pointer' onClick={handleClickAddJadwal}>
                  <img src="/assets/icons/plus.png" alt="add" />
                </div>
              </div>
              <div className='grid md:grid-cols-2 gap-6 mt-6'>
                {schedules?.schedules?.map((schedule, id) => {
                  return (
                    <div key={id} onClick={() => handleClickScheduleCardClass(schedule.id)}>
                      <CardScheduleClass setCurrentScheduleId={setCurrentScheduleId} schedule={schedule} setOpen={setShowPopup} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <ListStudent schedule={schedules?.students} idClass={id} />
        </div>
      </MainDashboard>
    </Fragment>
  )
}

function CardScheduleClass({ setOpen, schedule, setCurrentScheduleId }) {
  const cardRef = useRef(), iconTrashRef = useRef(), borderRef = useRef()
  const handleMoveCard = () => {
    cardRef.current.classList.add('bg-blue')
    cardRef.current.classList.remove('text-black')
    cardRef.current.classList.add('text-white')

    borderRef.current.classList.remove('border-gray-800')

    iconTrashRef.current.src = '/assets/icons/trash-white.png'
  }

  const handleLeaveCard = () => {
    cardRef.current.classList.remove('text-white')
    cardRef.current.classList.remove('bg-blue')
    borderRef.current.classList.add('border-gray-800')

    iconTrashRef.current.src = '/assets/icons/trash-black.png'
  }

  const handleClickTrash = (event, currenScheduleId) => {
    event.stopPropagation()
    setCurrentScheduleId(currenScheduleId)
    setOpen(true)
  }

  return (
    <div ref={cardRef} className='cursor-pointer rounded-2xl p-5 border border-gray text-black' onMouseMove={handleMoveCard} onMouseLeave={handleLeaveCard}>
      <div className='flex text-xs items-center justify-between'>
        <span className=''>{schedule.title}</span>
        <div className='cursor-pointer p-2' onClick={(event) => handleClickTrash(event, schedule.id)}>
          <img ref={iconTrashRef} src="/assets/icons/trash-black.png" alt="delete" />
        </div>
      </div>
      <div ref={borderRef} className='flex flex-col mt-2 pb-4 border-b border-gray-800 mb-3'>
        <h1 className='text-xl font-medium'>{schedule.lesson.name} </h1>
        <span className='text-sm'>{schedule.teacher.name}</span>
      </div>
      <div className='text-xs flex justify-between'>
        <span>{schedule.date}</span>
        <span>{schedule.startTime.split(":").slice(0, 2).join(":")} - {schedule.endTime.split(":").slice(0, 2).join(":")}</span>
      </div>
    </div>
  )
}

function ListStudent({ schedule, idClass }) {
  const [showPopup, setShowPopup] = useState(false)
  const { control, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory()

  const { getCookie } = useCookies()
  const [students, setStudents] = useState([])
  const token = getCookie("token")

  useEffect(() => {
    const fetchStudent = async () => {
      const result = await getAllStudent(token)
      setStudents(result.data)
    }

    fetchStudent()
  }, [token])

  const studentsOptions = students.map(student => ({ // all student
    value: student.id,
    label: student.name
  }))

  const onSubmit = async (data) => {
    data.idClass = idClass

    const result = await studentUpdate(data, token)
    if (result.status === "Success") {
      alert("Ubah Murid Berhasil")
      return history.push('/admin/class')
    }
  }

  const schedulesStudentsOptions = schedule?.map(student => ({
    value: student.id,
    label: student.name
  }))

  if (!schedulesStudentsOptions) {
    return <div>Loading..</div>
  }

  const findStudentHasRegisteredClass = (student) => {
    return schedulesStudentsOptions.find(studentHasRegistered => studentHasRegistered.value === student.value)
  }

  const filteredOptions = studentsOptions.filter(student => {
    const result = findStudentHasRegisteredClass(student)?.value
    return student.value !== result
  })

  return (
    <Fragment>
      <PopupInput open={showPopup} setOpen={setShowPopup} title="Tambahkan Murid" text="Nama Murid" />
      <form autoComplete="off" className='md:w-2/5 ' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full'>
          <div className='bg-white p-6 rounded-xl'>
            <h2 className='text-2xl font-medium'>List Murid</h2>
            <span className='text-sm mt-2'>Tambah Murid</span>
            <div className='grid grid-cols-1'>
              <InputGroup className='w-full'>
                <label className="mb-2 text-sm" htmlFor="murid">Nama Murid</label>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="idStudent"
                  defaultValue={schedulesStudentsOptions.map(data => data.value)}
                  render={({ field: { onChange } }) => (
                    <Select defaultValue={schedulesStudentsOptions} options={filteredOptions} onChange={val => onChange(val.map(c => c.value))} isMulti />
                  )}
                />
                {errors.idStudent && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>
            </div>
            <div className='flex justify-end mt-4'>
              <button className='p-4 bg-blue text-white rounded-xl'>Simpan</button>
            </div>
          </div>

          <Link to='/admin/class'>
            <button className='p-4 bg-blue text-white rounded-xl mr-4 mt-6 w-full'>Kembali</button>
          </Link>
        </div>
      </form>
    </Fragment>
  )
}

export function List() {
  const [showPopup, setShowPopup] = useState(false)
  return (
    <Fragment>
      <PopupDeleteConfirmation open={showPopup} setOpen={setShowPopup} text="Yakin ingin menghapus murid ini" />
      <div className="flex items-center border-b-2 pb-4 relative">
        <input type="checkbox" className="mr-4" />
        <div className='w-14 h-14 mr-4'>
          <img src="/assets/avatar/default.png" className='w-full' alt="profile" />
        </div>
        <div className='flex flex-col'>
          <span>Andre Septian</span>
          <span className='text-sm'>Kelas 1 SD</span>
        </div>
        <div className='filter w-5 cursor-pointer grayscale absolute right-0 top-0' onClick={() => setShowPopup(true)}>
          <img src="/assets/icons/btn-trash.png" className='w-full' alt="delete" />
        </div>
      </div>
    </Fragment>
  )
}

function CardTop({ schedules }) {
  return (
    <div className='rounded-2xl p-5 bg-blue-100 text-black'>
      <div className='flex text-xs items-center justify-between'>
        <span className='text-md'>{schedules?.name} {schedules?.classGrade}</span>
      </div>
      <div className='flex flex-col mt-2 pb-2 border-b border-gray-800 mb-3'>
        <h1 className='text-3xl font-medium'>Kelas {schedules?.classGrade}</h1>
      </div>
      <div className='text-sm'>
        <span>Jumlah Siswa {schedules?.ammount > 1 ? `1 - ${schedules?.ammount}` : `${schedules?.ammount}`} Orang</span>
      </div>
    </div>
  )
}

export default SchedulesClass