import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { NavDashboard } from '../../../components/Navbar'
import { PopupDeleteConfirmation } from '../../../components/Popup'
import { MainDashboard } from '../../../layouts/Dashboard'
import { useCookies } from "../../../hooks/useCookies"
import { getAllClass, deleteClass } from '../../../service/admin/adminService'

function ClassManage() {
  const [showPopup, setShowPopup] = useState(false)
  const history = useHistory()

  const { getCookie } = useCookies()
  const token = getCookie("token")

  const [classData, setClassData] = useState([])
  const [hasDeleteClass, setHasDeleteClass] = useState(false)
  const [currentIdClass, setCurrentIdClass] = useState([])

  const fetchAllClass = useCallback(async () => {
    const response = await getAllClass(token)
    setClassData(response.data)
    setHasDeleteClass(false)
  }, [token])

  useEffect(() => {
    fetchAllClass()
  }, [fetchAllClass, hasDeleteClass])
  
  const handleClickCardClass = (idClass) => {
    history.push(`/admin/class/schedules?id=${idClass}`)
  }

  return (
    <Fragment>
      <MainDashboard>
      <PopupDeleteConfirmation setState={() => setHasDeleteClass(true)} redirect="/admin/class" token={token} id={currentIdClass} action={deleteClass} open={showPopup} setOpen={setShowPopup} text="Yakin ingin menghapus Kelas ini" />
        <NavDashboard title="Kelola Kelas" />
        <div className='flex md:flex-row flex-col justify-between mt-6'>
          <div className='w-full bg-white rounded-xl p-5'>
            <div className='flex justify-between items-center'>
              <h1 className='text-xl font-medium'>Data Kelas</h1>
              <div className='flex items-center'>
                <Link to="/admin/class/add">
                  <div className='bg-blue p-2 rounded-xl cursor-pointer ml-6'>
                    <img src="/assets/icons/plus.png" alt="add-modul" />
                  </div>
                </Link>
              </div>
            </div>

            <span>{classData.length} Kelas</span>
            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-5 mt-4'>
              {classData.map(data => {
                return (
                  <Fragment key={data.id}>
                    <div onClick={() => handleClickCardClass(data.id)}>
                      <CardClass data={data} setOpen={setShowPopup} setCurrentIdClass={setCurrentIdClass}/>
                    </div>
                  </Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </MainDashboard>
    </Fragment>
  )
}

function CardClass({ setOpen, data, setCurrentIdClass }) {
  const cardRef = useRef(), iconTrashRef = useRef(), borderRef = useRef()
  const handleMoveCard = () => {
    cardRef.current.classList.add('bg-blue')
    cardRef.current.classList.remove('text-black')
    cardRef.current.classList.add('text-white')

    borderRef.current.classList.remove('border-gray-800')

    iconTrashRef.current.src = '/assets/icons/trash-white.png'
  }

  const handleLeaveCard = () => {
    cardRef.current.classList.remove('text-white')
    cardRef.current.classList.remove('bg-blue')
    borderRef.current.classList.add('border-gray-800')

    iconTrashRef.current.src = '/assets/icons/trash-black.png'
  }

  const handleClickTrash = (event) => {
    event.stopPropagation()
    setCurrentIdClass(data.id)
    setOpen(true)
  }

  return (
    <div ref={cardRef} className='cursor-pointer rounded-2xl p-5 border border-gray text-black' onMouseMove={handleMoveCard} onMouseLeave={handleLeaveCard}>
      <div className='flex text-xs items-center justify-between'>
        <span className=''>{data.name} {data.classGrade}</span>
        <div className='cursor-pointer p-2' onClick={handleClickTrash}>
          <img ref={iconTrashRef} src="/assets/icons/trash-black.png" alt="delete" />
        </div>
      </div>
      <div ref={borderRef} className='flex flex-col mt-2 border-gray-800 mb-3'>
        <h1 className='text-xl font-medium'>Kelas {data?.classGrade}</h1>
        <span className='text-sm'>Siswa {data?.ammount > 1 ? `1 - ${data?.ammount}` : `${data?.ammount}`} Orang</span>
      </div>
    </div>
  )
}

export default ClassManage