import { Fragment, useRef, useState, useEffect } from "react";
import { NavDashboard } from "../../../components/Navbar";
import { ContentDashboard, MainDashboard } from "../../../layouts/Dashboard";
import { handleLeaveCard, handleMoveCard } from "../../../utils/card";
import { PopupDeleteConfirmation } from "../../../components/Popup";
import { deleteQuiz, getAllQuiz } from "../../../service/admin/adminService";
import { useHistory } from "react-router-dom";
import { useCookies } from "../../../hooks/useCookies";
// import Select from 'react-select'
// import { useForm, Controller } from "react-hook-form"

function QuizManage() {
  const history = useHistory();
  const [quiz, setQuiz] = useState([]);
  const [hasDeleted, setHasDeleted] = useState(false);
  // const { control } = useForm()

  const { getCookie } = useCookies();
  const token = getCookie("token");

  const handleClickAdd = () => {
    history.push("/admin/quiz/add");
  };

  const handleClickEdit = (quizId) => {
    history.push(`/admin/quiz/edit?id=${quizId}`);
  };

  useEffect(() => {
    const getQuiz = async () => {
      const result = await getAllQuiz(token);
      setQuiz(result.data);
      setHasDeleted(false);
    };

    getQuiz();
  }, [token, hasDeleted]);

  console.log(quiz);

  // const filtered = [...new Set(quiz.map(data => data.modul))]

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Quiz" />
        <ContentDashboard>
          <div className="flex justify-between items-center">
            <h1 className="text-xl font-medium">Data Kuis</h1>
            <div onClick={handleClickAdd}>
              <div className="p-2 rounded-md bg-blue cursor-pointer">
                <img src="/assets/icons/plus.png" alt="add" />
              </div>
            </div>
          </div>
          
          {/* <div className="mt-5">
            <Controller
              control={control}
              defaultValue=""
              name="modul"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select value="" options={[]} placeholder="Filter by modul"/>
              )}
            />
          </div> */}

          <div className="grid md:grid-cols-3 gap-6 mt-6">
            {quiz.map((data) => {
              return (
                <div key={data.id} onClick={() => handleClickEdit(data.id)}>
                  <Card
                    quiz={data}
                    token={token}
                    setHasDeleted={setHasDeleted}
                  />
                </div>
              );
            })}
          </div>
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  );
}

function Card({ quiz, token, setHasDeleted }) {
  const cardRef = useRef(),
    iconTrashRef = useRef(),
    borderRef = useRef();
  const [open, setOpen] = useState(false);
  const [currentId, setCurrentId] = useState();

  const handleClickDelete = (event, quizId) => {
    event.stopPropagation();
    setOpen(true);
    setCurrentId(quizId);
  };

  return (
    <Fragment>
      <PopupDeleteConfirmation
        open={open}
        setOpen={setOpen}
        text="Yakin ingin menghapus kuis ini"
        action={deleteQuiz}
        token={token}
        setState={() => setHasDeleted(true)}
        id={currentId}
        redirect="/admin/quiz"
      />
      <div
        className="text-black p-6 border rounded-xl flex flex-col cursor-pointer"
        ref={cardRef}
        onMouseMove={() => handleMoveCard(cardRef, borderRef, iconTrashRef)}
        onMouseLeave={() => handleLeaveCard(cardRef, borderRef, iconTrashRef)}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-medium w-4/5">{quiz.title}</h1>
          <div
            className="cursor-pointer"
            onClick={(event) => handleClickDelete(event, quiz.id)}
          >
            <img
              ref={iconTrashRef}
              src="/assets/icons/trash-black.png"
              alt="delete"
            />
          </div>
        </div>
        <div
          ref={borderRef}
          className="flex flex-col pb-4 border-b border-gray-800"
        >
          <p className="text-sm my-4 w-11/12">{quiz.description}</p>
          <span className="text-md font-medium">{quiz.totalQuestion} Soal</span>
        </div>
        <div className="flex justify-between mt-4 text-xs">
          <div className="flex flex-col">
            <span>Pelajaran</span>
            <span>{quiz.lesson}</span>
          </div>
          <div className="flex flex-col text-right">
            <span>Modul</span>
            <span>{quiz.modul}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default QuizManage;
