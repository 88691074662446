import { NavDashboard } from '../../components/Navbar'
import Tabs from '../../components/Tabs'
import { Fragment, useEffect } from 'react'
import { useState } from 'react';
import { useQuery } from '../../hooks/useQuery'
import { useToggle } from '../../hooks/useToggle'
import { useCookies } from '../../hooks/useCookies'
import { getallchapter, getAllLesson } from '../../service/students/studentService';

function Modul({ teacher, grade, teacherLessonData, getAllChapterByTeacher }) {
  const [file, setFile] = useState('')
  const [activeChapter, setActiveChapter] = useState()
  const [showMoreChapter, setShowMoreChapter] = useState(false)
  const { setHiddenSidebar } = useToggle()
  const [lesson, setLesson] = useState([])
  const [chapters, setChapters] = useState([])
  const [filteredChapter, setFilteredChapter] = useState([])

  const { getCookie } = useCookies()
  const token = getCookie('token')
  const idModul = useQuery("id")
  const idClass = useQuery("class")

  useEffect(() => {
    setHiddenSidebar(false)
  }, [setHiddenSidebar])

  useEffect(() => {
    if (!teacher) {
      const fetchLesson = async () => {
        const result = await getAllLesson(token)
        if (result.status === "Success") {
          return setLesson(result.items)
        }

      }

      fetchLesson()
    }

    setLesson(teacherLessonData)
  }, [token, teacher, teacherLessonData])

  useEffect(() => {
    if (idModul && !teacher) {
      const fetchChapter = async () => {
        const result = await getallchapter(idModul, token)
        if (result.status === "Success") {
          return setChapters(result.items)
        }

        console.log(result)
      }

      fetchChapter()
    } else if (teacher) {
      const fetchChapter = async () => {
        const result = await getAllChapterByTeacher(token, idModul)
        if (result.status === "Success") {
          return setChapters(result.data)
        }

        console.log(result)
      }

      fetchChapter()
    }

  }, [idModul, token, teacher, getAllChapterByTeacher])

  useEffect(() => {
    if (idModul) {
      let splitedLink = chapters?.filter(chapter => chapter.order === activeChapter)[0]?.link.split('/')
      setFilteredChapter(chapters?.filter(chapter => chapter.order === activeChapter))

      if (splitedLink) {
        setFile(() => {
          splitedLink[splitedLink.length - 1] = "preview"
          const link = splitedLink.join('/')
          return link
        })
      }
    }
  }, [activeChapter, chapters, idModul])

  const handleClickChapter = (chapter, linkPDF) => {
    const splitedLink = linkPDF.split("/")
    splitedLink[splitedLink.length - 1] = "preview"
    const link = splitedLink.join('/')

    setActiveChapter(chapter)
    setFile(`${link}`)
    setShowMoreChapter(false)
  }

  console.log(idClass)

  // const filteredChapter = chapters?.filter(chapter => chapter.order === activeChapter)
  // console.log(filteredChapter)

  return (
    <Fragment>
      <div className='relative w-full h-screen md:p-6 p-4 overflow-hidden bg-gray overflow-y-auto '>
        <NavDashboard title={`${idClass ? `Kelas ${idClass}` : "" }`} />
        {(!idModul || document.body.clientWidth >= 1024) && (
          <div className='lg:relative w-full lg:h-auto h-max lg:mb-0 mb-4 overflow-x-auto'>
            <Tabs teacher={teacher} grade={grade} lesson={lesson} />
          </div>
        )}

        {(idModul || document.body.clientWidth >= 1024) && (
          <div className={`absolute lg:bottom-6 bottom-4 lg:top-48 top-36 lg:right-6 right-4 lg:left-6 left-4 lg:block`}> {/* overflow-hidden */}
            <div className='flex flex-col h-screen w-full pb-6'> {/* lg:h-full */}
              <div className='flex lg:flex-row flex-col h-full overflow-y-auto lg:overflow-hidden'>
                <div className='flex flex-col lg:w-4/5 lg:order-1 order-2 lg:mr-4 h-full'>
                  <span className='block lg:text-xl text-md font-semibold pb-3'>Bab {filteredChapter[0]?.order} - {filteredChapter[0]?.description}</span>
                  <div className='bg-white rounded-xl overflow-hidden lg:h-full'>
                    <div className='lg:h-full h-screen'>
                      <iframe title="pdf" src={`${file}`} width="100%" height="100%" />
                    </div>
                  </div>
                </div>
                <div className='lg:order-2 order-1 lg:w-1/3 lg:bg-transparent bg-white lg:p-0 p-6 lg:mb-0 mb-6 lg:rounded-none rounded-xl relative'>
                  <span className='block lg:text-xl text-md font-semibold mb-3'>Bab dan Materi</span>
                  <div className='absolute right-6 top-6 text-sm txt-gray md:hidden block cursor-pointer' onClick={() => setShowMoreChapter(true)}>Lihat Semua</div>
                  <div className='md:block hidden overflow-y-auto custom-scroll h-full pb-10'>
                    {chapters.map(chapter => {
                      return <button key={chapter.order} onClick={() => handleClickChapter(chapter.order, chapter.link)} className={`${activeChapter === chapter.order ? 'bg-blue text-white shadow-lg' : 'lg:shadow-none shadow-gray bg-white'} w-full p-4 rounded-2xl mb-4 lg:text-base text-xs text-left`}>Bab {chapter.order} - {chapter.description}</button>
                    })}
                  </div>

                  {/* Ukuran Hp */}
                  <div className='md:hidden'>
                    {chapters.slice(0, 3).map(chapter => {
                      return <button key={chapter.order} onClick={() => handleClickChapter(chapter.order, chapter.link)} className={`${activeChapter === chapter.order ? 'bg-blue text-white shadow-lg' : 'lg:shadow-none shadow-gray bg-white'} w-full p-4 rounded-2xl mb-4 lg:text-base text-xs text-left`}>Bab {chapter.order} - {chapter.description}</button>
                    })}
                  </div>

                  {/* untuk ukuran hp jik a klik  lihat semua */}
                  {showMoreChapter && (
                    <div className='fixed md:hidden p-6 flex flex-col inset-0 top-16 bg-white rounded-r-2xl rounded-l-2xl'>
                      <div className='flex justify-between'>
                        <span className='block lg:text-xl text-md font-semibold mb-3'>Bab dan Materi</span>
                        <div className='w-6 h-6' onClick={() => setShowMoreChapter(false)}>
                          <img src="/assets/icons/exit.png" alt="exit" />
                        </div>
                      </div>
                      <div className='md:hidden h-full overflow-auto'>
                        {chapters.map(chapter => {
                          return <button key={chapter.order} onClick={() => handleClickChapter(chapter.order, chapter.link)} className={`${activeChapter === chapter.order ? 'bg-blue text-white shadow-lg' : 'lg:shadow-none shadow-gray bg-white'} w-full p-4 rounded-2xl mb-4 lg:text-base text-xs text-left`}>Bab {chapter.order} - {chapter.description}</button>
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment >
  )
}

export default Modul