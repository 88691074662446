/* eslint-disable no-restricted-globals */
import { Fragment, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useHistory } from "react-router-dom"
import Select from "react-select"
import { TitleForm } from "../.."
import { CONSTANT } from "../../../../constant"
import { useQuery } from "../../../../hooks/useQuery"
import TextRed from "../../../ErrorField"
import { Input, InputGroup } from "../../../Input"
import { PopupDeleteConfirmation } from '../../../Popup'
// import CreatableSelect from 'react-select/creatable';

export function AddModulForm({ gradeAndLesson: { grade, lesson }, createModul, token }) {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [state, setState] = useState({})
  const [chapters, setChapters] = useState([])
  const [showPopup, setShowpopup] = useState(false)
  const history = useHistory()

  const onSubmit = async (data) => {
    const parseIdGrade = parseFloat(data.idGrade)
    const parseIdLesson = parseFloat(data.idLesson)

    state.chapter = chapters
    state.idGrade = parseIdGrade
    state.idLesson = parseIdLesson

    setState(prev => ({ ...prev, chapter: [] }))

    const distinctItems = new Set(state.chapter.map(chapter => chapter.order))
    if (distinctItems.size < chapters.length) {
      return alert("Ada beberapa Bab yang duplikat, silahkan hapus!")
    }

    const result = await createModul(state, token)

    if (result.status === "Success") {
      alert("Tambah Modul Berhasil")
      return history.push('/admin/modul')
    }

    console.log(result)
  }

  const handleChangeForm = (input) => {
    const target = input.target.name
    const value = input.target.value

    if (target === "link" || target === "description" || target === "order") {
      return setState(prev => ({
        ...prev,
        chapter: [{ ...prev.chapter?.[0] ?? [], [target]: value }]
      }))
    }

    setState(prev => ({
      ...prev,
      [target]: value
    }))
  }

  const handleClickAddModul = () => {
    setChapters(prev => [...prev, ...state?.chapter ?? []])
  }

  const handleClickRemoveModul = (event) => {
    const index = parseFloat(event.target.dataset.index)
    chapters[index] = null
    setChapters(chapters.filter(chapter => chapter !== null))
  }

  return (
    <Fragment>
      <PopupDeleteConfirmation open={showPopup} setOpen={setShowpopup} text="Yakin ingin menghapus modul ini" />
      <form onChange={handleChangeForm} className='w-full h-full mb-6' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full flex md:flex-row flex-col h-full'>
          <div className='md:order-1 order-2 flex flex-col p-5 rounded-xl bg-white md:w-2/5 md:mb-0 mb-6 md:mr-6'>
            <h1 className='text-xl font-medium'>Urutan Bab dan Materi</h1>
            <div className='border-b pb-6'>
              <InputGroup>
                <label className='mb-2 text-sm' htmlFor="order">Bab</label>
                <Input type="number" id="order" register={register('order', { required: true })} />
                {errors.order && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>

              <InputGroup>
                <label className='mb-2 text-sm' htmlFor="description">Materi</label>
                <textarea name="description" {...register("description", { required: true })} className='border-gray outline-none rounded-xl p-3' />
                {errors.description && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>

              <InputGroup>
                <label className='mb-2 text-sm' htmlFor="link">link PDF (Modul)</label>
                <Input register={register('link', { required: true })} />
                {errors.link && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>

              <button onClick={handleClickAddModul} type="button" className='border-blue mt-3 w-full p-4 txt-blue text-sm rounded-xl mr-4'>Tambah Modul</button>
            </div>

            <div className='mt-6 text-sm h-72 overflow-y-auto'>
              <ul>
                {chapters.map((chapter, id) => {
                  return (
                    <li key={id} className='flex flex-col relative pl-6 mb-3'>
                      <span>Bab {chapter?.order}</span>
                      <span>{chapter?.description}</span>
                      <span>Link pdf: {chapter?.link ?? 'belum diisi'}</span>
                      <i onClick={handleClickRemoveModul} data-index={id} className='w-4 h-4 absolute left-0 top-1 cursor-pointer bg-no-repeat' style={{ backgroundImage: "url('/assets/icons/exit.png')" }} />
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className='md:order-2 order-1 bg-white p-5 rounded-xl md:w-3/5 md:mb-0 mb-6'>
            <div className='flex justify-between items-center'>
              <TitleForm>Tambah Modul</TitleForm>
              {/* <i onClick={() => setShowpopup(true)} className='w-4 h-6 bg-contain cursor-pointer bg-no-repeat' style={{ backgroundImage: "url('/assets/icons/trash-black.png')" }} /> */}
            </div>
            <InputGroup>
              <label className='mb-2 text-sm' htmlFor="name">Nama Modul</label>
              <Input register={register('name', { required: true })} />
              {errors.name && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
            <InputGroup>
              <label className='mb-2 text-sm' htmlFor="idLesson">Nama Pelajaran</label>
              <select className='rounded-xl border-gray p-3 outline-none' {...register('idLesson', { required: true })}>
                {lesson?.data?.map((lesson, id) => {
                  return <option key={id} value={lesson.id}>{lesson.name}</option>
                })}
              </select>
              {errors.idLesson && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
            <InputGroup>
              <label className='mb-2 text-sm' htmlFor="idGrade">Tingkatan</label>
              <select className='rounded-xl border-gray p-3 outline-none' {...register('idGrade', { required: true })}>
                {grade?.data?.map((data, id) => {
                  return <option key={id} value={data.grade}>{data.grade}</option>
                })}
              </select>
              {errors.idGrade && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
          </div>
        </div>

        <div className='flex mt-12 justify-end'>
          <Link to="/admin/modul">
            <button className='bg-white text-black text-sm py-4 px-6 rounded-xl mr-4'>Kembali</button>
          </Link>
          <button type="submit" className='bg-blue p-4 text-white text-sm rounded-xl mr-4'>Tambah</button>
        </div>
      </form>
    </Fragment >
  )
}

export function EditModulForm({ gradeAndLesson: { grade, lesson, specificModulById, allChapter }, updateModul, token, deleteModul }) {
  const { handleSubmit, formState: { errors }, control, setValue } = useForm()
  const [chapters, setChapters] = useState(allChapter)
  const id = useQuery("id")
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState({})
  
  const filteredModul = specificModulById?.data?.filter(data => data.idModul === parseFloat(id))
  
  const [state, setState] = useState({
    idGrade: filteredModul?.[0].grade,
    idLesson: filteredModul?.[0].idLesson,
    idModul: parseInt(id),
    name: filteredModul?.[0].nameModul,
    chapter: chapters
  })

  const onSubmit = async (data) => {
    state.chapter = chapters

    const distinctItems = new Set(state.chapter.map(chapter => parseInt(chapter.order)))
    console.log(distinctItems.size, chapters.length)
    if (distinctItems.size < chapters.length ) {
      return alert("Ada beberapa Bab yang duplikat, silahkan hapus!")
    }

    console.log(state)
    
    const result = await updateModul(state, token)
    if (result.status === "Success") {
      alert("Ubah data berhasil")
      return history.push('/admin/modul')
    }
    
    setErrorMessage(result.errors)
    console.log(result)
  }

  const handleChangeForm = (input) => {
    const target = input.target.name
    const value = input.target.value

    if (target === "link" || target === "description" || target === "order") {
      return setState(prev => ({
        ...prev,
        chapter: [{ ...prev.chapter[0], [target]: value }]
      }))
    }

    setState(prev => ({
      ...prev,
      [target]: value
    }))
  }

  console.log(chapters)

  const handleClickAddModul = () => {
    setChapters(prev => [...prev, ...state?.chapter ?? []])
  }

  const handleClickRemoveModul = (event) => {
    const index = parseFloat(event.target.dataset.index)
    chapters[index] = null
    setChapters(chapters.filter(chapter => chapter !== null))
  }

  const handleRemoveModul = async () => {
    const confirmation = confirm("yakin ingin menghapus modul ini?")

    if(confirmation) {
      const result = await deleteModul(state.idModul, token)
      if(result.status === "Success") {
        alert("Hapus Modul Berhasil")
        history.goBack()
      }
    }
  }

  const lessonOptions = lesson?.data?.map(lesson => ({
    value: lesson.id,
    label: lesson.name,
  }))

  const gradeOptions = grade?.data?.map(lesson => ({
    value: lesson.grade,
    label: lesson.grade,
  }))

  if (!filteredModul) {
    return <div>Loading...</div>
  }

  return (
    <Fragment>
      <form className='w-full mb-6' onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full flex md:flex-row flex-col '>
          <div className='md:order-1 order-2 flex flex-col p-5 rounded-xl bg-white md:w-2/5 md:mb-0 mb-6 md:mr-6'>
            <h1 className='text-xl font-medium'>Urutan Bab dan Materi</h1>
            <div className='border-b pb-6'>
              <InputGroup>
                <label className='mb-2 text-sm' htmlFor="order">Bab</label>
                <Input onChange={handleChangeForm} name="order" type="number" id="order" />
                {errors.order && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>

              <InputGroup>
                <label className='mb-2 text-sm' htmlFor="description">Materi</label>
                <textarea onChange={handleChangeForm} id="description" name="description" className='border-gray outline-none rounded-xl p-3' />
                {errors.description && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>

              <InputGroup>
                <label className='mb-2 text-sm' htmlFor="link">link PDF (Modul)</label>
                <Input onChange={handleChangeForm} id="link" name="link" />
                {errors.link && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              </InputGroup>

              <button onClick={handleClickAddModul} type="button" className='border-blue mt-3 w-full p-4 txt-blue text-sm rounded-xl mr-4'>Tambah Modul</button>
            </div>

            <div className='mt-6 text-sm h-72 overflow-y-auto'>
              <ul className='pb-4'>
                {chapters.map((chapter, id) => {
                  return (
                    <li key={id} className='flex flex-col relative pl-6 mb-3'>
                      <span>Bab {chapter?.order}</span>
                      <span>Materi: {chapter?.description}</span>
                      <span className='break-words'>Link pdf: {chapter?.link}</span>
                      <i onClick={handleClickRemoveModul} data-index={id} className='w-4 h-4 absolute left-0 top-1 cursor-pointer bg-no-repeat' style={{ backgroundImage: "url('/assets/icons/exit.png')" }} />
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className='md:order-2 order-1 bg-white p-5 rounded-xl md:w-3/5 md:mb-0 mb-6'>
            <div className='flex justify-between items-center'>
              <TitleForm>Ubah Modul</TitleForm>
              <i onClick={handleRemoveModul} className='w-4 h-6 bg-contain cursor-pointer bg-no-repeat' style={{ backgroundImage: "url('/assets/icons/trash-black.png')" }} />
            </div>
            <InputGroup>
              <label className='mb-2 text-sm' htmlFor="name">Nama Modul</label>
              <Input defaultValue={filteredModul[0].nameModul} name="name" onChange={handleChangeForm} />
              {(errors.name && <TextRed>{CONSTANT.REQUIRED}</TextRed>) || (errorMessage.name && <TextRed>{errorMessage.name.message}</TextRed>)}
            </InputGroup>
            <InputGroup>
              <label className='mb-2 text-sm' htmlFor="idLesson">Nama Pelajaran</label>
              <Controller
                name="idLesson"
                defaultValue={setValue('idLesson', filteredModul?.[0].idLesson)}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => {
                  return <Select defaultValue={{ label: filteredModul?.[0].lesson, value: filteredModul?.[0].value }} onChange={val => state.idLesson = val.value} options={lessonOptions} />
                }}
              />
              {errors.idLesson && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
            <InputGroup>
              <label className='mb-2 text-sm' htmlFor="idGrade">Tingkatan</label>
              <Controller
                name="idGrade"
                defaultValue={setValue('idGrade', filteredModul?.[0].grade)}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => {
                  return <Select defaultValue={{ label: filteredModul?.[0].grade, value: filteredModul?.[0].grade }} onChange={val => state.idGrade = val.value} options={gradeOptions} />
                }}
              />
              {errors.idGrade && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
            </InputGroup>
          </div>
        </div>

        <div className='flex mt-12 justify-end'>
          <Link to="/admin/modul">
            <button className='bg-white text-black text-sm py-4 px-6 rounded-xl mr-4'>Kembali</button>
          </Link>
          <button type="submit" className='bg-blue p-4 text-white text-sm rounded-xl mr-4'>Ubah</button>
        </div>
      </form>
    </Fragment >
  )
}