import { Fragment } from "react";
import Nav from '../Navbar';
import { Section, FlexColw50, Title, SubTitle } from '../Section';
import { scrollToSection } from '../../utils/common/smoothScroll'
import { Link } from "react-router-dom";
import { useSignUp } from '../../hooks/useSignup'

function Header() {
    return (
        <Fragment>
            <Nav />
            <Section marginTop='md:mt-12 mt-0' className='grid md:grid-cols-2 grid-cols-1 text-center md:text-left'>
                <FlexColw50 className=''>
                    <Title marginTop='md:mt-6 mt-4'>
                        Mari Belajar dengan <span className='txt-blue'>Bahagia</span> dan Tingkatkan Prestasi Bersama Star Bimbel
                        <div className='absolute md:-bottom-8 h-auto -bottom-2 md:w-full w-1/2 md:left-0 left-1/4 md:h-24 -z-10'>
                            <img src="/assets/images/line_hero.png" loading='lazy' alt="line" />
                        </div>
                    </Title>
                    <SubTitle marginTop='md:mt-6 mt-4'>
                        <span className='md:w-full w-9/12 block m-auto'>Pendidikan adalah paspor untuk masa depan, karena hari esok adalah milik mereka yang mempersiapkannya hari ini.</span>
                    </SubTitle>
                    <Link to='/signup'>
                        <button className='md:hidden bg-blue px-8 py-3 mt-6 text-white rounded-xl mr-6 md:text-base text-xs'>Sign Up</button>
                    </Link>
                    
                    <button className='md:hidden mt-6 border-2 py-3 px-4 rounded-xl border-gray md:text-base text-xs' onClick={() => scrollToSection('tentang')}>Tentang Kami</button>
                    <span className='bg-orange m-auto mt-6 md:mt-4 md:ml-0 md:mb-0 w-max py-1 px-3 rounded-md text-white  block md:text-base text-sm'>Harga Ramah Di Kantong</span>
                    <button className='mt-6 btn-blue hidden md:block' onClick={() => scrollToSection('tentang')}>Tentang Kami</button>

                </FlexColw50>
                <FlexColw50>
                    <div className='lg:-mt-16 xl:-mt-20'>
                        <img src='/assets/images/hero_img.png' loading='lazy' alt='hero' />
                    </div>
                </FlexColw50>
            </Section>
        </Fragment>
    )
}

export function HeaderSignUp() {
    const { stepForm } = useSignUp()

    return (
        <div className='flex md:px-24 px-6 md:h-80 md:mb-0 mb-6 md:mt-28 mt-6'>
            <div className='md:w-1/2 flex flex-col md:justify-between'>
                <img className='w-16' src="/assets/icons/logo.png" alt="logo" />
                <Title>Hai, Selamat Bergabung di Star Bimbel </Title>
                {stepForm === 2 && (
                    <SubTitle marginTop='mt-6'>
                        Anda harus melengkapi data Orang Tua dibawah ini.
                    </SubTitle>
                )}

                {stepForm === 3 && (
                    <SubTitle marginTop='mt-6'>
                        Anda harus melengkapi data Murid dibawah ini.
                    </SubTitle>
                )}
            </div>
            <div className='w-1/2 md:flex hidden h-max'>
                <div className='bg-blue w-full flex justify-center h-full rounded-2xl relative'>
                    <div className='md:h-full lg:h-96 absolute bottom-0 overflow-hidden'>
                        <img className='transform xl:-translate-y-6' src="/assets/images/lengkapi.png" alt="lengkapi" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header