import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { NavDashboard } from '../../../components/Navbar'
import { useCookies } from '../../../hooks/useCookies'
import { getAllChapter, getAllModul } from '../../../service/admin/adminService'

function ModulManage() {
  const [showMateri, setShowMateri] = useState(false)
  const [activeSelectModul, setActiveSelectModul] = useState("")
  const [modul, setModul] = useState([])
  const [chapters, setChapters] = useState([])

  const { getCookie } = useCookies()
  const token = getCookie('token')

  useEffect(() => {
    const getModul = async () => {
      const modul = await getAllModul(token)
      setModul(modul.data)
    }

    getModul()
  }, [token])

  useEffect(() => {
    if (activeSelectModul) {
      const getChapters = async () => {
        const chapters = await getAllChapter(token, activeSelectModul)
        setChapters(chapters.data)
      }

      getChapters()
    }
  }, [activeSelectModul, token])

  return (
    <Fragment>
      <div className='relative bg-gray w-full h-screen md:p-6 p-4'>
        <NavDashboard title="Kelola Modul" />
        <div className='flex md:flex-row flex-col justify-between mt-6 absolute md:bottom-6 bottom-4 md:left-6 left-4 md:right-6 right-4 lg:top-20 top-32 overflow-hidden'>
          <div className='md:w-3/5 h-full md:mr-6 bg-white rounded-xl p-5 pb-20'>
            <div className='flex justify-between items-center'>
              <h1 className='text-xl font-medium'>Data Modul</h1>
              <div className='flex items-center'>
                <Link to={`/admin/modul/edit?id=${activeSelectModul}`}>
                  <span className='text-sm cursor-pointer txt-blue md:block hidden'>Edit Modul</span>
                </Link>
                <Link to="/admin/modul/add">
                  <div className='bg-blue p-2 rounded-xl cursor-pointer ml-6'>
                    <img src="/assets/icons/plus.png" alt="add-modul" />
                  </div>
                </Link>
              </div>
            </div>

            <div className='grid sm:grid-cols-2 gap-5 mt-6 h-full overflow-y-auto'>
              {modul.map(modul => {
                return <CardModul key={modul.idModul} setActiveSelectModul={setActiveSelectModul} setShowMateri={setShowMateri} name={modul.nameModul} id={modul.idModul} />
              })}
            </div>
          </div>
          <div id="bab" className={`bg-white rounded-xl md:w-2/5 md:flex ${showMateri ? '' : 'hidden'} md:static fixed inset-0 -bottom-6 top-10 flex-col p-5 md:mt-0 mt-6 mb-6 md:mb-0`}>
            <div className='flex justify-between'>
              <h1 className='text-xl font-medium'>Bab dan Materi</h1>
              <div className='md:hidden flex items-center'>
                <Link to={`/admin/modul/edit?id=${activeSelectModul}`}>
                  <span className='text-sm cursor-pointer txt-blue'>Edit Modul</span>
                </Link>
                <div onClick={() => setShowMateri(false)} className='p-2 rounded-xl cursor-pointer ml-6'>
                  <img src="/assets/icons/exit.png" alt="add-modul" />
                </div>
              </div>
            </div>
            <div className='flex flex-col h-full overflow-y-auto overflow-x-hidden'>
              <ul className='p-6'>
                {chapters?.map(chapter => {
                  return (
                    <li key={chapter.order} className='flex flex-col mb-3'>
                      <span className='font-semibold text-md block'>Bab {chapter.order}</span>
                      <span className='text-sm words-break'>{chapter.description}</span>
                    </li>
                  )
                })}

                {chapters.length === 0 && (
                  <span>Belum ada Bab dan Materi</span>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

function CardModul({ name, id, setShowMateri, setActiveSelectModul }) {
  const handleClickCard = (card) => {
    setActiveCard(card, setActiveSelectModul)
    setShowMateri(true)
  }

  return (
    <div onClick={handleClickCard} data-card={id} className={`card-modul border-gray text-black rounded-xl p-4 flex flex-col cursor-pointer`}>
      <span className='font-semibold text-md'>{name}</span>
      <span className='text-sm'>ID {id}</span>
    </div>
  )
}

// active card modul
function setActiveCard(card, setActive) {
  let el = "", id = ""
  console.log(card)
  const checkCard = card.nativeEvent.composedPath().some(element => {
    el = element
    id = element.dataset.card
    setActive(id)
    return element.classList.contains('card-modul')
  })

  if (checkCard) {
    el.classList.toggle('bg-blue')
    el.classList.toggle('text-white')
  }

  const cards = Array.from(document.getElementsByClassName('card-modul')).filter(card => card.dataset.card !== id)
  cards.forEach(card => {
    card.classList.remove('bg-blue', 'text-white')
  })
}

export default ModulManage