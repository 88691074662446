import { NavDashboard } from "../../components/Navbar"
import { EditPasswordStudentForm, EditStudentForm } from '../../components/Form/student'
import { Fragment } from "react"
import { useEditType } from '../../hooks/useEditType'
import { useQuery } from '../../hooks/useQuery'
import { ContentDashboard, MainDashboard } from "../../layouts/Dashboard"
import { useState, useEffect } from 'react'
import { getProfile } from "../../service/profile/profileService";
import { useCookies } from "../../hooks/useCookies"

function EditProfile() {
  const { type, changeEditTypeTo } = useEditType()
  const editType = useQuery("type")

  const { getCookie } = useCookies()
  const token = getCookie('token')
  const [profile, setProfile] = useState([])

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getProfile(token)
      if (profile.status === "Success") {
        return setProfile(profile.data)
      }

      console.log(profile) // check error
    }

    fetchProfile()

    return () => setProfile({})
  }, [token])

  console.log(profile)

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Profile" />
        <ContentDashboard>
          {(type === "profil" && editType !== "password") && <EditStudentForm token={token} profile={profile} changeEditTypeTo={changeEditTypeTo} />}
          {(editType === "password" || type !== "profil") && <EditPasswordStudentForm />}
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  )
}

export default EditProfile