import { Fragment, useState, useEffect } from "react"
import { NavDashboard } from "../../../components/Navbar"
import { AddScheduleClassForm } from '../../../components/Form/admin/class'
import { MainDashboard } from "../../../layouts/Dashboard"
import { useCookies } from "../../../hooks/useCookies"
import { getAllTeacher, getAllLessonSchedule, scheduleCreate } from '../../../service/admin/adminService'

function AddScheduleClass() {
  const { getCookie } = useCookies()

  const [teachers, setTeachers] = useState([])
  const [lessonSchedule, setLessonSchedule] = useState([])
  const token = getCookie("token")

  useEffect(() => {
    const fetchAllTeacher = async () => {
      const teachers = await getAllTeacher(token)
      const lessonSchedule = await getAllLessonSchedule(token)
      setTeachers(teachers.data)
      setLessonSchedule(lessonSchedule.data)
    }

    fetchAllTeacher()
  }, [token])

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Kelas" />
        <div className='mt-6'>
          <AddScheduleClassForm token={token} teachers={teachers} lessonSchedule={lessonSchedule} createSchedule={scheduleCreate}/>
        </div>
      </MainDashboard>
    </Fragment>
  )
}

export default AddScheduleClass