import { useState, Fragment } from "react";
import { usePaymentData } from "../../hooks/usePaymentData";
import { usePromoValue } from "../../hooks/usePromoInput";
import TextRed from "../ErrorField";
import { Link, useHistory } from "react-router-dom";
import Close from "../Close";
import { useCookies } from "../../hooks/useCookies";
import { checkCurrentRole } from "../../utils/common/checkCurrentRole";
import { Input, InputGroup } from "../Input";
import { useForm, Controller } from "react-hook-form";
import { CONSTANT } from "../../constant";
import Select from "react-select";

function PopupContainer({ children, className }) {
  return (
    <div
      className={`w-full h-screen inset-0 fixed bg-transparent flex z-20 ${className} md:p-0 p-4 overflow-y-auto`}
    >
      {children}
    </div>
  );
}

export function PopupConfirmation({
  open,
  setOpen,
  textCancel,
  title,
  textOK,
  onClickOK,
  bgButtonOK,
}) {
  if (open) {
    return (
      <PopupContainer>
        <div className="flex flex-col m-auto bg-white p-12 rounded-xl">
          <h3 className="md:text-base text-sm">{title}</h3>
          <div className="flex justify-between mt-6 text-md">
            <button
              className="py-3 md:px-7 px-3 rounded-xl bg-gray md:text-base text-sm"
              onClick={() => setOpen(false)}
            >
              {textCancel}
            </button>
            <button
              className={`py-3 md:px-7 px-3 ${
                bgButtonOK ? bgButtonOK : "bg-blue"
              } text-white rounded-xl md:text-base text-sm`}
              onClick={onClickOK}
            >
              {textOK}
            </button>
          </div>
        </div>
      </PopupContainer>
    );
  }

  return <></>;
}

export function PopupQuiz({ setState, open, setOpen, idQuiz, idClass, action, token }) {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    data.idQuiz = idQuiz
    data.idClass = idClass

    const result = await action(data, token)
    console.log(result)
    if(result.status === "Success") {
      alert("Set Waktu Quiz Telah Berhasil!")
      setState()
      return setOpen(false)
    }
  }

  if (open) {
    return (
      <PopupContainer>
        <form onSubmit={handleSubmit(onSubmit)} className="md:w-1/2 bg-white rounded-xl md:p-20 sm:p-16 p-9 m-auto flex flex-col">
          <span className="mb-4 font-medium">Isi data Berikut :</span>
          <InputGroup>
            <label className="mb-2 txt-gray text-sm">Waktu Mulai</label>
            <Input type="datetime-local" register={register("startDate", {required: true})}/>
            {errors.startDate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 txt-gray text-sm w-full">
              Waktu Selesai
            </label>
            <Input type="datetime-local" register={register("endDate", {required: true})}/>
            {errors.endDate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <div className="mt-6 w-full flex justify-between">
            <button
              type="button"
              className="bg-gray md:text-sm text-xs py-4 md:px-12 px-6 rounded-xl"
              onClick={() => setOpen(false)}
            >
              Kembali
            </button>
            <button
              type="submit"
              className="bg-blue md:text-sm text-xs py-4 md:px-12 px-6 rounded-xl text-white"
            >
              Masukan
            </button>
          </div>
        </form>
      </PopupContainer>
    );
  }

  return <></>;
}

export function PopupExam({ setState, open, setOpen, idExam, idClass, action, token }) {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    data.idExam = idExam
    data.idClass = idClass

    const result = await action(data, token)
    console.log(result)
    if(result.status === "Success") {
      alert("Set Waktu Ujian Telah Berhasil!")
      setState()
      return setOpen(false)
    }
  }

  if (open) {
    return (
      <PopupContainer>
        <form onSubmit={handleSubmit(onSubmit)} className="md:w-1/2 bg-white rounded-xl md:p-20 sm:p-16 p-9 m-auto flex flex-col">
          <span className="mb-4 font-medium">Isi data Berikut :</span>
          <InputGroup>
            <label className="mb-2 txt-gray text-sm">Waktu Mulai</label>
            <Input type="datetime-local" register={register("startDate", {required: true})}/>
            {errors.startDate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup>
            <label className="mb-2 txt-gray text-sm w-full">
              Waktu Selesai
            </label>
            <Input type="datetime-local" register={register("endDate", {required: true})}/>
            {errors.endDate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <div className="mt-6 w-full flex justify-between">
            <button
              type="button"
              className="bg-gray md:text-sm text-xs py-4 md:px-12 px-6 rounded-xl"
              onClick={() => setOpen(false)}
            >
              Kembali
            </button>
            <button
              type="submit"
              className="bg-blue md:text-sm text-xs py-4 md:px-12 px-6 rounded-xl text-white"
            >
              Masukan
            </button>
          </div>
        </form>
      </PopupContainer>
    );
  }

  return <></>;
}

function PopupWriteInputPromo({ open, setOpen }) {
  const { setPromo, promo } = usePromoValue();

  const handleClickSubmitPromo = () => {
    setOpen(false);
  };

  const handleChangeInputPromo = (input) => {
    setPromo(input.target.value);
  };

  if (open) {
    return (
      <PopupContainer>
        <div className="md:w-1/2 bg-white rounded-xl p-20 m-auto flex flex-col">
          <span>Masukan Promo</span>
          <input
            defaultValue={promo}
            className="font-semibold focus:border-blue-200 border md:rounded-md mt-4 p-2 outline-none uppercase"
            onChange={handleChangeInputPromo}
          />
          <div className="mt-6 w-full flex justify-between">
            <button
              type="button"
              className="bg-gray py-4 px-12 rounded-xl"
              onClick={() => setOpen(false)}
            >
              Kembali
            </button>
            <button
              type="button"
              className="bg-blue py-4 px-12 rounded-xl text-white"
              onClick={handleClickSubmitPromo}
            >
              Masukan
            </button>
          </div>
        </div>
      </PopupContainer>
    );
  }

  return <></>;
}

function PopupPaymentUpload({ open, setOpen }) {
  const {
    eventListener: { addImage },
  } = usePaymentData();
  const [messageErrorImage, setMessageErrorImage] = useState(false);
  const [image, setImage] = useState("");
  const [fileName, setFileName] = useState("");

  if (open) {
    const loadImage = (event) => {
      const files = event.target.files[0];
      const reader = new FileReader();

      reader.onload = function (e) {
        if (e.loaded >= 5000000) {
          return setMessageErrorImage(true);
        }

        setMessageErrorImage(false);
        const blob = event.target.files[0].slice(
          0,
          event.target.files[0].size,
          "image/png"
        );
        const newFile = new File([blob], e.target.result);
        addImage(newFile.name);

        setFileName(files.name);
        setImage(newFile.name);
      };

      if (files) {
        reader.readAsDataURL(files);
      } else {
        console.log("error");
      }
    };

    return (
      <PopupContainer>
        <div className="lg:w-1/2 lg:static absolute bottom-0 left-0 right-0 top-20 m-auto bg-white rounded-xl md:px-20 px-10 py-10 flex flex-col">
          <span className="text-lg font-semibold">Upload Bukti Pembayaran</span>
          <div className="flex flex-col">
            <div className="w-full h-52 rounded-xl border-4 p-4 border-dashed mt-4 flex flex-col items-center bg-lightblue-4 p-4">
              <div className="mt-6">
                <img src="/assets/icons/i-upload.png" alt="upload" />
              </div>
              <span className="text-sm mt-3 text-center">
                {" "}
                <label className="txt-blue cursor-pointer" htmlFor="file">
                  Browse
                </label>
                <input
                  required
                  className="w-full m-auto"
                  onChange={loadImage}
                  name="image"
                  type="file"
                  id="file"
                  accept="image/png, image/jpeg"
                />
                {messageErrorImage && (
                  <TextRed>Ukuran Gambar Tidak Boleh Leboh dari 5MB</TextRed>
                )}
              </span>
              <span className="text-sm txt-gray mt-2">
                Supports File JPG - Max. File Size 5 MB{" "}
              </span>
            </div>
            {image && (
              <div className="flex flex-col mt-6">
                <div className="rounded-xl border-2 p-5 flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-12">
                      <img src={`${image}`} alt="test" />
                    </div>
                    <span className="text-sm ml-3">{fileName}</span>
                  </div>
                  {/* <div className='bg-gray p-2 rounded-md cursor-pointer'>
                  <img src="/assets/icons/exit.png" alt="close" />
                </div> */}
                </div>
              </div>
            )}
            <div className="flex justify-between mt-6">
              <button
                type="button"
                className="bg-gray py-6 px-12 rounded-xl md:text-base text-xs"
                onClick={() => setOpen(false)}
              >
                Kembali
              </button>
              <button
                type="submit"
                disabled={messageErrorImage}
                className={`${
                  messageErrorImage && "cursor-not-allowed"
                } bg-blue py-6 px-12 rounded-xl text-white md:text-base text-xs`}
              >
                Kirim
              </button>
            </div>
          </div>
        </div>
      </PopupContainer>
    );
  }

  return <></>;
}

function PopupLogout({ open, close }) {
  const { removeCookie, getCookie } = useCookies();
  const token = getCookie("token");
  const history = useHistory();

  const handleClickLogout = () => {
    const role = checkCurrentRole(token);
    removeCookie("token", "/");
    switch (role) {
      case "admin":
        return history.push("/admin");
      case "superadmin":
        return history.push("/admin");
      case "teacher":
        return history.push("/teacher");
      default:
        return history.push("/login");
    }
  };

  if (open) {
    return (
      <PopupContainer>
        <div className="flex flex-col m-auto bg-white p-12 rounded-xl">
          <h3 className="md:text-base text-sm">
            Anda Yakin Ingin Log Out Sekarang ?
          </h3>
          <div className="flex justify-between mt-6 text-md">
            <button
              className="py-3 px-7 rounded-xl bg-gray md:text-base text-sm"
              onClick={() => close()}
            >
              Kembali
            </button>
            <button
              className="py-3 px-7 bg-red-500 text-white rounded-xl md:text-base text-sm"
              onClick={handleClickLogout}
            >
              Logout
            </button>
          </div>
        </div>
      </PopupContainer>
    );
  }

  return <></>;
}

function PopupChoosePackage({ open, studentID, setOpen }) {
  return (
    <Fragment>
      {open && (
        <div className="w-full h-screen fixed inset-0 bg-transparent z-40 flex">
          <div className="relative m-auto w-72 h-72 rounded-xl items-center justify-center bg-white flex flex-col p-4 md:text-sm text-xs">
            <div
              className="absolute right-4 top-4 cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <img src="/assets/icons/exit.png" alt="close" />
            </div>
            <Link to={`/parent/students/score/${studentID}`}>
              <button className="w-32 py-3 px-6 border-black rounded-xl">
                Lihat Skor
              </button>
            </Link>
            <Link to={`/parent/students/package/${studentID}`}>
              <button className="w-32 py-3 px-6 border-black rounded-xl mt-4">
                Pilih Paket
              </button>
            </Link>
            <Link to={`/parent/students/edit?id=${studentID}`}>
              <button className="w-32 py-3 px-6 border-blue bg-blue rounded-xl text-white mt-4">
                Edit Profile
              </button>
            </Link>
          </div>
        </div>
      )}
    </Fragment>
  );
}

function PopupPaymentProcess({
  open,
  close,
  payment,
  onClickVerifiy,
  onClickReject,
}) {
  return (
    <Fragment>
      {open && (
        <PopupContainer>
          <div className="bg-white m-auto md:w-1/2 p-6 md:static fixed top-10 overflow-y-auto right-0 left-0 bottom-0  rounded-xl">
            <div className="flex justify-between items-center border-b-2 border-gray-200 pb-5">
              <h1>Proses Pembayaran</h1>
              <div onClick={() => close()}>
                <Close to="/admin/payment" />
              </div>
            </div>

            <div className="flex md:flex-row flex-col">
              <div className="flex flex-col md:w-1/2">
                <div className="flex md:border-none border-b-2 md:pb-0 pb-4 md:w-auto w-full md:justify-start justify-between md:flex-col mt-6">
                  <span className="font-semibold">ID Orang Tua</span>
                  <div className="flex flex-col">
                    <span className="text-sm my-1">{payment[0].idParent}</span>
                    <span className="text-sm txt-gray">
                      {payment[0].nameParent}
                    </span>
                  </div>
                </div>
                <div className="flex md:border-none border-b-2 md:pb-0 pb-4 md:w-auto w-full md:justify-start justify-between md:flex-col mt-6">
                  <span className="font-semibold">ID Murid</span>
                  <div className="flex flex-col">
                    <span className="text-sm my-1">{payment[0].idStudent}</span>
                    <span className="text-sm txt-gray">
                      {payment[0].sudentName}
                    </span>
                  </div>
                </div>
                <div className="flex md:border-none border-b-2 md:pb-0 pb-4 md:w-auto w-full md:justify-start justify-between md:flex-col mt-6">
                  <span className="font-semibold">Paket Status Topup</span>
                  <span className="text-sm my-1">{payment[0].PackageType}</span>
                </div>
                <div className="flex md:border-none border-b-2 md:pb-0 pb-4 md:w-auto w-full md:justify-start justify-between md:flex-col mt-6">
                  <span className="font-semibold">Nominal Topup</span>
                  <span className="md:text-sm text-lg my-1">
                    Rp{payment[0].total_price.toLocaleString()}
                  </span>
                </div>
              </div>

              <div className="mt-6">
                <span className="font-semibold">Foto Bukti</span>
                <div className="md:w-52 md:h-42 h-40 w-full mt-4">
                  <img
                    src={`https://api.starbimbel.com/foto/${payment[0]?.image}`}
                    className="w-40 h-40 object-cover"
                    alt="bukti"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6 w-full flex justify-evenly">
              <button
                className="px-12 py-4 bg-gray rounded-xl md:text-base text-xs"
                onClick={onClickReject}
              >
                Tolak
              </button>
              <button
                className="px-12 py-4 bg-blue text-white rounded-xl md:text-base text-xs"
                onClick={onClickVerifiy}
              >
                Terima
              </button>
            </div>
          </div>
        </PopupContainer>
      )}
    </Fragment>
  );
}

function PopupDeleteConfirmation({
  open,
  setOpen,
  text,
  action,
  id,
  token,
  redirect,
  setState,
}) {
  const history = useHistory();

  const handleClickBack = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleClickDelete = async (event) => {
    event.stopPropagation();
    const result = await action(id, token);
    console.log(result);
    if (result.status === "Success" || result.status === "success") {
      alert("Hapus Data Berhasil");

      if (setState) {
        setState();
      }

      setOpen(false);
      return history.push(redirect);
    }
  };

  if (open) {
    return (
      <PopupContainer>
        <div className="flex flex-col m-auto bg-white p-12 rounded-xl">
          <h3 className="md:text-base text-sm">{text} ?</h3>
          <div className="flex justify-between mt-6 text-md">
            <button
              className="py-3 px-7 rounded-xl bg-gray md:text-base text-sm"
              onClick={handleClickBack}
            >
              Kembali
            </button>
            <button
              className="py-3 px-7 bg-red-500 text-white rounded-xl md:text-base text-sm"
              onClick={handleClickDelete}
            >
              Hapus
            </button>
          </div>
        </div>
      </PopupContainer>
    );
  }

  return <></>;
}

function PopupPreviewEditClass({
  open,
  setOpen,
  data,
  teachers,
  action,
  setState,
  token,
  lessonSchedule,
}) {
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const onSubmit = async (dataSubmit) => {
    dataSubmit.idSchedule = data[0].id;
    console.log(dataSubmit);

    const result = await action(dataSubmit, token);
    if (result.status === "Success") {
      alert("Ubah Jadwal Berhasil");

      if (setState) {
        setState();
      }

      setOpen(false);
    }
  };

  const teachersOption = teachers?.map((teacher) => ({
    value: teacher.id,
    label: teacher.name,
  }));

  const lessonScheduleOption = lessonSchedule?.map((lesson) => ({
    value: lesson.id,
    label: lesson.name,
  }));

  if (open) {
    if (!data) {
      return <div>Loading...</div>;
    }

    return (
      <Fragment>
        <div className="z-50 fixed">
          <PopupDeleteConfirmation
            open={showPopupDelete}
            setOpen={setShowPopupDelete}
            text="Yakin ingin menghapus kelas ini"
          />
        </div>
        <PopupContainer>
          <div className="m-auto md:w-1/2 w-full bg-white p-6 rounded-xl overflow-auto">
            <div className="flex justify-between m-auto  ">
              <h1>Preview dan Edit Jadwal Kelas</h1>
              {/* <div className='filter grayscale cursor-pointer' onClick={() => setShowPopupDelete(true)}>
                <img src="/assets/icons/btn-trash.png" alt="edit" />
              </div> */}
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputGroup>
                  <label htmlFor="title" className="text-sm mb-2">
                    Judul
                  </label>
                  <Input
                    defaultValue={setValue("title", data[0].title)}
                    register={register("title", { required: true })}
                  />
                  {errors.title && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                </InputGroup>
                <InputGroup>
                  <label htmlFor="lesson" className="text-sm mb-2">
                    Mata Pelajaran
                  </label>
                  <Controller
                    name="idLesson"
                    control={control}
                    defaultValue={data[0].lesson.id}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => {
                      return (
                        <Select
                          defaultValue={{
                            label: data[0].lesson.name,
                            value: data[0].lesson.id,
                          }}
                          onChange={(val) => onChange(val.value)}
                          options={lessonScheduleOption}
                        />
                      );
                    }}
                  />
                  {errors.idLesson && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                </InputGroup>
                <InputGroup>
                  <label htmlFor="teacher" className="text-sm mb-2">
                    Nama Guru
                  </label>
                  <Controller
                    name="idTeacher"
                    defaultValue={data[0].teacher.id}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => {
                      return (
                        <Select
                          defaultValue={{
                            label: data[0].teacher.name,
                            value: data[0].teacher.id,
                          }}
                          onChange={(val) => onChange(val.value)}
                          options={teachersOption}
                        />
                      );
                    }}
                  />
                  {errors.idTeacher && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                </InputGroup>
                <div className="flex justify-between md:flex-row flex-col">
                  <InputGroup className="md:w-1/2 w-full md:mr-2">
                    <label htmlFor="startTime" className="text-sm mb-2">
                      Jam Mulai
                    </label>
                    <Input
                      defaultValue={setValue("startTime", data[0].startTime)}
                      type="time"
                      register={register("startTime", { required: true })}
                    />
                    {errors.startTime && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                  </InputGroup>
                  <InputGroup className="md:w-1/2 w-full">
                    <label htmlFor="endTime" className="text-sm mb-2">
                      Jam Selesai
                    </label>
                    <Input
                      defaultValue={setValue("endTime", data[0].endTime)}
                      type="time"
                      register={register("endTime", { required: true })}
                    />
                    {errors.endTime && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                  </InputGroup>
                </div>
                <InputGroup>
                  <label htmlFor="date" className="text-sm mb-2 w-full">
                    Hari / Tanggal
                  </label>
                  <Input
                    defaultValue={setValue("date", data[0].date)}
                    type="date"
                    register={register("date", { required: true })}
                  />
                  {errors.date && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                </InputGroup>
                <InputGroup>
                  <label htmlFor="link" className="text-sm mb-2">
                    Link
                  </label>
                  <Input
                    defaultValue={setValue("link", data[0].link)}
                    register={register("link", { required: true })}
                  />
                  {errors.link && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
                </InputGroup>

                <div className="flex justify-between mt-6">
                  <button
                    type="button"
                    className="p-3 bg-gray rounded-xl"
                    onClick={() => setOpen(false)}
                  >
                    Kembali
                  </button>
                  <button className="p-3 bg-blue rounded-xl text-white">
                    Simpan
                  </button>
                </div>
              </form>
            </div>
          </div>
        </PopupContainer>
      </Fragment>
    );
  }
  return <></>;
}

function PopupInput({ open, setOpen, title, text }) {
  if (open) {
    return (
      <PopupContainer>
        <div className="m-auto bg-white rounded-xl p-6 md:w-2/5">
          <h1 className="text-lg font-medium">{title}</h1>
          <InputGroup className="mt-6">
            <label htmlFor="nameStudent" className="mb-2 text-sm">
              {text}
            </label>
            <Input />
          </InputGroup>
          <div className="flex justify-between mt-6">
            <button
              className="bg-gray rounded-xl py-4 px-8"
              onClick={() => setOpen(false)}
            >
              Kembali
            </button>
            <button className="bg-blue rounded-xl py-4 px-8 text-white">
              Simpan
            </button>
          </div>
        </div>
      </PopupContainer>
    );
  }
  return <></>;
}

export function PopupSettingRekening({ open, setOpen }) {
  if (open) {
    return (
      <PopupContainer>
        <div className="m-auto bg-white rounded-xl p-6 md:w-2/5">
          <h1 className="text-sm font-medium">Setting Nomor Rekening</h1>
          <InputGroup className="mt-6">
            <label className="mb-2 text-sm">BCA</label>
            <Input />
          </InputGroup>
          <InputGroup className="mt-6">
            <label className="mb-2 text-sm">OVO</label>
            <Input />
          </InputGroup>
          <InputGroup className="mt-6">
            <label className="mb-2 text-sm">DANA</label>
            <Input />
          </InputGroup>
          <div className="flex justify-between mt-6">
            <button
              className="bg-gray rounded-xl py-4 px-8"
              onClick={() => setOpen(false)}
            >
              Kembali
            </button>
            <button className="bg-blue rounded-xl py-4 px-8 text-white">
              Simpan
            </button>
          </div>
        </div>
      </PopupContainer>
    );
  }
  return <></>;
}

export {
  PopupPaymentUpload,
  PopupWriteInputPromo,
  PopupLogout,
  PopupChoosePackage,
  PopupPaymentProcess,
  PopupDeleteConfirmation,
  PopupPreviewEditClass,
  PopupInput,
};
