import { useState } from "react"

function useBoolean() {
  const [value, setValue] = useState(false)

  const toTruthy = () => {
    setValue(true)
  }

  const toFalsy = () => {
    setValue(false)
  }

  return {
    value,
    toTruthy,
    toFalsy
  }
}

export { useBoolean }