import { Fragment, useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { TitleForm } from "../.."
import { CONSTANT } from "../../../../constant"
import { createPromo, getAllPackage } from "../../../../service/admin/adminService"
import { loadImage } from "../../../../utils/common/loadImage"
import TextRed from "../../../ErrorField"
import { Input, InputGroup } from "../../../Input"
import { useQuery } from '../../../../hooks/useQuery'
import { useHistory } from "react-router-dom"
import Select from "react-select"

export function AddPromoForm({ token, setHasAddedPromo }) {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const imageRef = useRef(), packageSelectRef = useRef()
  const [image, setImage] = useState('')
  const [getpackage, setGetPackage] = useState([])
  const [selectValue, setSelectValue] = useState([])

  const handleChangeImage = (image) => {
    loadImage(image, file => {
      setImage(file.name)
    })
  }

  useEffect(() => {
    const fetchGetPackage = async () => {
      const result = await getAllPackage(token)
      setGetPackage(result.data)
    }

    fetchGetPackage()
  }, [token])

  const onSubmit = async (data) => {
    data.image = image
    data.idpackage = Array.from(new Set(selectValue.map(select => select.id)))

    if (!image) {
      return alert("gambar wajib diisi")
    }

    console.log(data)
    const result = await createPromo(data, token)
    if (result.status === "sucess") {
      alert("Tambah promo berhasil")
      setHasAddedPromo(true)
      return setSelectValue([])
    }
  }

  const handleClickAddPackage = () => {
    const select = packageSelectRef.current.children[0].value.split("-")
    setSelectValue(prev => [...prev, { id: select[0], text: select[1], listID: new Date().getTime() }])
  }

  const handleClickRemovePackage = (id) => {
    setSelectValue(selectValue.filter(select => select.listID !== id))
  }

  return (
    <Fragment>
      <form className='p-6 bg-white rounded-xl w-full' onSubmit={handleSubmit(onSubmit)}>
        <TitleForm>Masukan Promo</TitleForm>
        <InputGroup>
          <label htmlFor="header" className='mb-2 text-sm'>Judul</label>
          <Input maxLength="25" register={register('header', { required: true })} />
          {errors.header && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>

        <InputGroup>
          <label htmlFor="namePromo" className='mb-2 text-sm'>Nama Promo</label>
          <Input maxLength="25" register={register('namePromo', { required: true })} />
          {errors.namePromo && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>

        <div className='xl:flex'>
          <InputGroup className='xl:w-1/2 xl:mr-3'>
            <label htmlFor="startDate" className='mb-2 text-sm'>Hari / Tanggal Mulai</label>
            <Input register={register('startDate')} type="date" />
            {errors.startdate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup className='xl:w-1/2'>
            <label htmlFor="endDate" className='mb-2 text-sm'>Hari / Tanggal Selesai</label>
            <Input register={register('endDate')} type="date" />
            {errors.enddate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
        </div>

        <InputGroup className=''>
          <label htmlFor="cutOff" className='mb-2 text-sm'>Potongan</label>
          <Input register={register('cutoff')} type="text" placeholder="" />
          {errors.cutoff && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>

        <div className='xl:flex flex-col'>
          <InputGroup className='flex'>
            <label htmlFor="idpackage" className='mb-2 text-sm'>Jenis Paket</label>
            <div className='flex justify-between' ref={packageSelectRef}>
              <select className='border-gray rounded-md p-2 outline-none w-full mr-6' {...register('idpackage')}>
                {getpackage?.map(pkg => {
                  const filterGrades = pkg.grades.map(grade => grade.grade).sort((a, b) => a - b)
                  return <option key={pkg.id} value={`${pkg.id}-${pkg.type.type} ${pkg.classtype} Kelas ${filterGrades.join(', ')}`}>{pkg.type.type} {pkg.classtype} - Kelas {filterGrades.join(', ')}</option>
                })}
              </select>
              {errors.idPackage && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              <div className='flex items-center'>
                <button onClick={handleClickAddPackage} type="button" className='rounded-md border-blue p-2 text-md px-4'>Tambah</button>
              </div>
            </div>
          </InputGroup>
          <div className='xl:order-2 grid grid-cols-2 gap-3 mt-3 order-1 xl:flex-row'>
            {selectValue?.map((select, id) => {
              return (
                <div key={id} className='bg-gray rounded-xl p-4 flex justify-between items-center'>
                  <span className='text-sm'>{select?.text}</span>
                  <div className='cursor-pointer z-10' onClick={() => handleClickRemovePackage(select.listID)}>
                    <img src="/assets/icons/exit.png" alt="exit" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className='flex xl:flex-row flex-col'>
          <InputGroup className='xl:w-1/2 xl:mr-3 xl:order-1 order-2'>
            <label htmlFor="cutOff" className='mb-2 text-sm'>Gambar Promo</label>
            <div className='h-52 border-2  rounded-xl border-dashed'>
              <div className='flex flex-col justify-center items-center h-full'>
                <div>
                  <img src="/assets/icons/i-upload.png" alt="img" />
                </div>
                <div className='text-sm mt-2'>
                  <input {...register("image")} ref={imageRef} type="file" onChange={handleChangeImage} required />
                </div>
                <span className='text-xs mt-2 txt-gray'>Support file JPG - Max File Size 3MB</span>
              </div>
            </div>
          </InputGroup>
        </div>
        <div className='flex justify-end mt-6'>
          {/* <button type="button" className='mr-4 p-4 bg-gray rounded-xl'>Kembali</button> */}
          <button type="submit" className='p-4 bg-blue text-white rounded-xl'>Masukan</button>
        </div>
      </form>
    </Fragment>
  )
}

export function EditPromoForm({ token, editPromo, detailPromo }) {
  const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()
  const imageRef = useRef(), packageSelectRef = useRef()
  const [image, setImage] = useState("")
  const [getpackage, setGetPackage] = useState([])
  const [selectValue, setSelectValue] = useState([])

  const id = useQuery("id")
  const history = useHistory()

  const [state, setState] = useState({
    header: detailPromo?.header,
    namePromo: detailPromo?.name,
    startDate: detailPromo?.startDate?.split("T")[0],
    endDate: detailPromo?.endDate?.split("T")[0],
    cutoff: detailPromo?.cutoff,
    id: detailPromo?.id,
    image: "",
    idpackage: []
  })

  const handleChangeInput = (event) => {
    const {name, value}  = event.target
    console.log(name, value)
    setState(prev => ({...prev, [name]: value}))
  }

  const handleChangeImage = (image) => {
    loadImage(image, file => {
      setImage(file.name)
    })
  }

  useEffect(() => {
    const fetchGetPackage = async () => {
      const result = await getAllPackage(token)
      setGetPackage(result.data)
    }

    fetchGetPackage()
  }, [token])

  useEffect(() => {
    if(!detailPromo) return
    const check = detailPromo?.idPackage.split(",").map(data => Number(data))
    check.forEach((data, index) => {
      setSelectValue(prev => [...prev, {id: data, listID: index}])
    })
  }, [detailPromo])

  const onSubmit = async (data) => {
    state.id = id
    state.image = image
    state.idpackage = Array.from(new Set(selectValue.map(select => select.id)))
    
    // if (!image) {
    //   return alert("gambar wajib diisi")
    // }
    console.log(state)

    const result = await editPromo(state, token)
    if (result.status === "Success") {
      alert("Ubah promo berhasil")
      history.push("/admin/promo")
      return setSelectValue([])
    }
  }

  const handleClickAddPackage = () => {
    const {label, value} = packageSelectRef.current.state.value
    setSelectValue(prev => [...prev, { id: value, text: label, listID: new Date().getTime() }])
  }

  const handleClickRemovePackage = (id) => {
    setSelectValue(selectValue.filter(select => select.listID !== id))
  }

  if(!detailPromo) {
    return <div>Loading...</div>
  }
  
  const check = detailPromo?.idPackage.split(",").map(data => Number(data))

  const filtered = getpackage.find(data => check.includes(data.id))

  if(!filtered) {
    return <div>Loading...</div>
  }

  const filteredGrades = filtered?.grades.map(grade => grade.grade).sort((a, b) => a - b)

  const packageOptions = getpackage?.map(pkg => {
    const filterGrades = pkg.grades.map(grade => grade.grade).sort((a, b) => a - b)
    return {
      value: pkg.id,
      label: `${pkg?.type.type} ${pkg?.classtype} Kelas ${filterGrades.join(', ')}`
    }
  })

  return (
    <Fragment>
      <form className='p-6 bg-white rounded-xl w-full' onSubmit={handleSubmit(onSubmit)}>
        <TitleForm>Masukan Promo</TitleForm>
        <InputGroup>
          <label htmlFor="header" className='mb-2 text-sm'>Judul</label>
          <Input onChange={handleChangeInput} name="header" defaultValue={detailPromo?.header}/>
          {errors.header && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>

        <InputGroup>
          <label htmlFor="namePromo" className='mb-2 text-sm'>Nama Promo</label>
          <Input onChange={handleChangeInput} name="namePromo" defaultValue={detailPromo?.name} />
          {errors.namePromo && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>

        <div className='xl:flex'>
          <InputGroup className='xl:w-1/2 xl:mr-3'>
            <label htmlFor="startDate" className='mb-2 text-sm'>Hari / Tanggal Mulai</label>
            <Input className='w-full' onChange={handleChangeInput} name="startDate" defaultValue={detailPromo?.startDate?.split("T")[0]} type="date" />
            {errors.startdate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
          <InputGroup className='xl:w-1/2'>
            <label htmlFor="endDate" className='mb-2 text-sm'>Hari / Tanggal Selesai</label>
            <Input className='w-full' onChange={handleChangeInput} name="endDate" defaultValue={detailPromo?.endDate?.split("T")[0]} type="date" />
            {errors.enddate && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
          </InputGroup>
        </div>

        <InputGroup className=''>
          <label htmlFor="cutOff" className='mb-2 text-sm'>Potongan</label>
          <Input onChange={handleChangeInput} name="cutoff" defaultValue={detailPromo?.cutoff} type="text" placeholder="" />
          {errors.cutoff && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
        </InputGroup>

        <div className='xl:flex flex-col'>
          <InputGroup className='flex'>
            <label htmlFor="idpackage" className='mb-2 text-sm'>Jenis Paket</label>
            <div className='flex justify-between md:flex-row flex-col' >
            <Controller
                name="idpackage"
                control={control}
                defaultValue={setValue('idpackage', [{id: detailPromo?.idPackage}])}
                rules={{ required: true }}
                render={({ field: { onChange } }) => {
                  return <Select ref={packageSelectRef} options={packageOptions} className='rounded-md w-full mr-6' defaultValue={{ label: `${filtered?.type.type} ${filtered?.classtype} Kelas ${filteredGrades.join(', ')}`, value: filtered?.id }}/>
                }}
              />
              {errors.idPackage && <TextRed>{CONSTANT.REQUIRED}</TextRed>}
              <div className='flex items-center'>
                <button onClick={handleClickAddPackage} type="button" className='rounded-md border-blue p-2 text-md px-4'>Tambah</button>
              </div>
            </div>
          </InputGroup>
          <div className='xl:order-2 grid grid-cols-2 gap-3 mt-3 order-1 xl:flex-row'>
            {selectValue?.map((select, id) => {
              const filtered = getpackage.filter(data => check.includes(data.id))

              return (
                <div key={id} className='bg-gray rounded-xl p-4 flex justify-between items-center'>
                  <span className='text-sm'>{select?.text ?? `${filtered[id]?.type.type} ${filtered[id]?.classtype} Kelas ${filtered[id]?.grades.map(data => data.grade).join(', ')}`}</span>
                  <div className='cursor-pointer z-10' onClick={() => handleClickRemovePackage(select.listID)}>
                    <img src="/assets/icons/exit.png" alt="exit" />
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className='flex xl:flex-row flex-col'>
          <InputGroup className='xl:w-1/2 xl:mr-3 xl:order-1 order-2'>
            <label htmlFor="cutOff" className='mb-2 text-sm'>Gambar Promo</label>
            <div className='h-52 border-2  rounded-xl border-dashed'>
              <div className='flex flex-col justify-center items-center h-full'>
                <div>
                  <img src="/assets/icons/i-upload.png" alt="img" />
                </div>
                <div className='text-sm mt-2'>
                  <input {...register("image")} ref={imageRef} type="file" onChange={handleChangeImage} required/>
                </div>
                <span className='text-xs mt-2 txt-gray'>Support file JPG - Max File Size 3MB</span>
              </div>
            </div>
          </InputGroup>
        </div>
        <div className='flex justify-end mt-6'>
          <button onClick={() => history.push("/admin/promo")} type="button" className='mr-4 p-4 bg-gray rounded-xl'>Kembali</button>
          <button type="submit" className='p-4 bg-blue text-white rounded-xl'>Masukan</button>
        </div>
      </form>
    </Fragment>
  )
}