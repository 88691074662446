import { Link, useHistory } from "react-router-dom";
import { Hamburger } from "../Navbar";
import ActiveLink, { ButtonActive } from "../../utils/common/activeLink";
import { useToggle } from "../../hooks/useToggle";
import { useHidden } from "../../hooks/useHidden";
import {
  adminListSidebar,
  studentListSidebar,
  parentListSidebar,
  teacherListSidebar,
} from "../../utils/common/listSidebar";
import { PopupLogout } from "../Popup";
import { Fragment, useState } from "react";
import { useBoolean } from "../../hooks/useBoolean";
import { useEffect } from "react";
import { checkOnlineClass } from "../../service/students/studentService";
import { useCookies } from "../../hooks/useCookies";

function Sidebar({
  hrefProfile,
  children,
  admin = false,
  imageSrc,
  name,
  kelas,
  greeting,
  withGreetingImage,
}) {
  const { setMinimized, minimized } = useToggle();
  const { hidden, setHidden } = useHidden();
  const history = useHistory();

  const onMinimized = () => {
    // lihat text masing-masing icon menu

    if (minimized) {
      return setMinimized(false);
    }

    setMinimized(true);
  };

  const handleClickAvatar = () => {
    setHidden(true);
    history.push(hrefProfile);
  };

  return (
    <div
      className={`flex flex-col h-screen p-7 lg:relative ${
        minimized
          ? "w-max items-center hidden lg:flex"
          : `${
              hidden && "hidden"
            } lg:w-72 animate-toRight lg:animate-none fixed left-0 bg-white z-20 lg:flex`
      }`}
    >
      <Hamburger
        className={`transform scale-75 ${minimized ? "" : "hidden"}`}
        handleClickHamburger={onMinimized}
      />
      <div className={minimized ? "mt-8" : "w-full relative "}>
        <img
          className="w-16 h-14 rounded-full cursor-pointer"
          src={`${imageSrc}`}
          alt="profile"
          onClick={handleClickAvatar}
        />
        {!minimized && (
          <>
            <span
              className="block mt-4 font-medium cursor-pointer"
              onClick={handleClickAvatar}
            >
              {admin ? "Star Bimbel" : name}
            </span>
            {!admin && <span className="block text-xs">{kelas}</span>}
            <div
              className="absolute top-0 right-0 cursor-pointer lg:block hidden"
              onClick={onMinimized}
            >
              <img src="/assets/icons/btn-minimize.png" alt="minimize-btn" />
            </div>
          </>
        )}
      </div>

      <div className="flex flex-col items-start h-full overflow-y-auto lg:overflow-visible lg:h-1/2 mt-4">
        {children}
      </div>

      {!minimized && (
        <div className="lg:flex hidden items-end h-full relative ">
          {greeting && (
            <Image withGreetingImage={withGreetingImage} greeting={greeting} />
          )}
        </div>
      )}
    </div>
  );
}

export function SidebarAdmin({ role }) {
  const { minimized } = useToggle();
  const { setHidden } = useHidden();

  const handleClickLink = () => {
    setHidden(true);
  };

  const adminList = adminListSidebar.filter((list) => list.role === "admin");

  return (
    <Sidebar
      hrefProfile="#"
      name="Star Bimbel"
      imageSrc="/assets/avatar/admin.png"
    >
      <div className={`flex flex-col w-full mt-6 z-10`}>
        {role === "admin" ? (
          <>
            {adminList.map((list, id) => {
              return (
                <List
                  className={`mb-4 ${minimized ? "justify-center" : ""}`}
                  key={`list_${id}`}
                >
                  <Link
                    to={`/admin/${list.href}`}
                    className="flex items-center"
                    onClick={handleClickLink}
                  >
                    <Icon iconName={list.iconName} href={list.href} />
                    {!minimized && <Text href={list.href}>{list.name}</Text>}
                  </Link>
                </List>
              );
            })}
          </>
        ) : (
          <>
            {adminListSidebar.map((list, id) => {
              return (
                <List
                  className={`mb-4 ${minimized ? "justify-center" : ""}`}
                  key={`list_${id}`}
                >
                  <Link
                    to={`/admin/${list.href}`}
                    className="flex items-center"
                    onClick={handleClickLink}
                  >
                    <Icon iconName={list.iconName} href={list.href} />
                    {!minimized && <Text href={list.href}>{list.name}</Text>}
                  </Link>
                </List>
              );
            })}
          </>
        )}

        <Logout />
      </div>
    </Sidebar>
  );
}

export function SidebarTeacher(profile) {
  const { minimized } = useToggle();
  const { setHidden } = useHidden();

  const handleClickLink = () => {
    setHidden(true);
  };

  return (
    <Sidebar
      hrefProfile="/teacher/profile"
      name={`${profile.name}`}
      imageSrc="/assets/avatar/admin.png"
    >
      <div className={`flex flex-col w-full mt-6 z-10`}>
        {teacherListSidebar.map((list, id) => {
          return (
            <List
              className={`mb-4 ${minimized ? "justify-center" : ""}`}
              key={`list_${id}`}
            >
              <Link
                to={`/teacher/${list.href}`}
                className="flex items-center"
                onClick={handleClickLink}
              >
                <Icon iconName={list.iconName} href={list.href} />
                {!minimized && <Text href={list.href}>{list.name}</Text>}
              </Link>
            </List>
          );
        })}
        <Logout />
      </div>
    </Sidebar>
  );
}

export function SidebarUser(profile) {
  const { minimized, hiddenSidebar } = useToggle();
  const { setHidden } = useHidden();
  const [status, setStatus] = useState(null);

  const { getCookie } = useCookies();
  const token = getCookie("token");

  const handleClickLink = (href) => {
    if (!status && (href === "quiz" || href === "exam" || href === "class"))
      return alert(
        `maaf anda harus membeli paket online untuk mengakses ${href}`
      );
    setHidden(true);
  };

  useEffect(() => {
    const checkClass = async () => {
      const result = await checkOnlineClass(token);
      setStatus(result.status);
    };

    checkClass();
  }, [token]);

  const checkStudent = (href) => {
    if (!status) return "modul";
    return href;
  };

  return (
    <Fragment>
      {!hiddenSidebar && (
        <Sidebar
          hrefProfile="/dashboard/profile"
          name={profile.fullName}
          imageSrc={`https://api.starbimbel.com/foto/student/${profile.image}`}
          greeting={{
            name: `${profile.name}`,
            textGreeting: "Mau Belajar Apa Hari ini?",
          }}
          withGreetingImage
        >
          <div className={`flex flex-col w-full mt-6`}>
            {studentListSidebar.map((list, id) => {
              return (
                <List
                  className={`mb-4 ${minimized ? "justify-center" : ""}`}
                  key={`list_${id}`}
                >
                  <Link
                    to={`/dashboard/${
                      list.href === "quiz" ||
                      list.href === "exam" ||
                      list.href === "class"
                        ? checkStudent(list.href)
                        : list.href
                    }`}
                    className="flex items-center"
                    onClick={() => handleClickLink(list.href)}
                  >
                    <Icon iconName={list.iconName} href={list.href} />
                    {!minimized && <Text href={list.href}>{list.name}</Text>}
                  </Link>
                </List>
              );
            })}
            <Logout />
          </div>
        </Sidebar>
      )}
    </Fragment>
  );
}

export function SidebarParent(profile) {
  const { minimized } = useToggle();
  const { setHidden } = useHidden();

  const handleClickLink = () => {
    setHidden(true);
  };

  return (
    <Sidebar
      hrefProfile="/parent/edit"
      name={profile.name}
      imageSrc={
        profile.image
          ? `https://api.starbimbel.com/foto/parent/${profile.image}`
          : "/assets/avatar/default.png"
      }
      greeting={{
        name: `${profile.name}`,
        textGreeting: "Semoga sehat dan bahagia selalu",
      }}
    >
      <div className={`flex flex-col w-full mt-6`}>
        {parentListSidebar.map((list, id) => {
          return (
            <List
              className={`mb-4 ${minimized ? "justify-center" : ""}`}
              key={`list_${id}`}
            >
              <Link
                to={`/parent/${list.href}`}
                className="flex items-center"
                onClick={handleClickLink}
              >
                <Icon iconName={list.iconName} href={list.href} />
                {!minimized && <Text href={list.href}>{list.name}</Text>}
              </Link>
            </List>
          );
        })}
        <Logout />

        <div
          className={`absolute z-10 p-6 left-0 w-full ${
            minimized ? "lg:bottom-24 bottom-0" : "lg:bottom-44 bottom-0"
          }`}
        >
          <Link to="/parent/add-student" onClick={handleClickLink}>
            <div className="border-blue rounded-xl  flex justify-center items-center p-4">
              <div className="bg-blue p-2 rounded-md ">
                <img src="/assets/icons/plus.png" alt="plus" />
              </div>
              <span
                className={`w-full block text-sm ml-3 ${
                  minimized ? "hidden" : ""
                }`}
              >
                Tambah Murid
              </span>
            </div>
          </Link>
        </div>
      </div>
    </Sidebar>
  );
}

function Logout() {
  const { minimized } = useToggle();
  const { toTruthy, toFalsy, value } = useBoolean();

  return (
    <Fragment>
      <PopupLogout open={value} close={toFalsy} />
      <div onClick={() => toTruthy()}>
        <List
          className={`mb-4 ${
            minimized ? "justify-center absolute bottom-6" : ""
          }`}
        >
          <Icon iconName="btn-logout" href="logout" />
          {!minimized && <Text href="logout">Logout</Text>}
        </List>
      </div>
    </Fragment>
  );
}

function Icon({ iconName, href }) {
  return (
    <ButtonActive href={href}>
      <div className="cursor-pointer w-6 w-max">
        <img
          src={`/assets/icons/${iconName}.png`}
          className="w-6"
          alt={iconName}
        />
      </div>
    </ButtonActive>
  );
}

function Text({ children, href }) {
  return (
    <ActiveLink href={href}>
      <span className="ml-3 cursor-pointer block text-sm">{children}</span>
    </ActiveLink>
  );
}

function List({ className, children }) {
  return <div className={`flex items-center ${className}`}>{children}</div>;
}

function Image({ withGreetingImage, greeting }) {
  return (
    <div
      className={`flex flex-col w-full bg-blue p-7 rounded-xl ${
        withGreetingImage && "pt-24"
      }`}
    >
      {withGreetingImage && (
        <div className="absolute top-6 right-1">
          <img src="/assets/images/sidebar-img.png" alt="" />
        </div>
      )}
      <div className="flex flex-col text-white">
        <span className="block text-sm">Hai, {greeting.name}</span>
        <span className="block font-semibold">{greeting.textGreeting}</span>
      </div>
    </div>
  );
}
