/* eslint-disable no-restricted-globals */
import { Fragment, useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import Close from "../../../components/Close"
import { useToggle } from "../../../hooks/useToggle"

export function ShowProfile({hideChecked = false, setHasUpdate, onSave, setOpen, type, path, closeTo = '/admin', hiddenIconDelete, canEdit, data, apiDelete, token }) {
  const { minimized } = useToggle()

  const handleClickDelete = async (id) => {
    const confirmation = confirm("Yakin ingin menghapus data ini?")
    if (confirmation) {
      const result = await apiDelete(id, token)
      console.log(result)
      return;
    }
  }

  console.log(data)

  return (
    <Fragment>
      <div className={`mt-4 p-8 w-full lg:grid ${minimized ? 'grid-cols-1 justify-items-end' : 'grid-cols-1 lg:grid-cols-4'} gap-12 bg-white rounded-xl relative `}>
        <div className='flex items-center absolute top-6 right-6'>
          {!hiddenIconDelete && (<i onClick={() => handleClickDelete(data.id)} className='block w-6 h-6 bg-no-repeat mr-6 cursor-pointer filter grayscale' style={{ backgroundImage: "url('/assets/icons/btn-trash.png')" }} />)}
          <Close to={`${closeTo}/${path ? path : type}`} />
        </div>
        <div className={`${minimized ? ' w-11/12 lg:flex-row flex-col' : 'col-start-1 col-end-2 row-start-1 row-end-2 flex-col mb-20'} relative flex bg-blue mt-20  shadow-gray rounded-xl text-white justify-center items-center`}>
          {type === "students" && (
            <div className={`${minimized ? '-left-20 top-14 bottom-14' : '-top-20 h-32'} lg:absolute w-32 rounded-xl`}>
              <img className='h-full w-full' src={`http://api.starbimbel.com/foto/student/${data.image}`} alt="profile" />
            </div>
          )}
          <div className={`${minimized ? 'flex flex-row justify-center items-center' : ''} w-4/5`}>
            <div className={`${minimized ? 'w-full flex flex-col items-start' : ''}`}>
              <div className={`${minimized ? 'w-4/5' : 'lg:mt-12 items-center '} border-b-2 border-gray-100 py-6 flex flex-col `}>
                <h2>Name</h2>
                <span className="text-sm">{data?.name}</span>
              </div>

              <div className={`${minimized ? '' : "items-center border-b-2 border-gray-100"}  py-6 flex flex-col`}>
                <span className="text-xs">Mulai Gabung Sejak</span>
                <h3>{data?.createdAt?.split("T")[0]}</h3>
                <span className="text-xs mt-3">{type === "students" ? "Asal Sekolah" : "Aktivitas Saat Ini"}</span>
                <h4>{type === "students" ? data.school : data?.currentActivities}</h4>
              </div>
            </div>

            <div className={`${minimized ? "w-2/5 px-20 border-l-2 border-gray-100" : ""}`}>
              {minimized && <span className='mb-4 text-xl'>Kontak</span>}
              <div className='flex flex-col text-sm py-6'>
                <span>{data?.phone}</span>
                <span className={`${minimized ? 'mt-4' : 'mt-2'}`}>{data?.email}</span>
              </div>
            </div>
          </div>
        </div>

        <div className={`${minimized ? '' : 'col-start-2 row-start-1 col-end-5 row-end-2'}`}>
          <DetailProfile hideChecked={hideChecked} setHasUpdate={setHasUpdate} type={type} canEdit={canEdit} path={path} data={data} onSave={onSave} token={token} />
        </div>
      </div>
    </Fragment>
  )
}

function DetailProfile({hideChecked, setHasUpdate, type, canEdit, path, data, onSave, token }) {
  const { minimized } = useToggle()
  const checkboxRef = useRef(), btnRef = useRef()
  const [status, setStatus] = useState(data.status)
  const history = useHistory()

  useEffect(() => {
    if (data.status === true && !hideChecked) {
      handleClickDisableEnable(btnRef.current)
    }
  }, [data.status, hideChecked])

  const handleClickDisableEnable = (button) => {
    if (!checkboxRef.current.checked) {
      button.target ? button.target.classList.remove('left-1') : button.classList.remove('left-1')
      button.target ? button.target.classList.add('right-1') : button.classList.add('right-1')

      button.target ? button.target.parentElement.previousElementSibling.classList.remove('font-medium') : button.parentElement.previousElementSibling.classList.remove('font-medium')
      button.target ? button.target.parentElement.nextElementSibling.classList.add('font-medium') : button.parentElement.nextElementSibling.classList.add('font-medium')
      return setStatus(true)
    }

    setStatus(false)
    button.target ? button.target.classList.add('left-1') : button.classList.add('left-1')
    button.target ? button.target.classList.remove('right-1') : button.classList.remove('right-1')
    button.target ? button.target.parentElement.nextElementSibling.classList.remove('font-medium') : button.parentElement.nextElementSibling.classList.remove('font-medium')
    button.target ? button.target.parentElement.previousElementSibling.classList.add('font-medium') : button.parentElement.previousElementSibling.classList.add('font-medium')
  }

  const handleClickSave = async () => {
    const result = await onSave({ id: data.id, status: status }, token)
    if (result.status === "Success") {
      alert("Ubah status berhasil")
      setHasUpdate(true)

      if (type === "teachers") {
        return history.push('/admin/teachers')
      }

      return history.push('/admin/students')
    }
  }

  return (
    <div className={`w-full ${minimized ? 'lg:mt-4 mt-12' : 'mt-20'}`}>
      <div className={`${minimized ? 'lg:flex-row flex-col justify-evenly' : 'flex-col'} flex w-full`}>
        <div className={`${minimized ? 'order-2' : 'order-1'}`}>
          <div className={`${minimized ? 'flex-col' : ""} lg:flex-row flex-col flex w-full`}>
            <div className={`${minimized ? 'mt-12' : 'mt-0'} flex lg:w-48 flex-col pb-4 border-b-2 border-gray-200 mr-12 lg:mt-0`}>
              <span className='txt-gray'>{type === "students" ? "Nama Lengkap" : "Tanggal Lahir"}</span>
              <span className='font-medium'>{type === "students" ? data.fullName : data?.birthdate?.split("T")[0]}</span>
            </div>
            <div className={`${minimized ? 'mt-12' : ""} flex lg:w-48 lg:mt-0 mt-12 flex-col pb-4 border-b-2 border-gray-200`}>
              <span className='txt-gray'>{type === "students" ? "Nama Panggilan" : "Status Perkawinan"}</span>
              <span className='font-medium'>{type === "students" ? data.name : data?.maritalStatus}</span>
            </div>
          </div>

          {type === "teachers" && (
            <div className={`${minimized ? 'flex-col' : ""} lg:flex-row flex-col flex w-full mt-12`}>
              <div className={`${minimized ? 'mt-12' : 'mt-0'} flex lg:w-48 flex-col pb-4 border-b-2 border-gray-200 mr-12 lg:mt-0`}>
                <span className='txt-gray'>Pendidikan Terakhir</span>
                <span className='font-medium'>{data?.lastEducation}</span>
              </div>
              <div className={`${minimized ? 'mt-12' : ""} flex lg:w-48 lg:mt-0 mt-12 flex-col pb-4 border-b-2 border-gray-200`}>
                <span className='txt-gray'>Tahun Kelulusan</span>
                <span className='font-medium'>{data?.graduateYear}</span>
              </div>
            </div>
          )}

          {type === "students" && (
            <div className='flex w-full mt-12'>
              <div className='flex lg:w-48 w-full flex-col pb-4 border-b-2 border-gray-200'>
                <span className='txt-gray'>Tempat Tanggal Lahir</span>
                <span className='font-medium'>{data?.birthdate?.split("T")[0]}</span>
              </div>
            </div>
          )}

        </div>

        <div className={`${minimized ? 'order-1 lg:w-1/2' : 'order-2'}`}>
          <div className={`flex w-full ${minimized ? 'mt-0' : 'mt-12'}`}>
            <div className='flex w-full flex-col pb-4 border-b-2 border-gray-200'>
              <span className='txt-gray'>Alamat Lengkap</span>
              <span className='font-medium'>{data?.address}</span>
            </div>
          </div>

          {type === "teachers" && (
            <div className='flex w-full mt-12'>
              <div className='flex w-full flex-col pb-4 border-b-2 border-gray-200'>
                <span className='txt-gray'>Alamat Domisili</span>
                <span className='font-medium'>{data?.residence}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {canEdit ? (
        <div className='mt-8 w-full flex justify-end text-sm'>
          <Link to={`/teacher/profile/edit?type=password`}>
            <button className='mr-6 txt-blue p-4'>Edit Password</button>
          </Link>
          <Link to={`/teacher/profile/edit`}>
            <button className='bg-blue text-white p-4 rounded-xl'>Edit Profile</button>
          </Link>
        </div>
      ) : (
          <div className='mt-8 w-full flex md:flex-row flex-col justify-end md:items-center text-sm'>
            <input className='hidden' ref={checkboxRef} type="checkbox" id="disable-enable" />
            <div className='text-sm flex mr-12 items-center'>
              <span className=''>Disable</span>
              <div className='bg-lightblue-2 w-16  h-8 rounded-md p-1 mx-3 flex items-center relative'>
                <label ref={btnRef} onClick={handleClickDisableEnable} htmlFor="disable-enable" className='cursor-pointer bg-blue block w-6 rounded-md absolute left-1 bottom-1 top-1 '></label>
              </div>
              <span className=''>Enable</span>
            </div>
            <button className='bg-blue text-white p-4 rounded-xl md:mt-0 mt-6' onClick={handleClickSave}>Simpan dan Keluar</button>
          </div>
        )}
    </div>
  )
}

export default ShowProfile