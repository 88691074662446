export const responsiveMultiCarouselGuru = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 992, min: 552 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 552, min: 0 },
        items: 1
    }
};

export const responsiveMultiCarouselTestimoni = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 992, min: 768 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1
    }
};