import { SidebarAdmin } from "../../components/Sidebar"
import { usePageTitle } from "../../hooks/usePageTitle"
import { Redirect, Switch } from 'react-router-dom';
import { ContentDashboardNotFound } from '../errors/404'
import ContainerDashboard from '../../layouts/Dashboard'
import { RouteWithSubRoute } from '../../routes'
import { useCookies } from '../../hooks/useCookies'
import { checkRole } from '../../utils/common/checkRole'
import { checkCurrentRole } from '../../utils/common/checkCurrentRole'
import LoginAdmin from "./login/LoginAdmin";

function Admin({ routes }) {
  usePageTitle('Admin Dashboard - Star Bimbel')
  const { getCookie } = useCookies()
  const token = getCookie('token')

  if (!token) {
    return <LoginAdmin />
  } 

  const currentRole = checkCurrentRole(token)

  if (!checkRole("admin", token) && !checkRole("superadmin", token)) {
    if (currentRole === "student") {
      return <Redirect to={`/dashboard`} />
    }

    return <Redirect to={`/${currentRole}`} />
  }

  return (
    <ContainerDashboard>
      <SidebarAdmin role={currentRole}/>
      <Redirect to="/admin/payment" />
      <Switch>
        <RouteWithSubRoute routes={routes} />
        <ContentDashboardNotFound />
      </Switch>
    </ContainerDashboard>
  )
}

export default Admin