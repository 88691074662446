import { Fragment } from "react";
import { EditPromoForm } from "../../../components/Form/admin/promo";
import { NavDashboard } from "../../../components/Navbar";
import { ContentDashboard, MainDashboard } from "../../../layouts/Dashboard";
import { useCookies } from "../../../hooks/useCookies";
import { editPromo, getPromoById } from "../../../service/admin/adminService";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "../../../hooks/useQuery";
import { useCallback } from "react";

function EditPromo() {
  const { getCookie } = useCookies();
  const token = getCookie("token");
  const idPromo = useQuery("id");

  const [detailPromo, setDetailPromo] = useState();

  const fetchPromo = useCallback(async () => {
    const result = await getPromoById(token, idPromo);
    setDetailPromo(result.data);
  }, [idPromo, token]);

  useEffect(() => {
    fetchPromo();
  }, [fetchPromo]);

  if(!detailPromo) {
    return <div>Loading...</div>
  }

  return (
    <Fragment>
      <MainDashboard>
        <NavDashboard title="Kelola Promo" />
        <ContentDashboard>
          <EditPromoForm detailPromo={detailPromo} token={token} editPromo={editPromo} />
        </ContentDashboard>
      </MainDashboard>
    </Fragment>
  );
}

export default EditPromo;
