import { Fragment, useEffect, useState } from 'react'
import { ContentDashboard } from '../../layouts/Dashboard'
import { EditParentForm, EditPasswordParentForm } from '../../components/Form/parent'
import { NavDashboard } from '../../components/Navbar'
import { useEditType } from '../../hooks/useEditType'
import { useCookies } from '../../hooks/useCookies'
import { getProfile } from '../../service/profile/profileService'

function EditParent() {
  const { type, changeEditTypeTo } = useEditType()
  const { getCookie } = useCookies()
  const token = getCookie('token')
  const [profile, setProfile] = useState([])

  useEffect(() => {
    const fetchProfile = async () => {
      const profile = await getProfile(token)
      if (profile.status === "Success") {
        return setProfile(profile.data)
      }

      console.log(profile) // check error
    }

    fetchProfile()

    return () => setProfile({})
  }, [token])

  console.log(profile)

  return (
    <Fragment>
      <NavDashboard title="Profil Anda" />
      <ContentDashboard>
        {type === 'profil' ? <EditParentForm profile={profile} changeEditTypeTo={changeEditTypeTo} /> : <EditPasswordParentForm />}
      </ContentDashboard>
    </Fragment>
  )
}

export default EditParent