import { Link } from "react-router-dom";
import { Title } from "../../components/Section";

function PageNotFound() {
  return (
    <div className='fixed w-full h-screen flex justify-center flex-col items-center text-center'>
      <Title>404 - HALAMAN TIDAK DITEMUKAN</Title>
      <Link to='/'>
        <button className='btn-blue mt-4'>Kembali ke halaman utama</button>
      </Link>
    </div>
  )
}

export function ContentDashboardNotFound() {
  return (
    <div className='flex h-full justify-center items-center'>
      <h1 className='text-4xl'>Halaman Tidak ditemukan :(</h1>
    </div>
  )
}

export default PageNotFound